// https://blog.logrocket.com/vue-firebase-authentication/
<template>
	<ModalWrapper name="ModalUser" ref="wrapper" :closetitle="closetitle" @setModalSetting="setModalSetting">
		<div class="modal-inner">
			<!-- <header v-show="(!closeheader && !preloading)" class="modal-header">
				<button :class="{ 'headerselected': header_r , 'headerbtn': !header_r }" @click.prevent="switchMode('register')"> Sign Up </button>
				<button :class="{ 'headerselected': header_l , 'headerbtn': !header_l }" @click.prevent="switchMode('login')"> Login </button>
			</header> -->

			<!--<div v-show="display">
				<a>Creating your profile...</a>
			</div>-->

			<!--<div v-show="closetitle && !display">
				<a>Sign Up Successfully!</a>
			</div>-->

			<div v-show="((mode === 'login') && login && !preloading)" class="mode-login" >
				<UserLogin @Login="Login($event)" @OnUserSignIn="OnUserSignIn"></UserLogin>
			</div>
			<div v-show="((mode === 'register') && login && !preloading)" class="mode-register">
				<UserRegisterNoAvatar 
					@OnRegister="OnRegister" 
					@switchMode="switchMode" 
					@loadingRegister="loadingRegister($event)"
				></UserRegisterNoAvatar>
			</div>

			<div v-show="((mode === 'forgetpw') && login && !preloading)" class="mode-forgetpw">
				<UserForgetPassword>

				</UserForgetPassword>
			</div>

			<!-- <transition name="fade" mode="out-in">
			<div v-if="opentutorial" key="Tutorial">
				<Tutorial @closeTU="closeTU"></Tutorial>
			</div>

			<div v-else-if="selectquality" key="QualityCtrl">
				<QualityCtrl @closeQuality="closeQuality" @setQuality="setQuality($event)"></QualityCtrl>
			</div>
			</transition> -->
		</div>
	</ModalWrapper>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import ModalWrapper from '@/components/ModalWrapper'
import UserLogin from '@/components/UserLogin'
// import UserRegister from '@/components/UserRegister'
import UserRegisterNoAvatar from '@/components/UserRegisterNoAvatar'
import UserForgetPassword from '@/components/UserForgetPassword'
// import Tutorial from '@/components/Tutorial'
// import QualityCtrl from '@/components/QualityCtrl'
import { isNil } from 'lodash'
import firebase from 'firebase/app'
// import UsersDB from '@/firebase/users-db'
import { uuidv4 } from '@/js/uuidv4'
import { deleteCookie } from '@/js/cookies'
// import { desktop as isDekstop } from 'is_js'

export default {
	name: 'ModalUser',
	components: {
		ModalWrapper,
		UserLogin,
		// UserRegister,
		// Tutorial,
		// QualityCtrl,
		UserRegisterNoAvatar,
		UserForgetPassword,
	},
	props: ['isSystemLoaded', 'guestMode', 'gotFirestoreData'],
	data() {
		return {
			mode: 'register',
			error: null,
			activeUser: '',
			display: true,
			closetitle: false,
			// opentutorial: false,
			selectquality: false,
			closeheader: false,
			login: true,
			header_r: true,
			header_l: false,
			loginUser: false,

			userCookiesToken: '',
		}
	},
	computed: {
		...mapState('authentication', ['user']),
		...mapState('modals', ['userMode']),
		...mapState('app', ['networkOnLine', 'appTitle', 'preloading']),

		uuidv4(){
			return uuidv4('hash')
		},

		// GetCookiesToken() {
		// 	// this.userCookiesToken = getCookie('_user_token')
		// 	if (getCookie('_user_token') === null) {
		// 		// pop up show message 3s
		// 		console.log('No user token found in cookies, signin anonymously')
		// 		return false
		// 	}
		// 	return true
		// },
	},
	watch: {
		user: {
			handler(user) {
				// console.log('watch user handler')
				if (!isNil(user)) {
					// console.log('watch user handler !isNil')
					this.$store.dispatch('modals/closeAll')
					this.loadingLogin(true)
				} else {
					this.$store.dispatch('modals/open', {
						name: 'ModalUser',
						userMode: 'register',
					})
					this.loadingLogin(false)
				}
			},
			immediate: true
		},
		userMode: {
			handler(userMode) {
				if (userMode !== '') {
					this.switchMode(userMode)
				}
			},
			immediate: true
		}
	},
	methods: {
		...mapMutations('app', ['setPreloading', 'setMessageToastrContent', 'setMessageFadeTime']),
		...mapMutations('authentication', ['setUser']),
		switchMode(mode) {
			this.mode = mode
			if(mode === 'login'){
				this.header_l = true
				this.header_r = false
			} else if (mode === 'register') {
				this.header_r = true
				this.header_l = false
			}
		},
		setModalSetting(setting) {
			// console.log('setting', setting)
			if (setting === 'login') {
				this.switchMode('login')
			} else {
				this.switchMode('register')
			}
		},
		// old: loading
		// loadingRegister(state) {
		// 	if (state === true) {
		// 		this.login = false
		// 		this.closetitle = true
		// 		// this.$emit('startTutorial')
		// 		// this.selectquality = true
		// 		this.closeheader = true
		// 	}
		// 	// this.display = state
		// },
		// old: loading2
		loadingLogin(state) {
			if (state === true) {
				this.login = false
				this.closetitle = false
				// this.selectquality = true
				this.closeheader = true
				// this.loginUser = true
			} else {
				this.login = true
				this.closetitle = false
				this.closeheader = false				
			}
			// this.display = state	
		},
		updatepic(img){
			this.$emit('updateNav', img)
		},
		avatarphoto(img){
			this.$emit('avatarpic', img)
		},
		// displayName(name){
		// 	this.$emit('unityName', name)
		// },
		opentitle(){
			// this.closeheader = false
		},
		// closeQuality(){
		// 	if (this.loginUser) {
		// 		this.$refs.wrapper.close();
		// 		this.selectquality = false
		// 		this.$emit('toStartGame')
		// 	} else {
		// 		this.selectquality = false
		// 		this.opentutorial = true
		// 	}
		// },
		// setQuality(quality) {
		// 	this.$emit('viewQuality', quality)
		// },

		// call from UserLogin
		// Login(UserData) {
		// 	this.loadingLogin(true)
		// 	// this.$emit('sendUnityID', UserData.uid)
		// 	this.$emit('unityName', UserData.displayName)
		// 	// this.$emit('avatarpic', UserData.photoURL)
		// },

		async Logout() {
			console.log('ModalUser Logout')
			this.setPreloading(true)
			await firebase.auth().signInAnonymously()
			.then(async () => {
				this.OnUserLogout()
				this.showLogin()
			})
		},

		signupUserID(UserID){
			this.$emit('sendUnityID', UserID)
		},

		OnRegister() {
			this.$emit('OnRegister')
			this.loadingLogin(true)
			this.setPreloading(false)
		},

		async OnUserSignIn() {
			console.log('OnUserSignIn')
			this.loadingLogin(true)
			// setTimeout(() => {
			// 	window.location.reload()
			// }, 1000)
			// this.userCookiesToken = this.uuidv4
			// setCookie('_user_token', this.userCookiesToken, stayLogin ? 14 : 1)
			// firebase.firestore().collection('users').doc(user.uid).set({
			// 	sessionToken: this.userCookiesToken
			// }, { merge: true });
		},

		async OnUserLogout() {
			console.log('ModalUser OnUserLogout')
			deleteCookie('_user_token')
		},

		showLogin() {
			// TODO: screen should back to landing page
			this.login = true
			this.closeheader = false
			this.setMessageFadeTime(5000)
			this.setMessageToastrContent("Hi, Guest.")
			this.setPreloading(false)
		},

		/**
		 *  User & session token
		 */

		// async GetCookiesToken() {
		// 	this.userCookiesToken = getCookie('_user_token')
		// 	if (this.userCookiesToken === null) {
		// 		// pop up show message 3s
		// 		console.log('No user token found in cookies, signin anonymously')
		// 		// await this.Logout()
		// 		await firebase.auth().signInAnonymously()
		// 	}
		// },
		/**
		 *  When user has cookies token and auto signed in, refreshToken is called by onAuthedStateChanged
		 */
		// async RefreshToken(user) {
		// 	const tokenRef = firebase.firestore().collection('users').doc(user.uid)
		// 	tokenRef.onSnapshot(async snapShot => {
		// 		// if snapshot token is not exist, add one
		// 		if (!snapShot.data().sessionToken) {
		// 			tokenRef.set({
		// 				sessionToken: this.userCookiesToken
		// 			}, {merge: true})
		// 		}
		// 		if (this.userCookiesToken !== snapShot.data().sessionToken) {
		// 			// pop up 3s: cookies isn't match with snapshot token
		// 			// console.log('Your account is signedIn on another device, or login expired, please signIn again.')
		// 			this.setMessageToastrContent('Your account is signedIn on another device, or login expired, please signIn again.')
		// 			// this.firebaseLogout()
		// 			// this.$refs.navbar.logout()
		// 			await this.Logout()
		// 			tokenRef.onSnapshot() // cancel listen snapshot
		// 		}
		// 	})
		// },
	},
	async mounted() {
		// console.log('ModalUser Mounted')
		this.setPreloading(true)
		/**
		 *	firebase auth state persistence is set to local, it'll keep a user signed in even closed the website by default
		 */ 
		firebase.auth().onAuthStateChanged(async (firebaseUser) => {
			if (!firebaseUser) return;
			if (!this.gotFirestoreData) this.$emit('GetFirestoreData')
			if (!firebaseUser.isAnonymous) {
				// await this.RefreshToken(firebaseUser)
				// setTimeout(() => { 
				// 	console.log('GetSignInGameData uid', firebaseUser.uid)
				// }, 2500);
				// tofix: too fast to read gameSession will not trigger auth
				this.$emit('GetSignInGameData', firebaseUser.uid) 
			} else {
				// show login UI
				this.showLogin()
			}
			// startup preloading end
			// setTimeout(() => {
			// 	this.setPreloading(false) 
			// 	this.setMessageToastrContent(firebaseUser.isAnonymous ? "Hi, Guest." : `Welcome to Sunset Town, ${firebaseUser.displayName}!`)
			// }, 2000);
		})
	},
}
</script>

<style lang="scss" scoped>
@import '@/theme/vars.scss';

.headerbtn{
	width: 50%;
	height: 100%;
	vertical-align: middle;
	font-size: 0.7vw;
	border: 0.15vw solid;
	border-color: grey;
	border-radius: 10px;
	// border-color: #c3ff00;
	padding: 0 0;
	background-color: grey;
	// color: #c3ff00;
	color: white;
	cursor: pointer;
	font-family: 'Inter';
	font-weight: 300;
	line-height: 0;
}
.headerselected{
	width: 50%;
	height: 100%;
	vertical-align: middle;
	font-size: 0.7vw;
	border: 0.1vw solid;
	border-radius: 10px;
	// border-color: #c3ff00;
	border-color: white;
	padding: 0 0;
	// background-color: #c3ff00;
	background-color: white;
	color: black;
	cursor: pointer;
	font-family: 'Inter';
	font-weight: 300;
	line-height: 0;
}
.modal-header{
	display: flex;
	column-gap: 2px;
	// width: 19.2vw;
	height: 1.85vw;
	margin-bottom: .5vw;
	justify-content: center;
}
</style>
