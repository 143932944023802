<template>
    <div>
        <div 
            id="unity-container"
            :class="{ showContainer: unitySystemLoaded }"
			:style="{
				width: '100%',
				height: '100%'
			}"
        >
            <transition name="fade">
                <div
                    v-if="(status.gestureRequired && unitySystemLoaded)"
                    @click.prevent="OnClickPlay"
                    class="click-play-container"
                    >
                    <!-- Click Play -->
                    <div class="play-ellipse">
                        <img class="play-arrow" src="/img/game/clickplay_arrow.svg">
                    </div>
                    <p class="play-text">START GAME</p>
                </div>
            </transition>
        </div>

        <!-- <div
            v-if="status.clickedPlayFirstTime"
            ref="cursorMask"
            class="cursorMask"
        >
        </div> -->
    </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import ubitus from '@/js/UibtusExternal'
import { isNil } from 'lodash'

export default {
    components: {

    },
    props: {
		showDefaultProgress: {
			type: Boolean,
			default: false,
		},
        // userId: {
        //     type: String,
        //     default: null,
        // },
        gameToken: {
            type: String,
            default: null,
        },
        ubitusSettings: {
            type: Object,
            default: null,
        },
		reservationMode: {
			type: Boolean,
			default: false
		},
        DebugGameAuthMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isDebugMode: false,
            onnineScript: null,
            ubitusPlayer: null,
            showContainer: false,
			unitySystemLoaded: false,
            Width: null,
            Height: null,
            userIdleLimitation: false,
            userIdleTime: 20,
            userInactiveCount: 0,
            useUserProfile: true,

            status: {
                initSuccess: false,
                connected: false,
                closed: false,
                idleCountDown: false,
                firstFrameArrived: false,
                keyInput: false,
                clickedPlayFirstTime: false,
                gestureRequired: false
            },

            config: {
                game: this.ubitusSettings.game,
                server: this.ubitusSettings.server,
                width: 1920,
                height: 1080,
                target: '#unity-container',
                loadingComponent: false,
                auth: {
                    provider_id: this.ubitusSettings.auth.provider_id,
                    appId: this.ubitusSettings.auth.appId,
                    userId: '',
                    gameToken: '',
                    channel: this.ubitusSettings.auth.channel,
                },
                launchParams: {
                    session: 'new', 
                    idleTimeout: 1200, // negative to disable idle detection
                    kickoutTimeout: 120, // negative to disable network bandwidth detection
                    setting: {
                    
                    }
                }
            }
        }
    },
    computed: {
		...mapState('authentication', ['user']),
    },
    beforeMount() {

    },
    mounted() {
        /**
         * SDK import
         */

        ubitus(this.ubitusSettings.sdkUrl).then(() => {
            console.log("☁Ubitus onloaded.")
    
            window.addEventListener('resize', () => {
                this.checkFrameSize()
            })
    
            // if (process.env.NODE_ENV === 'development') {
            //     this.isDebugMode = true
            //     // this.debugModeEmulation()
            // } else {
            //     this.isDebugMode = false
            // }
    
            // if (this.isDebugMode) {
            if (this.DebugGameAuthMode) {
                console.log(`☁Ubitus DebugMode Init`)
                const userId = 'ubitus_test'
                const gameToken = 'test'
                this.PreInit(userId, gameToken)
            } else {
                this.PreInit(this.user.id, this.gameToken)
            }
        })

    },
    methods: {
		...mapMutations('app', ['setPreloading', 'setMessageToastrContent', 'setMessageFadeTime']),

        // async debugModeEmulation() {
            // console.log('Ubitus DebugMode')
		// 	const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

		// 	await delay(3000)
			
		// 	this.$emit('ContainerState', 'OnProgressStart')
			
		// 	await delay(3000)

		// 	this.$emit('ContainerState', 'OnStreamStart')
		// 	this.SetUnitySystemLoaded()
        // },
        PreInit(userId, gameToken) {
            if (userId === '' || gameToken === '') {
                console.error('denied access.')
                return
            }
            this.checkFrameSize()
            this.config.width = this.Width
            this.config.height = this.Height

            this.config.auth.userId = userId
            this.config.auth.gameToken = gameToken

            this.$emit('sendUbitusConfigDetails', this.config)
            
            this.StartPlayer()
        },
        StartPlayer() {
            try {
                // eslint-disable-next-line
                OnNine.initializeAsync(this.config).then(player => {
                    this.ubitusPlayer = player;
                    // Listen status events
                    this.ubitusPlayer.addEventListener('status', status => {
                        console.log(`☁Ubitus Status: ${status}`);
                        switch (status) {
                            case 'start':
                                // start gamecloud client
                                break;
                            case 'play':
                                // request play link
                                break;
                            case 'playsucess':
                                // request play link success
                                this.status.initSuccess = true
                                break;
                            case 'connect':
                                this.status.connected = true
                                break;
                            case 'reconnectstart':
                                // If network is broken, client will start reconnect mechanism. Duration time: 30 seconds.
                                break;
                            case 'reconnectretry':
                                // The client reconnect to server. Interval time: 5 seconds.
                                break;
                            case 'reconnectend':
                                // success or not?
                                break;
                            case 'firstframearrived':
                                // hide DUI
                                this.status.firstFrameArrived = true
                                this.ubitusPlayer.hideDUI(true)
                                // OnWebAppStart is called when reservation mode is on
                                if (this.reservationMode) this.sendUnityMessage('OnWebAppStart')
                                setTimeout(() => this.SetUnitySystemLoaded, 2000)
                                this.ubitusPlayer.getMuteStatus(res => console.log('☁Ubitus muted status: ', res.muted))
                                break;
                            case 'idletimeout':
                                this.status.idleCountDown = true
                                break;
                            case 'countdowncancel':
                                this.status.idleCountDown = false
                                break;
                            case 'disconnect':
                                this.status.connected = false
                                break;
                            case 'close':
                                if (!this.status.closed) {
                                    this.stopPlayer()
                                    this.status.closed = true
                                    this.status.keyInput = false
                                }
                                break;
                            case 'gesturerequired':
                                // TODO: auto skip click play button
                                // this.OnShowPlayButton()
                                break;
                            case 'gesturecompleted':
                                this.OnClickPlay()
                                break;
                            case 'playerpause':
                                // this.ubitusPlayer.resumeStreaming()
                                break;
                            default:
                                break;
                        }
                    });
                    // Listen error events
                    this.ubitusPlayer.addEventListener('error', error => {
                        // Handle error events
                        console.error(`☁Ubitus Error: ${error.code}`);
                        // show refresh browser (signin again)
                        this.setMessageFadeTime(10000)
                        this.setMessageToastrContent(`☁Server Error: ${error.code}. Can't connect to game, please try again later, and contact your administrator for more information.`)
                        this.setPreloading()
                        setTimeout(() => {
                            window.location.reload()
                        }, 10000);
                    });
                    this.ubitusPlayer.addEventListener('customEvent', event => {
                        this.OnGetUnityEvent(event)
                    })
                    this.ubitusPlayer.addEventListener('useroperation', uo => {
                        // console.log(`☁Ubitus useroperation: ${JSON.stringify(uo)}`)
                        // params
                        // keyboard: type, keyCode
                        // mouse: type, x, y
                        if (!this.status.keyInput) {
                            this.status.keyInput = true
                            console.log('☁Ubitus Start: listen user operation')
                        } else {
                            this.OnUbiKeyInput(uo)
                        }
                    })

                    this.OnStreamStart()
                })

            } catch (e) {
                console.log(e)
            }
        },
        OnGetUnityEvent(data) {
            // TODO: split msg data
            console.log(`☁Ubitus customEvent: ${data}`)
            const params = data.split('/')
			if (params.length >= 2) {
				// console.log('_ogum', params[0], params[1])
				this.$emit('onGetUnityMessage', params[0], params[1])
			} else {
				// console.log('_ogum ', data)
				this.$emit('onGetUnityMessage', data)
			}
            
			switch (data) {
				case 'OnSystemLoaded':
					this.SetUnitySystemLoaded()
					break
				case 'OnGameEnd':
					this.$emit('onUnityGameEnd')
					break
                case 'OnShowGameLogin':
					// NXSplashScreenFinished, show game login
                    this.SetUnitySystemLoaded()
                    break;
				default:
					break
			}
        },
        OnUbiKeyInput(useroperation) {
            this.$emit('OnUbiKeyInput', useroperation)
        },
        sendUnityMessage(method, param = '') {
            if (this.ubitusPlayer === null) {
                console.warn(
					"you've sent a message to the Ubitus player, but it wasn\t instantiated yet."
				)
				return
            }

            switch (method) {
                case 'SetCursorFree':
                    this.ubitusPlayer.exitPointerLock()
                    break;
                default:
                    break;
            }

            this.ubitusPlayer.sendWmCopyData(JSON.stringify({
                functionName: method,
                value: param
            }))
            
            console.log(`☁Ubitus sendWMCopyData: ${method} ${param}`)
        },
        SetUnitySystemLoaded(){
            if (!this.unitySystemLoaded) {
                this.unitySystemLoaded = true
                this.$emit('onUnitySystemLoaded')
            }
		},
        checkFrameSize() {
			let windowW = window.innerWidth // eslint-disable-line
			// let windowH = window.innerHeight // eslint-disable-line
			let width = windowW
			let height = (windowW / 16) * 9 // locked ratio for 16:9
			// let left
			// let top

			// if (isMobile) {
			// 	// return // disable mobile auto resize
			// 	if(this.mobileAutoResize){
			// 		if (windowW < windowH) {
			// 			const windowWTemp = windowW
			// 			windowW = windowH
			// 			windowH = windowWTemp
			// 		}
			// 	}else{
			// 		return
			// 	}
			// }

			// if (windowW / windowH >= 16 / 9) {
			// 	// fit width, extend height
			// 	width = windowW
			// 	height = (windowW / 16) * 9
			// 	top = (height - windowH) / 2
			// 	this.sendUnityMessage('WindowOffset', 0, top)
			// } else {
			// 	// fit height, extend width
			// 	height = windowH
			// 	width = (windowH / 9) * 16
			// 	left = (width - windowW) / 2
			// 	this.sendUnityMessage('WindowOffset', left, 0 )
			// }

			// if(this.Height !== `${height}px` || this.Width !== `${width}px`){
			// 	this.Height = `${height}px`
			// 	this.Width = `${width}px`
			// 	console.log('AutoResize', this.Width, this.Height)
			// }

            if (height < window.innerHeight) {
                // width is small than 16 ratio, but height doesnt change
                width = 16 * (window.innerHeight / 9)
                height = window.innerHeight
            }

			if(this.Height !== height || this.Width !== width){
				this.Height = height
				this.Width = width
				console.log('AutoResize', this.Width, this.Height)
			}

            if (this.ubitusPlayer)
                this.ubitusPlayer.setSize(this.Width, this.Height)
		},
        OnStreamStart() {
            // start load game: showing ubitus logo session
            this.ubitusPlayer.play()
            // this.$emit('sendUnityMessage', 'OnWebAppStart')
            this.$emit('ContainerState', 'UbiOnStreamStart')
        },
        OnShowPlayButton() {
            // if (!this.status.clickedPlayFirstTime) {
                this.status.gestureRequired = true // turn clickplay div on
            // } else {
                // if not the firstTime shown, dont show click play button, and auto call mute(false) to fix
                // this.ubitusPlayer.mute(false)
            // }


            
            // this.$emit('onShowPlayButton') // for mute(false) button function
            // this.SetUnitySystemLoaded() // debug usage
        },
        OnClickPlay() {
            this.$emit('ContainerState', 'UbiOnClickPlay') // emit GA
            this.status.gestureRequired = false // turn clickplay div off
            // this.ubitusPlayer.mute(false)
            this.FocusIframe() // ubitusPlayer.iframeFocus()
            if (!this.status.clickedPlayFirstTime) {
                // this.$emit('onUserClickPlay') // start avatar edit page
                this.status.clickedPlayFirstTime = true
            }
        },
        /**
         *  Send UserId to Unity, fetching user profile data (displayName, photoUrl, etc...)
         */
        SendUserIdToUnity() {
			if (!isNil(this.user) && this.useUserProfile) {
				setTimeout(() => {
					this.sendUnityMessage('SetUserIdAnonymously', this.user.id)
				}, 2000)
			}
		},
        async stopPlayer() {
            // stop cloud gaming
            await this.ubitusPlayer.stopAsync()
            console.log(`☁Ubitus stopAsync.`)
        },
        FocusIframe() {
            this.ubitusPlayer.iframeFocus()
            this.sendUnityMessage('SetCursorLock')
            // document.getElementById('unity-container').requestPointerLock();
        }
    },
    async beforeDestroy() {
        await this.stopPlayer()
    }
}
</script>
<style lang="scss">
#unity-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	opacity: 0;

	position: fixed;
	min-width: 100vw;
	min-height: 100vh;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

    // cursor: none;

	iframe {
		border: none;
	}

	&.showContainer {
		opacity: 1;
	}
	&.forceShow {
		opacity: 1;
	}
}

.click-play-container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 999;
    background: #FF6900;
    align-items: center;
    gap: 0.5vh;
    justify-content: center;

    .play-ellipse {
        width: 5vw;
        height: 5vw;
        padding: 1vw 1vw;
        position: relative;
        border-radius: 50%;
        background-color: white;
        filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.1));

        &:hover {
            background-color: #66BAFF;
        }
    }

    .play-arrow {
        position: relative;
        width: 3.5vw;
        left: 50%;
        transform: translate(-40%, -50%);
        top: 50%;

        .hover {
            filter: sepia(1);
        }
    }

    .play-text {
        font-family: 'Inter';
        font-size: 1.2vw;
        font-weight: bold;
        text-align: center;
    }
}

.cursorMask {
    width: 100%;
    height: 100%;
    z-index: 999;
    cursor: none;
}

// hide ubitus menu dummy
.on9-menu-icon {
    display: none !important;
}
</style>

<style lang="css">
/**
    hide ubitus click play
 */ 
/* .gesture-modal {
    display: none !important;
} */

</style>