<template>
	<div>
		<div 
			v-if="(isMobile || isTablet)"
			id="brim-mask"
			class="layout-fullview"
			style="text-align: center"
			>
			<h1 style="margin: 0 !important">Sunset Town Festival</h1>
			<h1 style="margin: 0 !important">夕陽小鎮虛擬音樂節</h1>
			<p style="margin: 5vh 0 0 0 !important">請使用電腦或筆電進行體驗</p>
			<p>Please use PC or Laptop to experience</p>
		</div>
		<div v-if="isDev && !(isMobile || isTablet)" id="debug">
			<DevConsole
				:projectPath="projectPath"
				:debugUI="debugUI"
				@OnDevPermit="OnDevPermit"
			></DevConsole>
		</div>
		<div v-if="webHandle" id="brim-main" :class="{ UnityLoaded: isUnitySystemLoaded }">

			<!-- Background video -->
			<transition name="fade" :duration="500">
				<div
					v-if="(!gameReady && !techWarning && !GameEnd)"
					class="layout-fullview bgVideoWrapper"
				>
					<img
						id="bgImg"
						src="@/assets/img/st-loginBg.png"
						data-src="@/assets/img/st-loginBg.png"
						data-srcset="@/assets/img/st-loginBg.png"
					>
					<video
						id="bgVideo"
						:src="
							isMobile
								? '/img/BC/bc_web_bg_mobile.webm'
								: '/img/BC/bc_web_bg_fullhd.webm'
						"
						playsinline
						autoplay="autoplay"
						loop="loop"
						muted
						defaultMuted
						poster="/img/lazyloadBg.png"
						preload="auto"
					></video> 
				</div>
			</transition>

			<!-- GameEnd -->
			<transition name="fade" :duration="500">
				<div
					v-if="GameEnd"
					class="layout-fullview endView "
				>
					<img
						class="user-drag"
						src="@/assets/img/st-loginBg.png"
						data-src="@/assets/img/st-loginBg.png"
						data-srcset="@/assets/img/st-loginBg.png"
					>

					<div
						class="gameend-container user-drag"
					>
						<div class="gameend-message">
							Thank you for coming!
						</div>
						<div
							class="flex social-container"
						>
							<img class="social-icon" src="img/icons/discord.svg" @click.prevent="OpenExternalUrl('discord')" />
							<img class="social-icon" src="img/icons/facebook.svg" @click.prevent="OpenExternalUrl('facebook')" />
							<img class="social-icon" src="img/icons/instagram.svg" @click.prevent="OpenExternalUrl('instagram')" />
						</div>
					</div>
					
				</div>
			</transition>
			
			<transition name="fade" :duration="500">
				<div
				v-if="techWarning"
				class="layout-fullview techView"
				>
					<div
					class="tech-container"
					>
						<div class="tech-message">
							<h1>
								Sorry, the connection seems to be down.
							</h1>
							<p>
								we are checking the problem, please wait in patient.
							</p>

							<!-- <div style="margin: 6vh 0;">
								<img style="height: 1.2vh;" src="/img/streaming_bullet.svg"><span @click.prevent="OpenExternalUrl('yt-streaming')">Please Watch live stream on YouTube</span>
							</div> -->

							<div
								style="margin: 6vh 11vw;"
								class="flex social-container"
							>
								<img class="social-icon" src="img/icons/discord.svg" @click.prevent="OpenExternalUrl('discord')" />
								<img class="social-icon" src="img/icons/facebook.svg" @click.prevent="OpenExternalUrl('facebook')" />
								<img class="social-icon" src="img/icons/instagram.svg" @click.prevent="OpenExternalUrl('instagram')" />
							</div>
						</div>
					</div>
					
				</div>
			</transition>

			<!-- Nav Bar -->
			<nav-bar 
				v-show="(!preloading && !streamStarted && !techWarning)"
				ref="navbar"
				:GameEnd="GameEnd"
				@Logout="Logout"
				@OnUserLogout="OnUserLogout"
			></nav-bar>

			<!-- Modal User -->
			<modal-user
				v-show="(!preloading && !GameEnd)"
				ref="modalUser"
				:guestMode="true"
				:gotFirestoreData="gotFirestoreData"
				@OnRegister="OnRegister"
				@GetFirestoreData="GetFirestoreData"
				@GetSignInGameData="GetSignInGameData"
			></modal-user>

			<game-key-authenticator
				ref="gameKeyAuthenticator"
				v-show="(entered && user && !hasKeyAuth && !preloading)"
				@enter="enter"
				@GetSignInGameData="GetSignInGameData"
				@OnGameAuth="OnGameAuth"
			></game-key-authenticator>

			<!-- Intros  -->
			<transition name="fade" :duration="1000">
				<div v-show="((!gameReady || !entered) && user && !showCongradulation && !preloading && !GameEnd && !techWarning)" id="IntroView" class="layout-fullview">
					<!-- Live Link -->
					<a v-if="isGameAvailable && showLiveStream" :href="externalLink" target="_blank">
						<img class="live-btn" :src="liveImg" />
					</a>
					<!-- PC Intro -->
					<div v-if="!isMobile" class="logo-wrapper">
						<!-- <img class="logo user-drag" src="/img/BC/BC_Logo.svg" /> -->
						<!-- <h1>SUNSET TOWN</h1> -->
						<div class="content">
							<!-- <p>
								Virtual Music Festival
							</p> -->
							<button
								v-show="(isGameAvailable && !entered) || (forceEnter && showEnter)"
								type="button"
								class="enter-btn"
								@click.prevent="enter(true)"
							>
								ENTER
							</button>
							<button
								v-show="(!isGameAvailable && !forceEnter)"
								class="soon-btn"
							>
								SOON
							</button>
							<label v-if="(shwoDebugGameAuth && !streamStarted)" style="color: black; position: absolute; left: 1vw; bottom: 1vh;">
								<!-- DebugGameAuthMode
								<input type="checkbox" v-model="DebugGameAuthMode"> -->
								forceEnter
								<input type="checkbox" v-model="forceEnter">
							</label>
							<div v-if="(isGameAvailable && entered && hasKeyAuth && streamStarted)" class="warn-VmWait" v-html="WarnText"></div>
						</div>
					</div>

					<!-- PC Start Progress, Loading progress bar -->
					<transition name="fade" :duration="1000">
						<div v-if="progressMode !== 'none'" id="progressGroup" class="PC">
							<transition name="fade" :duration="1000">
								<div v-show="progressMode === 'step'" class="progress-bar">
									<div
										class="progress-fill"
										:style="{ width: downloadProgress * 100 + 'vw' }"
									></div>
								</div>
							</transition>
							<transition name="fade" :duration="1000">
								<div
									v-show="progressMode === 'gradient'"
									class="progress-gradient"
								></div>
							</transition>
						</div>
					</transition>
				</div>
			</transition>

			<!-- Game Intro -->
			<GameLogin
				v-if="(gameReady && !GameEnd && entered && hasKeyAuth && !techWarning && !preloading)"
				:showGameLogin="showGameLogin"
				@toggleTutorial="toggleTutorial"
				@sendUnityMessage="sendUnityMessage"
				@OnChangeChatDisplayName="OnChangeChatDisplayName"
				@StartGame="StartGame"
				/>
			<GameTutorial 
				:hideGameUI="hideGameUI"
				:showInfo="showInfo"
				:showInfoTips="showInfoTips"
				:showTutorial="showTutorial"
				@toggleTutorial="toggleTutorial"
				/>
			<!-- Chat Input -->
			<Chatroom
				ref="chatroom"
				v-if="(gameStarted && !techWarning && !GameEnd)"
				:hideGameUI="hideGameUI"
				:showChat="showChat"
				:isMobile="isMobile"
				:userId="this.user.id"
				:userName="this.userName"
				:projectPath="projectPath"
				@focusCloudContainer="focusCloudContainer"
				@sendUnityMessage="sendUnityMessage"
			></Chatroom>

			<CloudGameStarter
				v-if="(clientReady && entered && user && hasKeyAuth)"
				v-show="(!showWarn && !showLoading && !GameEnd && !techWarning)"
				id="CloudGameStarter"
				ref="StarterRef"
				class="Landscape-only"
				style="position:fixed; z-index: 1"
				:cloudPlatform="cloudPlatform"
				:gameToken="gameToken"
				:ubitusSettings="ubitusSettings"
				:reservationMode="reservationMode"
				:DebugGameAuthMode="DebugGameAuthMode"
				@StarterState="StarterState"
				@onGetUnityMessage="onGetUnityMessage"
				@onUnitySystemLoaded="onUnitySystemLoaded"
				@onUserClickPlay="onUserClickPlay"
				@onUnityGameEnd="onUnityGameEnd"
				@OnUbiKeyInput="OnUbiKeyInput"
				@sendUbitusConfigDetails="OnGetUbitusConfigDetails"
			></CloudGameStarter>
				
			<InfoPanel
				v-show="(gameStarted && !GameEnd && !techWarning)"
				ref="InfoPanel"
				:projectPath="projectPath"
				@sendUnityMessage="sendUnityMessage"
			></InfoPanel>

			<UnityNavBar
				v-show="(gameStarted && !GameEnd && !techWarning)"
				ref="UnityNavBar"
				:hideGameUI="hideGameUI"
				:isDemo="isDev"
				:showZoneReport="showZoneReport"
				:zoneServerReport="zoneServerReport"
				:projectPath="projectPath"
				@sendUnityMessage="sendUnityMessage"
			></UnityNavBar>

			<BottomMenu
				v-show="(!showWarn && !GameEnd && !techWarning)"
				id="BottomMenu"
				ref="BottomMenu"
				class="Landscape-only"
				:hideGameUI="hideGameUI"
				:game-started="gameStarted"
				:visible-info-btn="visibleInfoBtn"
				@toggleInfo="toggleInfo"
				@toggleChat="toggleChat"
			></BottomMenu>

			<transition name="modal-fade">
				<div v-show="preloading" class="preloading-container">
					<div class="preloading-circle"></div>
				</div>
			</transition>
			
			<message-toaster></message-toaster>

			<!-- Register succesfully: Congratulations -->
			<transition name="fade" :duration="1000">
				<div class="register-cons" v-show="showCongradulation">
					<p class="user-title" style="font-size: 1.6rem; color: #FF6900;">Congratulations!</p>
					<div class="form-space"></div>
					<span>請於活動期間攜帶你的</span><span class="font-bold">【登入序號】</span><span>再次登入，開啟你的夕陽小鎮之旅。</span> <p></p>
					<span>Please log in again with your </span><span class="font-bold">“serial code”</span><span> during the event.</span> <p></p>
					<div class="form-space"></div>
					
					<!-- <div class="cons-space"></div> -->
					<!-- 
					<div class="sm">
						<a href=""><img src="/img/icons/facebook.svg"></a>
						<a href=""><img src="/img/icons/twitter.svg"></a>
					</div> -->
				</div>
			</transition>

			<transition name="fade" :duration="1000">
				<img 
					class="yongge-img" 
					:class="{'yongge-img-loading': streamStarted && !GameEnd}"
					v-show="(user && !preloading && !isUnitySystemLoaded || GameEnd)" 
					src="/img/sunsettown/yongge.svg"
				/>
			</transition>

			<!-- <div v-if="entered && gameReady && GameEnd" id="EndView" class="layout-fullview Landscape-only">
				<img
					:src="isMobile ? '/img/BC/credit_MB.svg' : '/img/BC/credit_PC_v2.svg'"
					alt="HyPERREAL performance WORK by Harrison Hall, Sam Mcgilp, NAXS FUTURE"
					:class="{ imgmb: !isMobile }"
				/>
			</div>
			<div v-if="techWarning && entered && gameReady && !GameEnd" id="TechView" class="layout-fullview Landscape-only">
				<a :href="externalLink" target="_blank" rel="noopener noreferrer">
					<img
						v-if="showLiveStream"
						:src="isMobile ? '/img/BC/tech_MB.svg' : '/img/BC/tech_PC.svg'"
						alt="HyPERREAL performance WORK by Harrison Hall, Sam Mcgilp, NAXS FUTURE"
						:class="{ imgmb: !isMobile }"
					/>
					<img
						v-else
						:src="isMobile ? '/img/BC/tech_MB_nolive.svg' : '/img/BC/tech_PC_nolive.svg'"
						alt="HyPERREAL performance WORK by Harrison Hall, Sam Mcgilp, NAXS FUTURE"
						:class="{ imgmb: !isMobile }"
					/>
				</a>
			</div> -->
		</div>
	</div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// import { uuid } from 'vue-uuid'
// import axios from 'axios'
import { getCookie } from '@/js/cookies'
import BottomMenu from '@/components/BottomMenu'
// import FurioosStarter from '@/components/FurioosStarter'
import CloudGameStarter from '@/components/CloudGameStarter.vue'
// eslint-disable-next-line
import {
	isMobile,
	isIOS,
	isIOS13,
	isChrome,
	isAndroid,
	isTablet
} from 'mobile-device-detect'
import firebase from 'firebase/app'
// import Brim from 'brim'
// import Scream from 'scream'
// import platform from 'platform'
// import NewContentAvailableToastr from '@/components/NewContentAvailableToastr'
import Chatroom from '@/components/Chatroom.vue'
import GameLogin from '@/components/GameLogin.vue'
import GameTutorial from '@/components/GameTutorial.vue'
import NavBar from '@/components/NavBar.vue'
import ModalUser from '@/components/ModalUser.vue'
import GameKeyAuthenticator from '@/components/GameKeyAuthenticator.vue'
import MessageToaster from '@/components/MessageToaster.vue'
// eslint-disable-next-line
import InfoPanel from '@/components/InfoPanel.vue'
// eslint-disable-next-line
import UnityNavBar from '@/components/UnityNavBar/index.vue'
import DevConsole from '@/components/DevConsole.vue'
// import PlayFab from 'playfab-sdk'
// import playfabConsole from '@/components/playfabConsole.vue'

export default {
	components: {
		// FurioosStarter,
		BottomMenu,
		// NewContentAvailableToastr,
		Chatroom,
		GameLogin,
		GameTutorial,
		NavBar,
		ModalUser,
		GameKeyAuthenticator,
		MessageToaster,
		// eslint-disable-next-line
		InfoPanel,
		CloudGameStarter,
		// eslint-disable-next-line
		UnityNavBar,
		DevConsole
	},
	props: {
		isDev: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			// basic
			BuildVersion: 'SunsetTown',
			projectPath: '23-01-SunsetTown',
			// furioosLinkId: null,
			// furioosLinkId_backup: null,
			cloudPlatform: 'ubitus',
			firebaseProjName: 'srvc-sunsetmusic',
			firebaseAPIId: 'AIzaSyAwgJk2UfbLzIKVWt-NHUt6C26hmEjR_cA',
			playfabTitleId: '88C3C',

			// copy feature
			isCopied: false,
			isChatFocus: false,

			// UI States
			entered: false,
			showLoading: false,
			showBrowserWarn: false,
			showFSWaitWarm: false,
			showGameLogin: false,
			// showOKBtn: false,
			// onNextBtn: false,
			showChat: false,
			showTutorial: false,
			showInfo: true,
			showInfoTips: false,
			visibleInfoBtn: false,
			showSendChatBtn: false,
			showLiveStream: false,
			showCongradulation: false,
			chatLock: false,
			showEnter: true,
			hideGameUI: false,

			showZoneReport: false,

			// ui image url
			liveImg: '/img/intro/icon_live.svg',

			// game state
			isGameAvailable: false,
			gameStarted: false,
			GameEnd: false,
			gameSession: '',
			dumInput: null,
			gameTime: null,

			// sys
			currentTime: null,
			currentHour: null,
			currentMin: null,

			currentUTCHour: null,
			currentUTCMin: null,

			// CloudGame States
			streamStarted: false,
			isUnitySystemLoaded: false,
			downloadProgress: 0,
			WarnText: '<p>Loading...</p>',
			progressMode: 'none',
			autoStart: false,
			reservationMode: false,

			ubitusSettings: null,
			shwoDebugGameAuth: false,
			DebugGameAuthMode: false,
			forceEnter: false,

			// FurioosContainer config
			useMobileJoystick: false,
			usePCInput: false,

			// multiplayer server
			zoneServerReport: null,

			// device state
			MBOrientation: false,
			heightDiff: 0,

			// user input
			userName: '',

			// Firestore
			gotFirestoreData: false,
			
			// User data
			hasKeyAuth: false,
			
			// debug
			dummyStart: true,
			debugUI: false,
			devPermit: false,
			
			// Info display
			notOpenText: '',
			// notificationText: '建議配戴耳機以獲得最佳觀賞體驗',
			notificationText: '',
			//  TODO: Need to Add: Live Link
			externalLink: '',
			//  TODO: Technical Issue, Need to Add firebase
			techWarning: false
		}
	},
	watch: {
		user: {
			handler(user) {
				if (!user && this.streamStarted) {
					this.setPreloading(true)
					setTimeout(() => {
						window.location.reload()
					}, 1000)
				}
			}
		},
		currentUTCMin: {
			handler(currentUTCMin) {
				if (this.forceEnter) return

				// this is local time, not utc
				// if (this.currentHour >= this.gameTime?.end?.toDate().getHours() && currentMin >= this.gameTime?.end?.toDate().getMinutes() && this.streamStarted) {
				if (this.currentUTCHour >= this.gameTime?.end?.toDate().getUTCHours() && currentUTCMin >= this.gameTime?.end?.toDate().getUTCMinutes() && this.streamStarted) {
				// if (this.currentHour >= 21 && currentMin >= 0 && this.streamStarted) {
					// show gameEnd
					this.onUnityGameEnd()
				}
				// console.log(currentTime)
			}
		},
		forceEnter: {
			handler(forceEnter) {
				if (forceEnter) {
					this.isGameAvailable = true
				} else {
					this.isGameAvailable = false
				}
			}
		}
	},

	computed: {
		...mapState('app', ['preloading']),
		...mapState('authentication', ['user', 'sessionToken', 'gameToken']),
		clientReady() {
			if (
				!this.showBrowserWarn &&
				this.isGameAvailable &&
				this.gotFirestoreData &&
				this.entered
			) {
				// console.log(
				// 	'clientReady true',
				// 	this.showBrowserWarn,
				// 	this.isGameAvailable,
				// 	this.entered
				// )
				return true
			}
			// console.log(
			// 	'clientReady false',
			// 	this.showBrowserWarn,
			// 	this.isGameAvailable,
			// 	this.entered
			// )
			return false
		},
		gameReady() {
			if (this.clientReady && this.isUnitySystemLoaded) {
				// console.log(
				// 	'gameReady true',
				// 	this.clientReady,
				// 	this.isUnitySystemLoaded
				// )
				return true
			}
			// console.log(
			// 	'gameReady false',
			// 	this.clientReady,
			// 	this.isUnitySystemLoaded
			// )
			return false
		},
		hasGameToken() {
			if (this.gameToken) {
				console.log(
					'hasGameToken true'
				)
				return true
			}
			return false
		},
		getCookie(cname) {
			return getCookie(cname)
		},
		isIOS() {
			return isIOS
		},
		isIOS13() {
			return isIOS13
		},
		isTablet() {
			return isTablet
		},
		isMobile() {
			return isMobile
		},
		isChrome() {
			return isChrome
		},
		isAndroid() {
			return isAndroid
		},
		showWarn() {
			if (this.showBrowserWarn || this.showFSWaitWarm) return true
			return false
		},
		LoadText() {
			if (this.isGameAvailable) {
				return 'LOADING...'
			}
			return this.notOpenText
		},
		copyText() {
			if (this.isCopied) return 'COPIED'
			return 'COPY'
		},
		webHandle() {
			// if device was mobile or tablet, it will not load
			if (this.isMobile || this.isTablet) return false
			if (this.isDev) {
				if (this.devPermit) {
					// dev login success
					return true
				}
				// in dev mode, but password is not correct
				return false
			}
			// normal mode
			return true
		},
	},

	beforeMount() {
		// if (!this.isDev) return
		// this.DebugOverride()
		// console.log(
		// 	'BuildVersion ',
		// 	this.BuildVersion,
		// 	// this.furioosLinkId
		// )
	},

	async mounted() {
		if (!isMobile) {
			window.addEventListener(
				'keydown',
				e => {
					// console.log(e.code)
					if (e.code) this.onKeyInput(e.code)
					if (e.code === 'Tab') {
						if (this.streamStarted) {
							e.preventDefault();
							this.$refs.UnityNavBar.toggleNav()
						}
					}
					// this.onChatInput(e.key)
				},
				false
			)

			this.$gtag.event('PC User', { method: 'Google' })
		} else {
			this.$gtag.event('Mobile User', { method: 'Google' })
		}

		if (this.isDev) {
			firebase
			.firestore()
			.collection('_projects')
			.doc(this.projectPath)
			.collection('frontend')
			.doc('states')
				.onSnapshot(
					doc => {
						// make sure the doc exists & has data
						if (doc.data()) {
							this.adminPassword = doc.data().adminPassword
						} 
					},
					err => {
						console.log('Unable to fetch the document:', err.message)
					}
				)
		}

		window.setInterval(() => {
			const now = new Date()
			this.currentTime = now.getTime()
			this.currentHour = now.getHours()
			this.currentMin = now.getMinutes()

			this.currentUTCHour = now.getUTCHours()
			this.currentUTCMin = now.getUTCMinutes()
		}, 1000)

		// if (isMobile) {
		// 	this.initBrim()
		// 	// this.entered = true
		// 	if (isIOS && isChrome) {
		// 		window.addEventListener('resize', () => this.checkMinimal(), false)
		// 		window.addEventListener('scroll', () => this.checkMinimal(), false)
		// 		this.checkMinimal()
		// 	}
		// }
	},

	methods: {
		...mapMutations('app', ['setPreloading', 'setMessageToastrContent', 'setMessageFadeTime']),
		...mapMutations('authentication', ['setGameToken']),
		DebugOverride() {
			// debug override
			// if (this.$route.query.fs != null) {
			// 	this.furioosLinkId = this.$route.query.fs
			// }
			// if (this.$route.params.linkid != null) {
			// 	this.furioosLinkId = this.$route.params.linkid
			// }
			if (
				this.$route.hash === '#demo' ||
				this.$route.query.demo === '1' ||
				this.isDev
			) {
				this.isDev = true
				this.isGameAvailable = true
			}
			console.log(
				'debugOverride',
				'D',
				this.isDev,
				'IGA',
				this.isGameAvailable,
				// this.furioosLinkId
			)
		},
		Logout() {
			this.$refs.modalUser.Logout()
			this.OnUserLogout()
		},
		OnRegister() {
			if (this.gameSession !== 'test') return
			this.showCongradulation = true
			setTimeout(() => {
				this.showCongradulation = false
			}, 10000);
		},
		OnUserLogout() {
			console.log('OnUserSignOut')
			this.hasKeyAuth = false
			this.$refs.gameKeyAuthenticator.OnUserLogout()
		},
		GetSignInGameData(userId) {
			this.$refs.gameKeyAuthenticator.GetSignInGameData(userId, this.gameSession, this.projectPath)
		},
		GetFirestoreData() {
			firebase
				.firestore()
				.collection('_projects')
				.doc(this.projectPath)
				.collection('frontend')
				.doc('states')
				.onSnapshot(
					doc => {
						// make sure the doc exists & has data
						if (doc.data()) {
							this.OnGetFirestoreData(doc.data())
						} else {
							console.log('that document does not exist')
						}
					},
					err => {
						console.log('Unable to fetch the document:', err.message)
					}
				)
		},
		OnGetFirestoreData(data) {
			// console.log('data', data)
			// console.log('notOpenText', data.notOpenText)
			this.notOpenText = data.notOpenText
			this.notificationText = data.notificationText
			this.externalLink = data.liveLink
			this.techWarning = data.technicalWarning
			this.showLiveStream = data.showLiveStream
			// this.GameEnd = data.isGameEnd
			this.adminPassword = data.adminPassword
			this.reservationMode = data.reservationMode
			this.gotFirestoreData = true
			// if (data.furioosLinkId) this.furioosLinkId = data.furioosLinkId
			// if (data.ubitusSettings) this.ubitusSettings = data.ubitusSettings
			if (this.isDev) {
				this.ubitusSettings = data.ubitusSettingsDev
				console.log('☁Ubitus: dev mode')
			} else {
				this.ubitusSettings = data.ubitusSettings
			}

			this.discordUrl = data.discordUrl
			this.facebookUrl = data.facebookUrl
			this.igUrl = data.igUrl

			// Check Open Hour
			// this.isGameAvailable = data.isOpened

			// Normal format time check
			// if (data.openTime.open && data.openTime.close) {
			// 	const datetimeNow = new Date()
			// 	let isOpenHour = false

			// 	const openTimeToday = data.openTime.open.toDate()
			// 	openTimeToday.setMonth(datetimeNow.getMonth())
			// 	openTimeToday.setDate(datetimeNow.getDate())

			// 	const closeTimeToday = data.openTime.close.toDate()
			// 	closeTimeToday.setMonth(datetimeNow.getMonth())
			// 	closeTimeToday.setDate(datetimeNow.getDate())

			// 	console.log('opentime', datetimeNow, openTimeToday, closeTimeToday)
			// 	if (closeTimeToday > openTimeToday) {
			// 		if (datetimeNow > openTimeToday && datetimeNow < closeTimeToday) {
			// 			isOpenHour = true
			// 		}
			// 	} else {
			// 		console.log('⌚ reversed range')
			// 		if (datetimeNow < openTimeToday && datetimeNow > closeTimeToday) {
			// 			isOpenHour = true
			// 		}
			// 	}
			// 	console.log('⌚ isOpenHour', isOpenHour)
			// 	this.isGameAvailable = isOpenHour
			// }

			// Special format time check
			// TODO: fix only available in actual day of 24 hrs
			const datetimeNow = new Date()
			let isCurrentGameSessionOpen = false
			if (data.openTime.test) {
				const openTime = data.openTime.test.toDate()
				// console.log(openTime)

				if (datetimeNow > openTime) {
					this.gameSession = 'test'
					isCurrentGameSessionOpen = true
				} else {
					// game is not ready, start count down timer
				}
			}
			if (data.openTime.day1) {
				const openTime = data.openTime.day1.toDate()
				// console.log(openTime)

				if (datetimeNow > openTime) {
					this.gameSession = 'day1'
					isCurrentGameSessionOpen = true
				}
			}
			if (data.openTime.day2) {
				const openTime = data.openTime.day2.toDate()
				// console.log(openTime)

				if (datetimeNow > openTime) {
					this.gameSession = 'day2'
					isCurrentGameSessionOpen = true
				}
			}
			if (data.openTime.day3) {
				const openTime = data.openTime.day3.toDate()
				// console.log(openTime)

				if (datetimeNow > openTime) {
					this.gameSession = 'day3'
					isCurrentGameSessionOpen = true
				}
			}
			if (data.openTime.day4) {
				const openTime = data.openTime.day4.toDate()
				// console.log(openTime)

				if (datetimeNow > openTime) {
					this.gameSession = 'day4'
					isCurrentGameSessionOpen = true
				}
			}

			let isGameTime = false
			if (data.gameTime) {
				this.gameTime = data.gameTime
				
				const gameStartUTCHour = this.gameTime.start.toDate().getUTCHours()
				const gameStartUTCMin = this.gameTime.start.toDate().getUTCMinutes()
				const gameEndUTCHour = this.gameTime.end.toDate().getUTCHours()
				const gameEndUTCMin = this.gameTime.end.toDate().getUTCMinutes()

				const now = new Date()

				const nowUTCHour = now.getUTCHours()
				const nowUTCMin = now.getUTCMinutes()

				if (nowUTCHour === gameStartUTCHour && nowUTCMin >= gameStartUTCMin) {
					isGameTime = true
				} else if (nowUTCHour === gameEndUTCHour && nowUTCMin <= gameEndUTCMin) {
					isGameTime = true
				} else if (nowUTCHour >= gameStartUTCHour +1 && nowUTCHour <= gameEndUTCHour -1) {
					isGameTime = true
				} else {
					isGameTime = false
				}

				console.log('isGameTime', isGameTime)
			}

			let isOpened = false
			isOpened = data.isOpened
			console.log('isOpened', isOpened)

			this.isGameAvailable = isCurrentGameSessionOpen && isGameTime && isOpened
			console.log({
				'isGameAvailable': this.isGameAvailable,
				'gameSession': this.gameSession
			})
			if (this.user) this.GetSignInGameData(this.user.id)
			if (data.forceOpen) this.isGameAvailable = data.forceOpen

			// this.GetSignInGameData(this.user)
			if (this.isDev) this.DebugOverride()
		},
		OnGameAuth(state) {
			// true: auto start game, false: ban user immediately
			this.hasKeyAuth = state
			this.setPreloading(false)
			this.setMessageFadeTime(5000)
			this.setMessageToastrContent(`Welcome to Sunset Town, ${this.user.displayName}!`)
		},
		StarterState(FunctionName, param) {
			switch (FunctionName) {
				case 'UbiOnClickPlay':
					this.$gtag.event('UbiOnClickPlay', { method: 'Google' })
					break
				case 'UbiOnStreamStart':
					this.WarnText = '<p>Loading...</p>'
					this.progressMode = 'gradient'
					this.streamStarted = true
					// this.showInfo = true // tutorial
					this.showTutorial = true
					this.showInfoTips = true // tu tips
					this.$gtag.event('UbiOnStreamStart', { method: 'Google' })
					break
				case 'BrowserNotSupported':
					this.showBrowserWarn = true
					break
				case 'OnProgressStart':
					this.WarnText = '<p>Please wait...</p>'
					this.showFSWaitWarm = true
					this.progressMode = 'step'
					break
				case 'externalProgress':
					this.downloadProgress = param
					break
				case 'FSConnecting':
					this.WarnText = '<p>Connecting...</p>'
					this.progressMode = 'gradient'
					break
				case 'FSWaitInLine':
					this.WarnText = '<p>Please wait in line</p>'
					this.progressMode = 'gradient'
					this.showFSWaitWarm = true
					break
				case 'FSHasRezMachine':
					// 有預約機器，10秒內載入
					this.WarnText = '<p>Loading...</p>'
					this.progressMode = 'gradient'
					this.showFSWaitWarm = true
					break
				case 'FSFull':
					this.WarnText =
						'<p>The server is currently full,</p> <p>please wait patiently or try again later.</p>'
					this.progressMode = 'gradient'
					this.$gtag.event('FSFull', { method: 'Google' })
					break
				case 'FSSessionStopped':
					this.WarnText = '<p>Connection lost, please refresh.</p>'
					this.progressMode = 'none'
					this.showFSWaitWarm = true
					this.$gtag.event('FSSessionStopped', { method: 'Google' })
					break
				case 'FSAppCrashed':
					this.WarnText = '<p>Connection lost, please refresh.</p>'
					this.progressMode = 'none'
					this.showFSWaitWarm = true
					this.$gtag.event('FSAppCrashed', { method: 'Google' })
					break
				case 'FSOnStreamStart':
					console.log('FSOnStreamStart')
					this.WarnText = '<p>Logging in</p>'
					this.progressMode = 'none'
					this.showFSWaitWarm = false
					this.$gtag.event('FSOnStreamStart', { method: 'Google' })
					break
				default:
					break
			}
		},
		// eslint-disable-next-line
		onGetUnityMessage(FunctionName, param0 = null) {
			// console.log('project Vue_ogum', FunctionName, param0)
			if (FunctionName === 'OnReturnKeyDown') {
				if (!this.isChatFocus) {
					// TODO: fix chatfocus with furioos, mouse stucked issue

					// this.$refs.ChatInput.focus()
					// this.isChatFocus = true
				}
			// } else if (FunctionName === 'OnTutorialKeyDown') {
			// 	this.$refs.BottomMenu.toggleTutorial()
			} else if (FunctionName === 'ShowUI') {
				console.log('Frontend ShowUI')
				if (this.isMobile) {
					this.useMobileJoystick = true
					this.showChat = true
					this.visibleInfoBtn = true
				} else {
					this.showChat = true
					this.visibleInfoBtn = true
				}
			} else if (FunctionName === 'HideUI') {
				console.log('Frontend HideUI')
				if (this.isMobile) {
					this.useMobileJoystick = false
					this.showChat = false
					this.visibleInfoBtn = false
				} else {
					this.showChat = false
					this.visibleInfoBtn = false
				}
			} else if (FunctionName === 'PlayerCustomizeUpdateUser') {
				const data = param0.split(' ')
				const skinIdx = data.map((str) => {
					return parseInt(str, 10)
				})
				const userRef = firebase.firestore().collection('users').doc(this.user.id)
				userRef.set({
					skinIdx
				}, { merge: true })
				.then(() => {
					console.log('user update skinIdx success')
				})
			} else if (FunctionName === 'SetInfoBubble') {
				this.$refs.InfoPanel.SetBubble(true, param0)
			} else if (FunctionName === 'ExitInfoBubble') {
				this.$refs.InfoPanel.SetBubble(false)
			} else if (FunctionName === 'ConnectedZoneServer') {
				this.zoneServerReport = JSON.parse(param0)
				// console.log(this.zoneServerReport)
			} else if (FunctionName === 'OnInvokeDialogueBehavior') {
				this.chatLock = true
			} else if (FunctionName === 'OnEndDialogueBehavior') {
				this.chatLock = false
			}
		},
		sendUnityMessage(functionName, data = null) {
			if (functionName === 'focusCloudContainer') {
				this.focusCloudContainer()
				return
			}
			// console.log('sendUnityMessage ', functionName, data)
			this.$refs.StarterRef.sendUnityMessage(functionName, data)
		},
		onUserClickPlay() {
			this.showGameLogin = true
		},
		onUnitySystemLoaded() {
			if (!this.gameStarted) {
				// this.showInfo = false
				this.showTutorial = false
				this.showInfoTips = false
				this.showLoading = false
				this.showGameLogin = true // deprecate: showIdInput
				this.isUnitySystemLoaded = true
				// this.sendUnityMessage('SetInputFocusInUnity', 'false')
				// this.sendUnityMessage('SetCursorFree')
				if (isMobile) this.sendUnityMessage('HideTopBar')
				// if (!isMobile) this.$refs.IDinput.focus()
				this.$gtag.event('onUnitySystemLoaded', { method: 'Google' })
			}
		},
		onUnityGameEnd() {
			this.GameEnd = true
		},
		// eslint-disable-next-line
		onKeyInput(key) {
			// console.log(key)
			if (key === 'KeyQ' && !this.gameStarted) {
				if (this.streamStarted)	
					this.toggleTutorial()
			} else if (this.isDev && key === 'Equal') {
				this.debugUI = !this.debugUI
			} else if (key === 'Slash') {
				this.shwoDebugGameAuth = !this.shwoDebugGameAuth
			}
		},
		OnUbiKeyInput(uo) {
			if (uo.type === 'keyboard') {
				console.log(uo.keyCode)
				switch (uo.keyCode) {
					// tab
					case 9:
						this.$refs.UnityNavBar.toggleNav()
						break;
					// Q: tutorial
					case 81:
						this.$refs.BottomMenu.toggleInfo()
						break;
					case 13:
						if (!this.chatLock) {
							this.$refs.chatroom.focusInput()
							this.sendUnityMessage('SetCursorFree')
						}
						break;
					// e key
					case 69:
						this.$refs.InfoPanel.SetPanel(true)
						break;
					// \ key
					case 220:
						this.showZoneReport = !this.showZoneReport
						break;
					// h key
					case 72:
						this.hideGameGUI()
						break;
					default:
						break;
				}
			}
		},
		OnGetUbitusConfigDetails(report) {
			if (this.isDev) {
				console.log("☁Ubitus report:", report)
			}
		},
		toggleTutorial(value = null) {
			if (value) {
				this.showTutorial = value
			} else {
				this.showTutorial = !this.showTutorial
			}
		},
		StartGame() {
			if (this.gameStarted) return
			if (isMobile) {
				this.useMobileJoystick = true
			} else {
				// this.usePCInput = true
				this.showChat = true
			}
			this.showGameLogin = false // deprecate: showIdInput
			this.visibleInfoBtn = true
			this.gameStarted = true
			this.sendUnityMessage('StartGame')
			this.sendUnityMessage('SetNXPInput', 'true')
			this.sendUnityMessage('SetCursorLock')
			// this.sendUnityMessage('SetCursorToggle')

			// this.sendUnityMessage('SetInputFocusInUnity', 'true')
			
			this.$gtag.event('StartGame', { method: 'Google' })
		},
		toggleInfo(toggle) {
			// this.showInfo = toggle
			this.showTutorial = toggle
		},
		toggleChat(toggle) {
			this.$refs.chatroom.toggleMobileChat(toggle)
		},
		copyToClipboard(textArea) {
			document.execCommand('copy')
			document.body.removeChild(textArea)
		},
		copyLink() {
			this.isCopied = true
			const textArea = document.createElement('textArea')
			textArea.value = window.location.href
			console.log('copylink href', textArea.value)
			if (isIOS) {
				textArea.value = location.href // eslint-disable-line
				console.log('copylink location', textArea.value)
			}
			document.body.appendChild(textArea)

			if (isIOS) {
				const range = document.createRange()
				range.selectNodeContents(textArea)
				const selection = window.getSelection()
				selection.removeAllRanges()
				selection.addRange(range)
				textArea.setSelectionRange(0, 999999)
			} else {
				textArea.select()
			}

			this.copyToClipboard(textArea)
			// navigator.clipboard.writeText('test');
		},
		enter(value) {
			this.entered = value
			this.showEnter = false
			// console.log(this.entered)
			// this.showLoading = true
			// document.body.requestFullscreen()
			// if (value) {
				// generate gameToken
				// document.body.requestFullscreen()
			// } else {
				// document.exitFullscreen()
			// }
		},
		focusCloudContainer() {
			this.$refs.StarterRef.focusCloudContainer()
		},
		// devLogin() {
		// 	if (this.devPassword === this.adminPassword) {
		// 		this.setPreloading(true)
		// 		this.devPermit = true
		// 		// this.debugUI = false
		// 	}
		// },
		hideGameGUI() {
			this.hideGameUI = !this.hideGameUI
		},
        OpenExternalUrl(app) {
			let url = ''
			switch(app) {
				case 'discord':
					url = this.discordUrl
					break;
				case 'facebook':
					url = this.facebookUrl
					break;
				case 'instagram':
					url = this.igUrl
					break;
				case 'yt-streaming':
					url = this.ytUrl
					break;
				default:
					break;
			}
            window.open(url);
        },
		OnChangeChatDisplayName(userName) {
			this.userName = userName
		},
		OnDevPermit(state) {
			this.devPermit = state
		}
	}
}
</script>

<style lang="scss" scoped>

@media (orientation: portrait) {
	#brim-mask {
		background: none;
	}
}

@media (orientation: landscape) {
	#brim-mask {
		background-color: rgba(0, 0, 0, 1);
	}
}

#brim-main {
	max-width: 100vw;
	overflow: hidden !important;
	&.UnityLoaded {
		touch-action: none;
	}
}

#progressGroup {
	position: absolute;
	left: 0vw;
	bottom: 0vw;
	width: 100vw;
	z-index: 999;
	height: 0.8vw;
	background: black;

	@keyframes progressbar-animation {
		0% {
			background-position: 0% 0%;
		}
		100% {
			background-position: -200% 0%;
		}
	}

	.progress-bar {
		.progress-fill {
			position: absolute;
			height: 100%;
			max-width: 100vw;
			background: #343CFF;
			transition: width 0.1s;
		}
	}

	.progress-gradient {
		background: #FF6900;
		background: linear-gradient(
			to right,
			#66BAFF 0%,
			#FF6900 15%,
			#66BAFF 40%,
			#FF6900 60%,
			#66BAFF 100%
		);
		width: 100%;
		height: 100%;
		background-size: 200%;
		-webkit-animation: progressbar-animation 3s linear infinite;
		-moz-animation: progressbar-animation 3s linear infinite;
		-o-animation: progressbar-animation 3s linear infinite;
		animation: progressbar-animation 3s linear infinite;
	}

	&.mobile {
		top: 98vh;
		bottom: auto;
		height: 2vw;
		.progress-bar {
			.progress-fill {
				height: 2vw;
			}
		}
	}

	@keyframes progressbar-animation {
		0% {
			background-position: 0% 0%;
		}
		100% {
			background-position: -200% 0%;
		}
	}
}
.register-cons {
	position:fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	width: 40rem;
	background: white;
	border-radius: 2vw;
	padding: 2.5rem;
	z-index: 1000;
    box-shadow: 4px 20px 70px rgba(0, 0, 0, 0.25);

	.cons-space {
		height: 3vh
	}

	p {
		font-family: 'Inter';
		// font-size: .73vw;
		font-size: 1rem;
		color: #000000;
		text-align: center;
		margin: 1rem 0;
	}
	.sm {
		display: inline-flex;
		column-gap: 1vw;
		margin: 0 0 2vh 0;
	}
}

.yongge-img {
	position:fixed;
	z-index: 1;
	// width: 33%;
	width: 33vw;
	right: 0;
	bottom: 0;
	transition: all 0.2s;
}
.yongge-img-loading {
	// width: 26%;
	width: 26vw;
}

.warn-VmWait {
	position: absolute;
	left: 2vw;
	bottom: 5vh;
	font-family: 'Inter';
	font-size: 2vw;
	font-weight: bold;
}

#wireframe {
	position: fixed;
	z-index: 999;
	bottom: 0;
	right: 0;
	text-align: end;
}

#debug {
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	color: rgba(255, 255, 255, 0.5);
	background: rgba(0, 0, 0, 0.1);
	p {
		color: rgba(255, 255, 255, 0.3);
		font-size: 10px;
	}
}
</style>
