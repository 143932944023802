<template>
  <transition name="modal-fade">
	<div v-show="isOpen || !Closetitle"
		:class="`modal-overlay ${isActive?'modal-overlay--active':''}`"
		:style="{ display: final }"
	>
      <div class="modal-bg"></div>
		<!-- Content Wrapper -->
		<div class="modal-wrapper">
			<!-- Close Button (only if this modal is active) -->
			<!--<button v-show="!display" class="btn-modal-close" @click="close">X</button>-->
			<!-- Content -->
			<slot :close="close"></slot>
		</div>
		<!-- Child Modal Slot (will be able to take full screen size) -->
		<slot name="child"></slot>
	</div>
  </transition>
</template>

<script>
	// import { mapState } from 'vuex'
	// v-if="isOpen"
	export default {
		name: "ModalWrapper",
		props: {
			name: { type: String, required: true },
			display: {type: Boolean},
			closetitle: {type: Boolean},
		},
		data() {
			return {
				doubleclose: false,
				final: 'flex',
			}
		},
		mounted(){
			// console.log('ModalWrapper Mounted')
		},
		computed: {
		    // ...mapState('modals'),
			isActive() {				
				// this.$emit('setModalSetting', this.$store.state.modals.userMode)
				// console.log('ModalWrapper isActive setModalSetting', this.$store.state.modals.setting)
				return this.$store.getters["modals/active"] === this.name
			},
			isOpen() {
				// let ans
				// if(this.doubleclose)
				// {
				// 	ans = false	
					// console.log('ans', ans)
				// }	
				// else
				// {
					// ans = this.$store.getters["modals/allOpen"].includes(this.name)
					// console.log('ModalWrapper isOpen')
				// }
				return this.$store.getters["modals/allOpen"].includes(this.name)
			},
			Closetitle(){
				return this.closetitle
			}
		},
		methods: {
			close() {
				this.closetitle = true
				this.$store.dispatch("modals/close", this.name)
				// this.doubleclose = true
				this.final = 'none'
				// console.log("close()")
			}
		},
		beforeDestroy() {
			if (this.isOpen) this.close()
		}
	}
</script>

<style scoped>
	/* *:focus {
		outline: none;
	}
	.modal-overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transition: opacity 0.3s ease-in-out;
		width: 100%;
	} */

	/* .modal-wrapper {
		background-color: #fff;
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
		padding: 20px;
		transition: all 0.3s ease;
		max-height: 80%;
		max-width: 300px;
		width: 100%;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.modal-wrapper button {
		float: right;
	} */
</style>