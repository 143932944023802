<template>
    <div
    v-if="showChat"
    id="chatInput"
    class="none-select"
    :class="{'hideGameUI' : hideGameUI }"
    style="z-index: 999"
    >
        <div
            v-if="isMobile && showMobileChatInput"
            class="Landscape-only ChatInput inputField"
            :class="{ chatMask: isChatFocus, float: inputFloat }"
        >
            <input
                ref="ChatInput"
                v-model="chatInputValue"
                class="MBchat"
                placeholder="Chat"
                name="chatroom"
                maxlength="100"
                @focus.prevent="onChatFocus()"
                @blur.prevent="onChatBlur()"
            />
            <button
                class="MBsendBtn btn-game"
                :class="{ float: inputFloat }"
                @touchstart="sendChatDummy"
            >
                Send
            </button>
        </div>
        <div
            v-else-if="!isMobile"
            id="chatContainer"
            class="chatContainer"
            :class="{ openChat: isChatFocus }"
            @mouseover.prevent="onChatFocus()"
            @mouseleave.prevent="onChatBlur()"
        >
            <div ref="chatTextContainer" class="chatTextContainer">
                <div v-for="item in chatroom" :key="item.key" class="chatText">
                    <p class="chatId">{{ item.username }}</p>
                    <p class="chatMsg">{{ item.message }}</p>
                </div>
            </div>
            <input
                ref="ChatInput"
                v-model="chatInputValue"
                class="ChatInput chat"
                v-on:keydown.enter="sendChatDummy"
                maxlength="100"
                placeholder="Press ENTER to chat"
                @focus.prevent="onChatFocus()"
                @blur.prevent="onChatBlur()"
            />
            <p v-show="chatError" class="chatErr">Please enter some words.</p>
        </div>

        <!-- Rtdb Tool -->
        <div v-if="useRtdbTool">
            <button @click.prevent="runChatCopy" style="top: 0; right: 0; position: relative; z-index: 999">Copy Chat Dummy</button>
            <button @click.prevent="runDelete" style="top: 0; right: 0; position: relative; z-index: 999">Delete Chat Dummy</button>
        </div>
    </div>
</template>


<script>
import firebase from 'firebase/app'
import { isMobile, isIOS } from 'mobile-device-detect'

export default {
    props: {
        showChat: {
            type: Boolean,
            default: false
        },
        userId: {
            type: String,
            default: ''
        },
        userName: {
            type: String,
            default: ''
        },
        projectPath: {
            type: String,
            default: ''
        },
		hideGameUI: {
			type: Boolean,
			default: false,
		}
    },
    data() {
        return {
            // shared
            isChatFocus: false,
            chatscrolled: false,
            chatroom: [],
            chatInputValue: '',
            chatError: false,

            // mobile
            showMobileChatInput: false,
            showSendChatBtn: false,
            inputFloat: false,

            // Rtdb tool
            useRtdbTool: false,
            fromRef: 'chatroom',
            toRef: `${this.projectPath}/chatroom`
        }
    },
    async mounted() {
        this.GetRTDBData()
    },
    computed: {
        isIOS() {
			return isIOS
		},
        isMobile() {
            return isMobile
        }
    },
    methods: {
        GetRTDBData() {
        // TODO: projectPath
        const msgRef = firebase.database().ref(`${this.projectPath}/chatroom`)
        msgRef.on('value', snapshot => {
            if (snapshot.val()) {
                const data = snapshot.val()
                this.chatroom = data
            } else {
                console.log('that document does not exist')
            }
        })
        },
        PostRTDBData(data) {
            const msgRef = firebase.database().ref(`${this.projectPath}/chatroom`)
            const {key} = msgRef.push()
            msgRef.child(key).set({ key, ...data })
        },
        runChatCopy() {
            this.copyRtdbRecord(this.fromRef, this.toRef)
        },
        runDelete() {
            this.deleteRtdbRecord(this.fromRef)
        },
        copyRtdbRecord(oldRef, newRef) {
            oldRef.once('value').then(snap => {
                return newRef.set(snap.val());
            }).then(() => {
                console.log('Done!');
            }).catch(err => {
                console.log(err.message);
            });
        },
        deleteRtdbRecord(ref) {
            ref.remove()
        },
        onChatFocus() {
            if (!isMobile && !this.chatscrolled) {
                setTimeout(() => this.chatScrollToBottom()) // pc chat
                this.chatscrolled = true
            } 
            this.isChatFocus = true
            if (isIOS) {
                this.inputFloat = true
                setTimeout(() => window.scrollTo(0, 0), 550)
            }
            if (isMobile) this.showSendChatBtn = true
        },
        onChatBlur() {
            if (!isMobile) {
                this.chatScrollToBottom()
                this.chatscrolled = false
            }
            this.isChatFocus = false
            this.showSendChatBtn = false
            this.inputFloat = false
            this.$emit('sendUnityMessage', 'SetCursorLock')
        },
        sendChatDummy() {
            if (!this.chatInputValue.trim()) {
                // TODO error warning
                // this.chatError = true
                // setTimeout(()=>{
                // 	this.chatError = false
                // }, 4000)
                
                if (isMobile) return;
                this.$refs.ChatInput.blur()
                // const chatContainer = document.getElementById('chatContainer')
                // const mouseover = new MouseEvent('mouseover', {
                // 	'view': window,
                // 	'bubbles': true,
                // 	'cancelable': true
                // });
                // chatContainer.dispatchEvent(mouseover);

                // this.$emit('sendUnityMessage', 'SetCursorToggle')
                // this.$emit('sendUnityMessage', 'SetInputFocusInUnity', 'true')
            } else {
                console.log('sendChatDummy:', this.chatInputValue)
                this.sendChat()
            }
            this.$emit('focusCloudContainer')
        },
        sendChat() {
            const chat = {
                uid: this.userId,
                username: this.userName,
                message: this.chatInputValue,
                timestamp: Date.now()
            }
            console.log('sendChat', chat)

            // TODO Push msg to firebase
            this.PostRTDBData(chat)

            if (isMobile) {
                if (isIOS) this.inputFloat = false
                // this.toggleMobileChat(false)
            }
            this.showSendChatBtn = false
            // if (!this.isChatFocus) return
            // this.$refs.StarterRef.SetUserActive()
            this.$refs.ChatInput.blur()
            // this.sendUnityMessage('SetInputFocusInUnity', 'true')
            // this.$emit('sendUnityMessage', 'SetCursorLock')
            // this.isChatFocus = false
            // this.sendUnityMessage('SendChatInput', this.chatInputValue)
            const chatMsg = this.chatInputValue
            this.chatInputValue = ''
            this.$emit('sendUnityMessage', 'OnChatInput', chatMsg)
        },
        chatScrollToBottom() {
            const openChatContainer = document.getElementById('chatContainer')
            openChatContainer.scrollTop = openChatContainer.scrollHeight
            this.$refs.chatTextContainer.scrollTop = this.$refs.chatTextContainer.scrollHeight
            // console.log('chat scroll to bottom')
        },
        toggleMobileChat(toggle) {
            this.showMobileChatInput = toggle
            if (toggle) { this.onChatFocus() }
            else { this.onChatBlur() }
            console.log(this.showChat, isMobile, this.showMobileChatInput, this.isChatFocus)
        },
        focusInput() {
            this.$refs.ChatInput.focus()
        }
    }
}
</script>