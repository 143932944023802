<template>
    <div>
        <transition name="fade">
            <div v-show="showMessage" class="messageContainer">
                {{ message }}
            </div>
        </transition>
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
    components: {

    },
    props: {

    },
    data() {
        return {
            showMessage: false,
            // fadeTime: 4000
        }
    },
    mounted() {

    },
    computed: {
        ...mapState('app', ['message', 'msgfadeTimeIntervals'])
    },
    watch: {
        message: {
            handler(message) {
                if (message !== '') {
                    this.OnShowMessage()
                }
            }
        }
    },
    methods: {
        OnShowMessage() {
            this.showMessage = true
            // console.log('MessageToaster showMessage', this.message)
            
            // message disappear in seconds
            setTimeout(() => {
                this.showMessage = false
            }, this.msgfadeTimeIntervals)
        }
    }
}
</script>
<style lang="scss">
.messageContainer {
    color: #FF6900;
    background: white;
    border-radius: 2vw;
    // width: 25vw;
    // height: 5vh;
    position: fixed;
    left: 50%;
    top: 5%;
    transform: translate(-50%, 0);
    text-align: left;
    // display: flex;
    z-index: 99;
    padding: .7rem 4rem .7rem 4rem;
    font-family: 'Inter';
    font-weight: bold;
    font-size: 1vw;
    box-shadow: 4px 20px 70px rgba(0, 0, 0, 0.25);
}
.message-text {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
</style>