<template>
<!-- Sorce: https://github.com/fpluquet/vue-avatar-editor -->
  <div>
    <vue-avatar
      :width='width'
      :height='height'
      :rotation="rotation"
      :borderRadius="borderRadius"
      :border='border'
      :color='color'
      :scale="scale"
      :image="image2"
      ref="vueavatar"
      @vue-avatar-editor:image-ready="onImageReady"
      @select-file="onSelectFile($event)"
      @sizeError="sizealert"
      @edited="prepared"
      @preview="sendPreview"
      @onDrag="onDrag"
      >
    </vue-avatar>

    <label v-if="hasScale" class="scalelabel">
      <input
        type="range"
        min=1
        max=3
        step=0.02
        class="custom-slider"
        v-model.number='scale'
      />
      <br>
      <div class="scale-number" :style="{ left: (scale-1)/2*95 + '%' }"> {{scale}}x </div>
    </label>
    
    <label v-if="hasRotation">
      Rotation : {{rotation}}°
      <br>
      <input
        type="range"
        min=0
        max=360
        step=1
        v-model.number='rotation'
      />
    </label>

    <!-- <button
        v-if="showSaveButton"
        @click.prevent="finished"
        class="avatarEditor-btn"
    >
        Save
    </button> -->
    <!-- <button
        v-if="showEditButton"
        @click.prevent="sendPreview"
        class="avatarEditor-btn"
        :disabled="isPreview"
    >
        Preview
    </button> -->
    <!-- <label
        v-show="showEditButton"
        class="done-label"
    >
        {{ doneText }}
    </label> -->
    <!--
    <br>
    <button @click="finished">{{ finishText }}</button>-->
  </div>
</template>

<script>
import VueAvatar from './VueAvatar.vue';

export default {
    watch: {
        scale: {
            handler(oldval, newval) {
                if (oldval !== newval) {
                    // this.showEditButton = true
                    // this.isPreview = false

                    // show preview btn
                    this.$emit('showPreview')
                }
            }
        }
    },
    props: {
        finishText: {
            type: String,
            default: 'Save'
        },
        hasRotation: {
            type: Boolean,
            default: false
        },
        hasScale: {
            type: Boolean,
            default: true
        },
        image: {
            type: String
        },
        image2: {
            type: String,
        },
        border: {
            type: Number,
            default: 0
        },
        borderRadius: {
            type: Number,
            default: 0
        },
        width: {
            type: Number,
            default: 200
        },
        height: {
            type: Number,
            default: 200
        },
        color: {
            type: Array,
            default: () => [0, 0, 0, 0.5]
        }
    },
    data () {
        return {
            rotation: 0,
            scale: 1,
            showEditButton: false,
            doneText: '',
            showSaveState: false,
            // isPreview: false,
        };
    },
    components: {
        VueAvatar
    },
    methods: {
        onSelectFile (files) {
            this.$emit('select-file', files);
            // this.showEditButton = true
            this.$emit('showPreview')
        },
        onImageReady () {
            this.scale = 1;
            this.rotation = 0;
        },

        finished () {
            // this.showSaveButton = false
            // this.showSaveState = true
            // this.doneText = 'Uploading...'
            return this.$emit('finished', this.$refs.vueavatar.getImage());
        },

        sendPreview () {
            // return this.$emit('sendPreview', this.$refs.vueavatar.getImage());
            // this.isPreview = true
            return this.$emit('sendPreview', this.$refs.vueavatar.getImageScaled2());
        },

        prepared(){
            this.$emit('prepared');
        },

        sizealert(){
            this.$emit('sizealert');
        },

        reset () {
            this.$refs.vueavatar.resetImage();
        },

        onDrag() {
            // this.showEditButton = true
            this.$emit('showPreview')
        },

        onUploadFinished() {
            // this.doneText = 'Done'
        }

    }
};

</script>

<style lang="scss" scoped>

.scalelabel {
    color: black;
    font-size: 1.2vw;
    // font-size: 1rem;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
    // transform: translate(-50%, 0px);
    // top: 22.1vw;
    // top: 25rem;
    // bottom: 3rem;
    // left: 19.4vw;
    // width: 7.7vw;
}

.custom-slider {
  -webkit-appearance: none;
//   appearance: auto;
  width: 100%;
  height: 0.05vw; // line style
  //border-radius: 5px;
//   background: black;
  background: none;
  outline: none;
  opacity: 1;
  -webkit-transition: .2s;
  transition: opacity .2s;

  &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        background-color: #000000;
        //clip-path: polygon(0 46%, 100% 45%, 100% 60%, 0 61%)
        height: 100%;
        // top: 0;
        width: 10%;
        padding: 0;
        cursor: pointer;
        opacity: 1;
  }

  &::-moz-range-thumb {
      background-color: #000000;
      //clip-path: polygon(0 46%, 100% 45%, 100% 60%, 0 61%);
      height: 100%;
      width: 50%;
      padding: 0;
      opacity: 1;
    }

    &::-webkit-slider-runnable-track {
        background: #b8b8b8;
        height: 0.25em;

    }
    &::-moz-range-track {
        background: #b8b8b8;
        height: 0.25em;

    }
}

.custom-slider:hover {
  opacity: 1;
}

// .custom-slider::-moz-range-track{
//   height:4px;
// //   background:#edc;
//   border:none;
// }

// .custom-slider::-webkit-slider-thumb {
// //   -webkit-appearance: none;
// //   appearance: none;
// //   width: 2vh;
// //   height: 2vh;
//     position: relative;
//   border: 0;
//   border-radius: 50%;
//   background: black;
// //   background-image: url('/img/slider.svg');
// //   background-size: contain;
// //   background-position: center center;
// //   background-repeat: no-repeat;
//   cursor: pointer;
// }

.scale-number{
    // position: absolute;
    left: 0%;
    padding: 2.6px 0 0 0;
}

.avatarEditor-btn {
    position: relative;
    // transform: translate(-50%, 0px);
    margin: 1vh 0;
    color: black;
    border: none;
    border-radius: 2vw;
    width: 7vw;
    height: 3vh;
    cursor: pointer;
    font-size: .7vw;
}

.done-label {
    position: relative;
    // transform: translate(-50%, 0px);
    bottom: 2rem;
    color: black;
}

</style>
