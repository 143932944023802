<template>
    <div class="info-container">
        
        <div v-if="isDebug" style="position: fixed; margin: 1rem;">
            <button v-for="item in infoData" :key="item" @click.prevent="SetBubble(true, item.id)">showBubble{{item.id}}</button>
            <button @click.prevent="SetBubble(false)">hideBubble</button>
            <button @click.prevent="SetPanel(true)">showPanel</button>
            <button @click.prevent="SetPanel(false)">hidePanel</button>
        </div>

        <!--
            Info Bubble
        -->
        <transition name="fade" :duration="1000">
            <div v-if="showBubble" class="info-modal">
                <div class="info-mask">
                    <div>
                        <p>{{queryData.title}}</p>
                        <p>{{queryData.subtitle}}</p>
                        <span>Press E for more</span>
                    </div>
                    <img :src="`${queryData.logoUrl}`" />
                </div>
            </div>
        </transition>

        <!-- 
            Info Panel 
        -->
        <transition name="slidefade">
            <div v-if="showPanel" class="info-panel">
                <div class="top-bar">
                    <img class="close-btn" @click.prevent="SetPanel(false)" src="img/infopanel/close.svg"/>
                    <div class="view-btn" @click.prevent="OpenExternalUrl(queryData.mainUrl)">View more ↗</div>
                </div>
                <div class="browser">
                    <div class="top-panel">
                        <div class="text-group">
                            <p class="title">{{queryData.title}}</p>
                            <p class="title">{{queryData.subtitle}}</p>
                        </div>
                        <img class="logo" :src="`${queryData.logoUrl}`" />
                    </div>
                    <div class="divider-container">
                        <hr class="solid" color="#FF6900" size="4">
                    </div>
                    <div class="content-panel">
                        <div class="text-html" v-html="queryData.content.text"></div>
                        <div class="info-social-container">
                            <img v-show="queryData.social.facebook" @click.prevent="OpenExternalUrl(queryData.social.facebook)" src="img/infopanel/facebook.svg" />
                            <img v-show="queryData.social.twitter" @click.prevent="OpenExternalUrl(queryData.social.twitter)" src="img/infopanel/twitter.svg" />
                            <img v-show="queryData.social.instagram" @click.prevent="OpenExternalUrl(queryData.social.instagram)" src="img/infopanel/instagram.svg" />
                        </div>
                    </div>
                    <div class="divider-container">
                        <hr class="solid" color="white">
                    </div>
                    <div class="bottom-panel">
                        <img v-for="(url) in queryData.content.img" :key="url" :src="`${url}`">
                    </div>
                    <div class="bottom-view-btn" @click.prevent="OpenExternalUrl(queryData.mainUrl)">View more ↗</div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import firebase from 'firebase/app'

export default {
    props: {
        projectPath: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            bucket: 'formal',
            infoData: [],
            queryData: {},
            showBubble: false,
            showPanel: false,
            gotInfoData: false,
            isDebug: false,
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {
        this.GetInfoData()
    },
    methods: {
        GetInfoData() {
            firebase
                .firestore()
                .collection('_projects')
                .doc(this.projectPath)
                .collection('frontend')
                .doc('infoData')
                .collection(this.bucket)
                .onSnapshot(snapshot => {
                    this.infoData.length = 0
                    snapshot.forEach((doc) => {
                        this.infoData.push(doc.data())
                        // console.log('pushdata', doc.data())
                    })
                    this.gotInfoData = true
                })
        },
        QueryInfoData(id) {
            const data = this.infoData.find((doc) => doc.id === id)
            console.log('query', id, data)
            return data
        },
        /**
         *  When play is in trigger zone, show bubble
         */
        SetBubble(toggle, infoId = '') {
            if (!this.gotInfoData) return
            if (infoId !== '') {
                const query = this.QueryInfoData(infoId)
                this.queryData = query
            }
            this.showBubble = toggle
        },
        /**
        *   When press E key from unity, show panel
        */
        SetPanel(toggle) {
            if (!this.showBubble && !this.showPanel) return 
            this.showBubble = !toggle
            this.showPanel = toggle
			this.$gtag.event(`openInfoPanel_${this.queryData.id}`, { method: 'Google' })

            if (toggle) 
                this.$emit('sendUnityMessage', 'SetCursorFree')
            else {
                this.$emit('sendUnityMessage', 'SetCursorLock')
                this.$emit('sendUnityMessage', 'focusCloudContainer')
            }
        },
        OpenExternalUrl(url) {
            window.open(url);
        }
    }
}
</script>
<style lang="scss">
.info-container {
    position: absolute; 
    z-index: 10001;

    button {
        color: black; 
        padding: 1rem;
    }
}

.info-modal {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 2vw;
    width: 19vw;
    // width: 25rem;
    // height: 8rem;
    height: 10vh;
    border-radius: 5px;
    // background-color: rgba(0, 0, 0, 0.5);
    background-color: rgba(255, 255, 255, 1);
    backdrop-filter: blur(10px);

    .info-mask {
        display: flex;
        flex-direction: row;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        // width: 20rem;
        width: 15vw;
    }

    p {
        font-family: "Inter";
        font-size: .9vw;
        text-align: left;
        font-weight: bold;
        // color: white;
        color:black;
        margin: 0
    }
    
    span {
        display: inline-block;
        font-family: "Inter";
        font-size: .7vw;
        font-weight: bold;
        text-align: left;
        color:#FF6900;
        // color: white;
        margin: 1vw 0 0 0;
    }

    // logo
    img {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        width: 4vw;
        height: 4vw;
    }
}

.info-panel {
    position: fixed;
    right: 0;
    height: 100%;
    width: 45vw;
    // background-color: rgba(0, 0, 0, 0.5);
    background-color: #66BAFF;
    backdrop-filter: blur(10px);
    
    .view-btn {
        position: absolute;
        padding: .3vw .7vw;
        right: 0;
        margin: 2vw 4vw;
        color: black;
        font-family: 'Inter';
        font-size: .7vw;
        font-weight: bold;
        // background: rgba(34, 34, 34, 0.7);
        background: #FF7A00;
        background-blend-mode: multiply;
        border-radius: 45px;
        border: none;
        cursor: pointer;

        transition: .1s all;

        &:hover {
            background: #D75EE7;
        }
    }

    .close-btn {
        position: absolute;
        right: 0;
        margin: 1vw 1vw;
        cursor: pointer;
    }

    .top-bar {
        height: 10vh;
        position: relative;
    }

    .browser {
        display: flex;
        width: 90%;
        height: 85%;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: scroll;
        scrollbar-width: thin;
        scrollbar-color: #FF7A00 transparent;
        margin: auto;

        &::-webkit-scrollbar {
            width: 3px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #FF7A00;
            border-radius: 0px;
            border: 3px solid transparent;
        }
    }

    .top-panel{
        position: relative;
        display: flex;
        flex-direction: row;
        align-self: center;
        justify-content: space-between;
        width: 100%;

        .text-group {
            // width: 38vw;
            // margin: 1vw 0;
            margin: 1vw 2vw 1vw 1.5vw;
        }

        .logo {
            position: relative;
            right: 0;
            // margin: auto 1.5vw auto 1.5vw;
            margin: 1vw 2.5vw;
	        top: 0;
	        bottom: 0;
            // width: 5vw;
            width: 6vw;
            // height: 3.5vw;
        }

        .title {
            font-family: "Inter";
            font-weight: bolder;
            // font-size: 1.45vw;
            font-size: 2vw;
            text-align: left;
            color: black;
            margin: 0
        }
    }

    .content-panel {
        width: 38vw;
        align-self: center;
        margin: 1vw 0;

        span {
            font-family: 'Inter';
            color: black;

        }

        .text-html {
            p {
                font-family: 'Inter';
                font-weight: bold;
                font-size: 1vw;
                color: black;
                text-align: justify;
            }
        }

        .info-social-container {
            display: flex;
            column-gap: .5vw;
            img {
                cursor: pointer;
            }
        }
    }

    .divider-container {
        width: 100%;
        align-self: center;

        hr {
            margin: .5vw 1vw;
        }
    }

    .bottom-panel {
        width: 38vw;
        align-self: center;
        text-align: center;
        margin: 1vw 0;

        img {
            width: 100%;
        }
    }

    .bottom-view-btn {
        margin: 4vh 1vw;
        padding: 1.5vh 0;
        border-radius: 2vw;
        font-family: "Inter";
        font-size: 0.7vw;
        font-weight: bold;
        background: #FF7A00;
        color: black;
        text-align: center;
        cursor: pointer;
        transition: .1s all;

        &:hover {
            background: #D75EE7;
        }
    }
}
    
</style>