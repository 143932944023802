<template>
    <div style="z-index: 9999">
        <div v-if="(!isMobile && showGameLogin)">
            <!-- Unity Avatar -->
            <transition name="fade">
                <div
                    v-show="showSelAvatar"
                    class="selectAvatar-view"    
                >
                    <div 
                        class="selectAvatar-container"
                    >
                        <p>Select Player</p>
                        <div class="avatar-wrapper">
                            <!-- <button 
                                v-for="(object) in avatar"
                                :value="object.name"
                                @click.prevent="setAvatar(object.idx)"
                                :disabled="object.selected"
                                :key="object.idx"
                                >
                                {{object.name}}
                            </button> -->
                            <img
                                v-for="(object) in avatar"
                                :value="object.name"
                                @click.prevent="setAvatar(object.idx)"
                                :key="object.idx"
                                :class="{disable: object.selected}"
                                :src="object.img"
                            />
                        </div>
                        <!-- <button @click.prevent="TestSendUserId">TestSendUserIdAnonymously</button> -->
                    </div>
                    <button class="next-btn" @click.prevent="OnSendAvatar">
                        <img src="img/game/avatar/next_button_arrow.svg" />
                        NEXT
                    </button>
                    <!-- <img class="next-btn" src="img/game/avatar/next_button.svg" /> -->
                </div>
            </transition>

            <!-- Unity Player Customize -->
            <div
                v-show="showEditAvatar"
                class="editAvatar-container"
            >
                <div
                    class="profile-editor"
                >
                    <!-- ID Input -->
                    <div
                        v-show="showEditAvatar" 
                        class="idInputContainer"
                    >
                    <!-- <p class="inputLabel">Your Name</p> -->
                        <!-- <p class="inputLabel">Username</p> -->
                        <input
                            ref="IDinput"
                            v-model="userName"
                            placeholder="Your ID"
                            class="inputField"
                            value
                            required
                            maxlength="20"
                            @focus.prevent="onIdInputFocus"
                        />
                    </div>

                    <!-- User Avatar -->
                    <UserAvatar
                        ref="UserAvatar"
                        @avatarphoto="setUnityAvatar"
                        @updatepic="setNavAvatar"
                        @sendUnityMessage="sendUnityMessage"
                        @showPreview="vueAvatarShowPreview"
                        @OnStartGame="OnStartGame"
                    >
                    </UserAvatar>
    
                    <button
                        :disabled="!showPreview"
                        @click.prevent="OnPreview"
                        class="preview-btn"
                        :class="{ disable: !showPreview }"
                        >
                        PREVIEW
                    </button>
                </div>

                <div
                    class="uavatar-editor"
                >
                    <UnityAvatar
                        ref="UnityAvatar"
                        @sendUnityMessage="sendUnityMessage"
                    >
                    </UnityAvatar>
                </div>
            </div>
            

            <!-- <button
                v-show="(!showOKBtn && showEditAvatar)"
                :disabled="!onNextBtn"
                class="loginConfirm-btn"
                :class="{ disable: !onNextBtn }"
                @click="OnConfirm"
                @mousedown="OnConfirm"
            >
                Confirm
            </button> -->

            <button 
                class="next-btn"
                v-show="(!showOKBtn && showEditAvatar)"
                @click="OnConfirm"
                @mousedown="OnConfirm"
            >
                <img src="img/game/avatar/next_button_arrow.svg" />
                NEXT
            </button>

			<transition name="modal-fade">
				<div v-show="confirmLoading" class="preloading-container">
					<div class="preloading-circle"></div>
				</div>
			</transition>

        </div>

        <!-- Mobile -->
        <div
            v-if="(isMobile && showGameLogin)"
            id="IDInput"
            class="pointer layout-fullpercent Landscape-only"
        >
            <div
                class="inputMask layout-fullpercent"
                :class="{ float: inputFloat }"
            ></div>
            <div class="idInput inputField" :class="{ float: inputFloat }">
                <button
                    v-show="showOKBtn"
                    class="ok-btn btn-game"
                    :disabled="!onNextBtn"
                    :class="{ float: inputFloat }"
                    @click="OnConfirm"
                >
                    Confirm
                </button>
                <input
                    ref="IDinput"
                    v-model="userName"
                    placeholder="ENTER YOUR NAME"
                    :class="{ float: inputFloat }"
                    value
                    required
                    maxlength="20"
                    @focus.prevent="onIdInputFocus"
                />
            </div>
        </div>
    </div>

</template>

<script>
import { isMobile, isIOS } from 'mobile-device-detect';
import { mapState, mapMutations } from 'vuex';
import firebase from 'firebase/app';
import UnityAvatar from './UnityAvatar.vue';
import UserAvatar from './UserAvatar.vue';

export default {
    components: {
        UnityAvatar,
        UserAvatar
    },
    async mounted() {
		if (!isMobile) {
			window.addEventListener(
				'keydown',
				e => {
					// console.log(e.code)
					if (e.code) this.onKeyInput(e.code)
				},
				false
			)
		}
    },
    watch: {
        user: {
            handler(user) {
                if (user !== null) {
                    this.onGetUserData()
                }
            },
            immediate: true
        },
        userName: {
            handler(userName) {
                if (userName !== '') {
                    this.onNextBtn = true
                } else {
                    this.onNextBtn = false
                }
            },
            immediate: true
        }
    },
    computed: {
        ...mapState('app', ['preloading']),
        ...mapState('authentication', ['user']),
        isMobile() {
            return isMobile
        },
        isIOS() {
            return isIOS
        },
        showPreview() {
            return this.vueAvatarEdited || this.userName !== '' 
        },
    },
    props: {
        showGameLogin: Boolean
    },
    data() {
        return {
            // debug
            debugShowUI: true,
            // UI State
            userName: "", // deprecate: userID
            showOKBtn: false,
            onNextBtn: false,
            inputFloat: false,

            showSelAvatar: false,
            showEditAvatar: false,

            skinData: null,
            playerAvatar: null,
            selectedAvatar: null,

            gotUserAvatar: false,

            vueAvatarEdited: false,

            confirmLoading: false,

            avatar: [
                {
                    idx: 0,
                    name: 'avatar1',
                    selected: false,
                    img: 'img/game/avatar/sunny.png'
                },
                {
                    idx: 1,
                    name: 'avatar2',
                    selected: false,
                    img: 'img/game/avatar/boy.png'
                },
                {
                    idx: 2,
                    name: 'avatar3',
                    selected: false,
                    img: 'img/game/avatar/girl.png'
                },
                {
                    idx: 3,
                    name: 'avatar4',
                    selected: false,
                    img: 'img/game/avatar/bug.png'
                },
                {
                    idx: 4,
                    name: 'avatar5',
                    selected: false,
                    img: 'img/game/avatar/grandma.png'
                }
            ],
        };
    },
    methods: {
		...mapMutations('app', ['setPreloading']),

        onIdInputValueChange() {
            if (this.userName !== "") {
                this.onNextBtn = true;
            }
        },
        onIdInputFocus() {
            // this.isChatFocus = true
            if (isIOS) {
                this.inputFloat = true;
                setTimeout(() => window.scrollTo(0, 0), 550);
            }
            if (isMobile) {
                this.showOKBtn = true;
                this.onNextBtn = false;
            }
        },
        OnConfirm() {
            this.showEditAvatar = false
            this.confirmLoading = true

            this.submitAvatarSkin()
            setTimeout(() => {
                this.submitProfilePic()
                this.sendID()
            }, 2000)

            // setTimeout(() => {
            //     this.$emit("StartGame");
            // }, 1000)
            
            // mobile
            if (isIOS || isMobile) {
                this.showOKBtn = false;
                this.onNextBtn = true;
                this.inputFloat = false;
                // this.showTutorial = true
                this.$emit("toggleTutorial", true);
            }

            // this.showGameLogin = false; // deprecate: showIdInput
        },
        OnStartGame() {
            this.$emit("StartGame");
        },
        sendID() {
            console.log("sendID", this.userName);
            this.$emit('OnChangeChatDisplayName', this.userName)
            this.updateDbDisplayName()
            this.sendUnityMessage("SetPreviewDisplayName", this.userName);
        },
        async updateDbDisplayName() {
            try {
				await firebase
					.firestore()
					.collection('users')
					.doc(this.user.id)
					.set({
						displayName: this.userName
					}, {merge: true})
				console.log('firestore updated displayName')
			} catch (err) {
				console.log('Update firestore error:', err.message)
			}

            try {
				await firebase.auth().currentUser.updateProfile({
					displayName: this.userName
				})
				console.log('UpdateProfile displayName success!!')
			} catch (err) {
				console.log('UpdateAuth error', err.message)
			}
        },
        onKeyInput(key) {
			if (this.showGameLogin) {
				// this.$refs.IDinput.focus()
				// this.onNextBtn = true
				if (key === 'Enter') {
                    // this.OnConfirm()
                    console.log("preview displayName", this.userName);
                    this.sendUnityMessage("SetPreviewDisplayName", this.userName);

					// this.sendID()
				}
			}
		},
        onGetUserData() {
            if (this.gotUserAvatar) return
            // console.log(this.gotUserAvatar)
            this.userName = this.user.displayName
            this.setAvatar(this.user.unityPrefabIdx)
            this.showSelAvatar = true
            this.gotUserAvatar = true
            // console.log(this.gotUserAvatar)
        },
        // eslint-disable-next-line
        setUnityAvatar(imgURL) {
            // full dimension
            // console.log('setUnityAvatar', imgURL)
            this.sendUnityMessage('SetPreviewPhotoData', imgURL)
        },
        setNavAvatar(imgURL) {
            // 128 dimension
            console.log('setNavAvatar', imgURL)
        },
        sendUnityMessage(functionName, param = null) {
            this.$emit('sendUnityMessage', functionName, param)
        },
        sendUserId() {
            // set unity user profile (displayName, skinIdx...)
            this.sendUnityMessage('SetUserIdAnonymously', this.user.id)
        },
        async OnSendAvatar() {
            // this.setPreloading(true)
            // this.sendUnityMessage('SetUserIdAnonymously', this.user.id)
            if (this.playerAvatar === this.user.unityPrefabIdx) {
                console.log('skip user prefab update')
                this.showAvatarEditor()
                // this.setPreloading(false)
                return
            }

            try {
                await firebase
                    .firestore()
                    .collection('users')
                    .doc(this.user.id)
                    .set({
                        unityPrefabIdx: this.playerAvatar
                    }, {merge: true}).then(() => {
                        this.showAvatarEditor()
                        // this.setPreloading(false) ..this will reset vue page especially in v-show
                        // console.log('update prefabidx and then')
                        // console.log('397avatarupdate', this.gotUserAvatar)
                        console.log('firestore udpate user avatar')
                    })
            } catch (err) {
				console.log('Update firestore error:', err.message)
            }
        },
        showAvatarEditor() {
            this.showSelAvatar = false
            this.showEditAvatar = true
            this.sendUserId()
        },
        setAvatar(idx) {
            if (this.selectedAvatar !== null) {
                this.selectedAvatar.selected = false
            }

            console.log(`setUnityPrefabIdx ${idx}`)

            // sometimes incoming idx is missing
            if (idx !== undefined || (idx >= 0 && idx <= this.avatar.length - 1)) {
                // find clicked object
                this.selectedAvatar = this.avatar.find(i => i.idx === idx)
                // console.log(this.selectedAvatar)
            } else {
                // this.selectedAvatar = this.avatar[0]
                [this.selectedAvatar] = this.avatar
            }
            this.selectedAvatar.selected = true
            this.playerAvatar = this.selectedAvatar.idx
            this.sendUnityMessage('SetPlayerPrefabIdx', this.selectedAvatar.idx.toString()) // preview
        },
        submitAvatarSkin() {
            this.sendUnityMessage('PlayerCustomize', 'submit')
        },
        submitProfilePic() {
            if (this.vueAvatarEdited) {
                this.$refs.UserAvatar.OnConfirm()
            } else {
                this.$emit("StartGame")
                console.log('skip profilepic update')
            }
        },
        OnPreview() {
            if (this.vueAvatarEdited) {
                this.$refs.UserAvatar.OnEditorPreview()
            }
            this.sendUnityMessage("SetPreviewDisplayName", this.userName);
        },
        vueAvatarShowPreview() {
            this.vueAvatarEdited = true
            console.log('vueAvatarEdited',this.vueAvatarEdited)
        }
    },
}
</script>

<style lang="scss">
.disable {
    cursor: not-allowed;
}

.editAvatar-container {
    z-index: 90;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    height: 90vh;
    left: 7vw;
    top: 50%;
    transform: translate(0px, -50%);
    // background-color: rgba(255, 255, 255, 0.5);
	// border-radius: 50px;
	// backdrop-filter: blur(10px);
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
    
    .profile-editor {
        background-color: rgb(255, 255, 255);
        border-radius: 2vw;
        backdrop-filter: blur(10px);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .uavatar-editor {
        position: relative;
        padding: auto;
        width: 100%;
        height: 100%;
        background-color: #66BAFF;
        border-radius: 2vw;
    }
}

.next-btn {
    // color: black;
    // font-family: 'Inter';
    // font-weight: bold;
    // font-size: 1vw;
    // margin: 4vh 0;
    // border: none;
    // border-radius: 2vw;
    // width: 22vw;
    // cursor: pointer;
    // transition: all 0.2s;
    position: fixed;
    font-family: "Inter";
    font-weight: 700;
    font-size: 2.4vw;
    color: black;
    text-shadow: -1px 0 #ff6900, 0 1px #ff6900, 1px 0 #ff6900, 0 -1px #ff6900;
    letter-spacing: 1vw;
    text-indent: 3.5vw;
    width: 19vw;
    height: 11vh;
    background: #ffffff;
    border: 10px solid #ee89ff;
    box-shadow: 0px 20px 30px rgb(0 0 0 / 48%);
    border-radius: 5vw;
    cursor: pointer;
    transition: all 0.2s;
    right: 5vw;
    bottom: 7vh;
    z-index: 99;

    img {
        position: absolute;
        left: 1.5vw;
        top: 50%;
        width: 4vw;
        transform: translate(0, -50%);
    }

    &:hover {
        background: #ee89ff;
    }
}

.selectAvatar-view {
    position: fixed;
    z-index: 25;

    // .next-btn {
    //     // color: black;
    //     // font-family: 'Inter';
    //     // font-weight: bold;
    //     // font-size: 1vw;
    //     // margin: 4vh 0;
    //     // border: none;
    //     // border-radius: 2vw;
    //     width: 22vw;
    //     cursor: pointer;
    //     transition: all 0.2s;
    //     right: 4vw;
    //     bottom: 3vh;

    //     &:hover {

    //     }
    // }

    .selectAvatar-container {
        position: fixed;
        left: 7vw;
        // top: 18vh;
        top: 50%;
        transform: translate(0, -50%);
        text-align: center;
        z-index: 99;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50px;
        backdrop-filter: blur(10px);
    
        p {
            color: black;
            font-family: 'Inter';
            font-weight: bold;
            font-size: 1.8vw;
            padding: 4vh 0;
            margin: 0;
            border-top-left-radius: 2vw;
            border-top-right-radius: 2vw;
            background-color: #FF7A00;
        }
    
    }
}


.avatar-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    // grid-auto-rows: 9vw;
    gap: 1vw;
    // margin: 0 1vw;
    margin: 5vh 1.5vw;
    align-items: center;
    justify-items: center;

    // button {
    //     // border-radius: 50px;
    //     border: none;
    //     // padding: 1rem 1rem;
    //     font-family: 'Inter';
    //     font-size: 1vw;
    //     font-weight: bold;
    //     width: 9vw;
    //     height: 9vw;
    //     margin: .5rem;
    //     border-radius: 2vw;
    //     color: black;
    //     cursor: pointer;

    //     &:hover {
            
    //     }
    // }

    img {
        cursor: pointer;
        width: 8.5vw;
        height: 8.5vw;
        padding: 0.5vw;
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));

        &:hover {
            background: rgba(255, 255, 255, 0.5);
        }
    }
    .disable {
        background: rgba(255, 255, 255, 0.5);
    }
}

.preview-btn {
    // border-radius: 50px;
    border: none;
    // padding: 1rem 1rem;
    font-family: 'Inter';
    font-weight: bold;
    font-size: .7vw;
    width: 9vw;
    height: 4vh;
    margin: 3vh 0;
    border: solid 0.3vw #FF6900;
    border-radius: 2vw;

    color: black;
    cursor: pointer;

    &:hover {
        background: #FF6900;
    }
}
</style>