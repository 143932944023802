<template>
	<div class="user-wrapper">
		<div class="form-space"></div>
		<!-- Loader Login.spec.js -->
		<!-- <div v-show="user === undefined" data-test="loader" class="authenticate">Authenticating...</div> -->
		<!-- Offline instruction -->
		<div v-show="!networkOnLine" class="network-warn" data-test="offline-instruction">
			Please check your connection, login feature is not available offline.
		</div>

		<p v-if="loginError">{{ loginError }}</p>

		<form id="loginForm" action="#">
			<p class="user-title text-left">Login</p>

			<div class="user-subtitle text-left">
				<span>No account? </span>
				<span class="tips" @click.prevent="redirectRoute">Create account</span>
			</div>

			<div class="form-group">
				<!-- <img class="input-icon2" src="/img/icon02.svg" /> -->
				<!-- <label>Email</label> -->
				<input
					id="loginEmail"
					v-model="loginForm.email"
					type="email"
					class="form-control"
					placeholder="Email"
					name="email"
					value
					required
				/>
			</div>

			<div class="password-group">
				<!-- <img class="input-icon1" src="/img/icon03.svg" /> -->
				<!-- <label>Password</label> -->
				<input
					id="loginPassword"
					v-model="loginForm.password"
					type="password"
					class="form-control"
					placeholder="Password"
					name="password"
					required
				/>

				<img @click.prevent="togglepw(showPw)" class="togglepw-img" :src="showPw ? '/img/user/eye_on.svg' : '/img/user/eye_off.svg'" />
			</div>


			<div class="alert" role="alert">
				<p v-if="alertMessage">{{ alertMessage }}</p>
			</div>

			<button v-if="!isPending" type="submit" :class="{'btn_notcomplete': !fillup}" class="submit-btn btn-dark btn-lg btn-block" @click.prevent="submitLogin">Login</button>
			<button v-if="isPending" class="user-btn btn-dark btn-lg btn-block" disabled>Loading...</button>

			<span class="tips text-right" @click.prevent="resetPassword()">Forget Password?</span>

			<div class="or-sign-with">
				<label class="text-center"><span>or</span></label>
			</div>
			
			<button
				v-show="networkOnLine"
				class="user-btn btn-dark btn-lg btn-block"
				@click="loginGoogle"
				>
				<img
				width="20px"
				alt='Google G Logo'
				src="@/assets/img/Google__G__Logo.svg.png"
				/>
				Continue with Google
			</button>

			<!-- <div class="stay-logged-in">
				<input type="checkbox" v-model="stayLogin" style="width:auto;"/>
				<a @click.prevent="(stayLogin=!stayLogin)">Stay logged in</a>
			</div> -->

		
			<div class="form-space"></div>
			
			<!-- <p class="text-right">-->
				<!-- <router-link :to="{ name: 'register' }">Create Account</router-link> -->
				<!--<a @click.prevent="switchMode('register')">Create Account</a>
			</p>-->
		</form>

		<!-- <a @click.prevent="testuuidv4" style="cursor:pointer;">testuuidv4</a> -->
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { isNil } from 'lodash'
import firebase from 'firebase/app'
// import { desktop as isDekstop } from 'is_js'

export default {
	data() {
		return {
			loginForm: {
				email: '',
				password: '',
			},
			error: null,
			loginError: null,
			alertMessage: '',
			btnsuccess: false,
			isPending: false,
			debug: false,
			stayLogin: false,
			showPw: false,
			userCookiesToken: ''
		}
	},
	computed: {
		...mapState('authentication', ['user']),
		...mapState('app', ['networkOnLine', 'appTitle']),

		fillup(){
			if(this.loginForm.email !== '' && 
			this.loginForm.password !== '' )
				return true

			return false
		},
	},
	watch: {
		user: {
			handler(user) {
				// console.log('watch user handler')
				if (!isNil(user)) {
					// console.log('watch user handler !isNil')
					this.$store.dispatch('modals/closeAll')
					//   const redirectUrl = isNil(this.$route.query.redirectUrl)
					//     ? '/welcome'
					//     : this.$route.query.redirectUrl
					//   console.log('redirecting to', redirectUrl)
					//   this.$router.push(redirectUrl)
				}
			},
			immediate: true,
		},
	},
	methods: {
		// ...mapMutations('authentication', ['setUser']),
		...mapMutations('app', ['setPreloading']),
		...mapActions({userLogin: 'authentication/login'}),
		
		// dont use
		// async login() {
		// 	// console.log('methods login')

		// 	this.loginError = null
		// 	const provider = new firebase.auth.GoogleAuthProvider()
		// 	this.setUser(undefined)

		// 	try {
		// 		// Firebase signin with popup is faster than redirect
		// 		// but we can't use it on mobile because it's not well supported
		// 		// when app is running as standalone on ios & android
		// 		// eslint-disable-next-line no-unused-expressions
		// 		isDekstop()
		// 			? await firebase.auth().signInWithPopup(provider)
		// 			: await firebase.auth().signInWithRedirect(provider)
		// 	} catch (err) {
		// 		this.loginError = err
		// 		this.setUser(null)
		// 	}
		// },
		submitLogin() {
			this.setPreloading(true)
			// this.isPending = true
			firebase
				.auth()
				.signInWithEmailAndPassword(
					this.loginForm.email,
					this.loginForm.password
				)
				.then((usercred) => {
					// this.btnsuccess = true
					// console.log('Signin', userCredential)
					// console.log('userphoto', userCredential.user.photoURL)
					this.$store.dispatch('modals/closeAll')
					// this.$emit('Login', userCredential.user)  // Send Login user data to Unity

					const {user} = usercred
					const userID = user.uid

					firebase
					.firestore()
					.collection('users')
					.doc(userID)
					.set({
						displayName: user.displayName.trim(),
						email: user.email.trim(),
						updateTimestamp: new Date(),
					}, {merge: true})

					setTimeout(() => (
						// this.isPending = false
						this.setPreloading(false)
					), 3000)

					// this.loginForm.email = ''
					// this.loginForm.password = ''
					// Signed in
					// const {user} = userCredential
					// this.$emit('OnUserSignIn', user, this.stayLogin)
					this.$emit('OnUserSignIn')
					this.$gtag.event('login_with_emailpw', { method: 'Google' })
					
					// this.setUser(userCredential.user)
					// ...
				})
				.catch((error) => {
					console.log(error.code)
					console.log('Signin Failed', error.message)
					this.alertMessage = error.message
					this.isPending = false
					this.setPreloading(false)
					// var errorCode = error.code
					// var errorMessage = error.message
				})
		},
		async loginGoogle() {
			try {
				this.setPreloading(true)
				// this.isPending = true
				const provider = new firebase.auth.GoogleAuthProvider()
				await firebase
					.auth()
					.signInWithPopup(provider)
					.then(() => {
						// this.btnsuccess = true
						this.$store.dispatch('modals/closeAll')
						setTimeout(() => {
							const user = firebase.auth().currentUser
							const userID = user.uid
							
							user.updateProfile({
								displayName: this.user.displayName
							}).then(() => {
								console.log('UpdateProfile displayName success!!')
							}).catch(err => {
								console.log('UpdateAuth error', err.message)
							})

							firebase
							.firestore()
							.collection('users')
							.doc(userID)
							.set({
								displayName: user.displayName.trim(),
								email: user.email.trim(),
								updateTimestamp: new Date(),
							}, {merge: true})

							// this.isPending = false
							this.setPreloading(false)
						}, 3000)
						this.$emit('OnUserSignIn')
						this.$gtag.event('login_with_google', { method: 'Google' })
						// this.$emit('OnUserSignIn', user)
					})
					.catch (e => {
						this.isPending = false
						this.alertMessage = e.message
						this.setPreloading(false)
						console.log('Google Auth', e)
					})
			} catch (e) {
				this.isPending = false
				this.alertMessage = e.message
				this.setPreloading(false)
				console.log('login Google', e)
			}
		},
		resetPassword() {
			// todo: send reset password email
			this.$store.dispatch('modals/open', {
				name: 'ModalUser',
				userMode: 'forgetpw',
			})

			// console.log("Send reset password email")
		},
		redirectRoute() {
			this.$store.dispatch('modals/open', {
				name: 'ModalUser',
				userMode: 'register',
			})
		},
		togglepw() {
			const pwInput = document.getElementById('loginPassword')
			this.showPw = !this.showPw
			if (this.showPw) {
				pwInput.setAttribute('type', 'text')
			} else {
				pwInput.setAttribute('type', 'password')
			}
		}
	},
}
</script>

<style lang="scss">

</style>