<template>
	<header class="navbar" :class="{ offline: !networkOnLine }">
		<router-link to="/">
			<!-- <span class="site-name title-desktop">AFTERLIFE</span> -->
			<img alt="logo-bento" class="logo" src="@/assets/sunsettown_logo.svg" />
			<!-- <span class="site-name title-desktop">{{ appTitle }}</span>
      <span class="site-name title-mobile">{{ appShortTitle }}</span> -->
		</router-link>

		<div class="links" v-show="!GameEnd">
			<nav class="nav-links">
				<div v-if="!isUserLoggedIn && networkOnLine" class="nav-item">
					<button id="login-btn" @click.prevent="openModalUser('login')" :class="`${ userMode === 'login' ? 'btn-active' : ''}`">Login</button>
					<button id="register-btn" @click.prevent="openModalUser('register')" :class="`${ userMode === 'register' ? 'btn-active' : ''}`">Sign Up</button>
					<!-- <a @click.prevent="openModalUser('login')">Login</a> /
					<a @click.prevent="openModalUser('register')">Register</a> -->
					<!-- <router-link to="/login">Login</router-link> / 
					<router-link to="/register">Register</router-link> -->
				</div>
				<!-- <div v-if="!isUserLoggedIn && networkOnLine" class="nav-item">
					<router-link to="/login">Login</router-link> / 
					<router-link to="/register">Register</router-link>
				</div> -->
				<div
					v-if="isUserLoggedIn && networkOnLine"
					class="nav-item logout-item"
				>
					<!-- <a>Logout</a> -->
					<button 
						id="logout-btn"
						@click.prevent="logout"
					>
						Logout
					</button>
				</div>
				

				<div v-if="!networkOnLine" class="nav-item offline-label">Offline</div>
			</nav>

			<!--
				TODO-fix: Navbar user img
			-->
			<!-- <router-link to="/profile" v-if="isUserLoggedIn && networkOnLine">
				<img
					v-if="isUserLoggedIn && networkOnLine"
					class="user-picture can-hide"
					:src="updateimg"
					alt="Loading.."
				/>
			</router-link> -->
		</div>
	</header>
</template>

<script>
// import firebase from 'firebase/app'
import { mapGetters, mapState } from 'vuex'

export default {
	props: ['Navimg', 'landingUserMode', 'GameEnd'],
	data() {
		return {
			profileimg: ''
		}
  	},
	computed: {
		...mapGetters('authentication', ['isUserLoggedIn']),
		...mapState('authentication', ['user']),
		...mapState('modals', ['userMode']),
		...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle']),
		// updateimg(){
		// 	if(this.Navimg.length > 1)
		// 	{
		// 		// console.log("UpdateNavImg",this.Navimg)
		// 		this.profileimg = this.Navimg
		// 	}
		// 	return this.profileimg
		// },
		
	},
	watch: {
		user: {
			handler(user) {
				console.log('NavUser', user)
				// this.profileimg = user.thumbnail_128
			},
		},
		/*
		profileimg: {
			handler(profileimg) {
				console.log('profileimg', profileimg)
			},
			immediate: true,
		},
		*/
  	},
	methods: {
		async logout() {
			this.$emit('Logout') // delete app navimg, main: run onusersignout
			this.profileimg = ''
		},
		openModalUser(val) {
			this.$store.dispatch('modals/open', {
				name: 'ModalUser',
				userMode: val,
			})
			// this.toggleButton(val)
		}
		// toggleButton(val) {
		// 	const loginbtn = document.getElementById('login-btn')
		// 	const registerbtn = document.getElementById('register-btn')
		// 	if (val === 'login') {
		// 		loginbtn.classList.add('btn-active')
		// 		registerbtn.classList.remove('btn-active')
		// 	} else if (val === 'register') {
		// 		registerbtn.classList.add('btn-active')
		// 		loginbtn.classList.remove('btn-active')
		// 	}
		// }
	},
	mounted() {
		// console.log('NavBar', this.user)
		// console.log('NavBar', this.userMode)
		// this.openModalUser(this.landingUserMode)
	},
}
</script>

<style lang="scss" scoped>
@import '@/theme/vars.scss';

.navbar {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 20;
	right: 0;
	height: $navbar-height;
	background-color: $navbar-color;
	box-sizing: border-box;
	// border-bottom: 1px solid #eaecef;
	padding: 2rem 2rem;
	line-height: 2.2rem;

	a {
		display: flex;
		align-items: center;
		width: fit-content;
	}

	.title-desktop {
		display: inline;
	}

	.title-mobile {
		display: none;
	}

	@media (max-width: 500px) {
		padding: 0.7rem 0.7rem;

		.can-hide {
			display: none;
		}

		.title-desktop {
			display: none;
		}

		.title-mobile {
			display: block;
		}
	}

	.site-name {
		font-size: 1.3rem;
		font-weight: 600;
		color: #fff;
		position: relative;
	}

	.logo {
		// height: 24px;
		// padding-right: 8px;
		// height: 8vw;
		// padding: 2rem 0 0 2rem;
		width: 20vw;
		// width: 20vw;
	}

	.links {
		padding-left: 1.5rem;
		box-sizing: border-box;
		white-space: nowrap;
		font-size: 0.9rem;
		position: absolute;
		right: 1.5rem;
		top: 0.7rem;
		display: flex;

		.nav-links {
			display: flex;
			align-items: center;
			justify-content: center;

			.nav-item {
				position: relative;
				display: inline-block;
				// margin-left: 1.5rem;
				line-height: 2.2rem;

				&:first-child {
					margin-left: 0;
				}

				button {
					font-family: 'Inter';
					font-weight: bold;
					border: none;
					border-radius: 8px;
					padding: 0.7rem 1.7rem;
					// padding: 0.7vw 1.6vw;
					margin: 1rem .5rem;
					color: black;
					background: #FF6900;
					filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.25));
					cursor: pointer;

					transition: all 0.1s;
					
					&:hover {
						background: white;
					}
				}

				a {
					font-weight: 500;
					font-size: 0.9rem;
					text-decoration: none;
					// color: #999;
					color: white;
					border-color: $color-support;
					// border-radius: 1rem;
					// background: #ff6900;
					line-height: 1.4rem;
					display: inline-block;
					cursor: pointer;
					&:hover {
						color: #fff;
					}
				}

				@mixin activatedLink() {
					// margin-bottom: -2px;
					// border-bottom: 2px solid $color-support;
				}

				// .router-link-active {
				// 	@include activatedLink;
				// }

				@media (hover) {
					:hover {
						@include activatedLink;
					}
				}
			}
		}
	}

	.btn-active {
		background: white !important;
	}

	&.offline {
		background: $navbar-offline-color;
		.links .nav-links .nav-item a,
		.site-name {
			color: white;
		}
	}

	.user-picture {
		max-height: 32px;
		margin-left: 1.5rem;
		border-radius: 50%;
	}

	.offline-label {
		padding: 0px 10px;
		border: 1px solid white;
		border-radius: 5px;
		color: white;
		margin-left: 1.5rem;
	}
}
</style>
