var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu-sub-container-bi timetable"},[_c('div',{staticClass:"menu-sub-container-bi__left"},[_c('div',{staticClass:"flex sub__nav"},_vm._l((_vm.days),function(item){return _c('div',{key:item.id,class:{ sub__nav__selected: _vm.selectDayDummy === item.id },on:{"click":function($event){$event.preventDefault();return _vm.selectDay(item.id)}}},[_vm._v(" "+_vm._s(item.display)+" ")])}),0),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"flex-column",staticStyle:{"width":"100%","position":"relative"}},[_c('div',{staticClass:"stage-container"},[_c('p',{staticClass:"__head"},[_vm._v("GMT+8")]),_vm._l((_vm.stage),function(item){return _c('div',{key:item,staticClass:"stage"},[_vm._v(" "+_vm._s(item)+" "),(item !== 'Cinema')?_c('span',[_vm._v("Stage")]):_vm._e()])})],2),_c('div',{staticClass:"time-container"},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","gap":"0.5vh"}},_vm._l((_vm.timelog),function(item){return _c('div',{key:item,staticClass:"timeline-timestamp"},[_c('p',{staticClass:"__gap"},[_vm._v(" "+_vm._s(String(item).slice(0, 2))+":"+_vm._s(String(item).slice(2, 4))+" ")])])}),0),_vm._l((_vm.stage),function(i){return _c('div',{key:i,staticClass:"timeline"},[_vm._l((_vm.fillMissingData),function(item){return [(item.day === _vm.selectDayDummy && item.stage === i)?_c('div',{key:item.day + item.time + i},[(!item.showId)?_c('div',{staticClass:"timeline__grid",class:item.time === '1915' ? 'timeline__grid__short' : '',staticStyle:{"background":"transparent"}}):_c('span',[_vm._l((_vm.video),function(show){return [(item.showId === show.showId)?_c('div',{key:show.showId,staticClass:"timeline__grid",class:[
												_vm.selectedShow.showId == item.showId
													? 'timeline__grid__active'
													: '',
												show.duration === 60 ? 'timeline__grid__long' : '',
												show.duration === 15 ? 'timeline__grid__short' : '',
												(show.showId === 'Cn_Must2_Intro')
												| (show.showId === 'Cn_Must1_Interview')
													? 'timeline__grid__short'
													: '',
												(show.showId === 'Cn_STR2_Chihuahua')
												| (show.showId === 'Cn_Must3_StreetArt')
												| (show.showId === 'Cn_STR5_Ignore')
												| (show.showId === 'Cn_STR5')
													? 'timeline__grid__long'
													: ''
											],staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.preventDefault();return _vm.selectShow(show, item)}}},[_c('div',{staticClass:"showName",domProps:{"innerHTML":_vm._s(show.title.ch)}})]):_vm._e()]})],2)]):_vm._e()]})],2)}),(_vm.liveTime)?_c('div',{staticClass:"timeline-pointer"},[_vm._v(_vm._s(_vm.timestamp))]):_vm._e()],2)])])]),_c('div',{staticClass:"menu-sub-container-bi__right showInfo"},[_c('div',[(_vm.selectedShow)?_c('h2',{staticClass:"showInfo__title",style:({ 'border-bottom-color': _vm.selectedColor })},[_c('div',{staticClass:"infotitle",domProps:{"innerHTML":_vm._s(_vm.selectedShow.title.ch)}})]):_vm._e(),(_vm.selectedShow)?_c('div',{staticClass:"infobox"},[_c('img',{attrs:{"width":"363","src":_vm.selectedShow.imgUrl,"alt":""}}),_c('div',{staticStyle:{"display":"flex","justify-content":"start"}},[_c('span',{staticClass:"stagetag",style:({ 'background-color': _vm.selectedColor })},[_vm._v(_vm._s(_vm.selectedStage.stage)+" Stage")]),_c('span',{staticClass:"content",staticStyle:{"margin":".3vh 0"}},[_vm._v(_vm._s(String(_vm.selectedStage.time).slice(0, 2))+":"+_vm._s(String(_vm.selectedStage.time).slice(2, 4)))])]),_c('p',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.selectedShow.description.ch)+" ")]),_c('p',{staticClass:"content"},[_vm._v(" "+_vm._s(_vm.selectedShow.description.en)+" ")])]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }