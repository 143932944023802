<template>
    <div class="menu-sub-container-bi zone noborder">
        <div
            style="width: 20vw; height: 100%; position: relative;"
        >
            <div class="current-zone-handle">
                <p class="title">You are in</p>
                <div style="display: flex; flex-direction: row; justify-content: space-between;">
                    <p class="zone-title">{{ getCurrentZone ? `Zone${getCurrentZoneIndex(getCurrentZone)}` : "Offline" }}</p>
                    <p class="zone-subtitle" style="margin: auto 0 1vh 0">Player Count: {{ getCurrentPlayerCount }}</p>
                </div>
                <div
                    v-if="(showZoneReport && playfabZoneReport)"
                >
                    <p class="zone-subtitle">fqdn: {{playfabZoneReport ? playfabZoneReport.fqdn : "" }}</p>
                    <p class="zone-subtitle">ipv4Address: {{playfabZoneReport ? playfabZoneReport.ipv4Address : ""}}</p>
                    <p class="zone-subtitle">maxConnections: {{playfabZoneReport ? playfabZoneReport.maxConnections : ""}}</p>
                    <p class="zone-subtitle">portGeneral: {{playfabZoneReport ? playfabZoneReport.portGeneral : ""}}</p>
                    <p class="zone-subtitle">portWebgl: {{playfabZoneReport ? playfabZoneReport.portWebgl : ""}}</p>
                    <p class="zone-subtitle">vmServerId: {{playfabZoneReport ? playfabZoneReport.vmServerId : ""}}</p>
                    <p class="zone-subtitle">vmSessionId: {{playfabZoneReport ? playfabZoneReport.vmSessionId : ""}}</p>
                </div>
            </div>
            <hr style="width: 99%; border: 2px solid #66BAFF" >
            <p style="text-align: left;" class="title">Room List</p>
            <div class="zone-list flex-column">
                <div
                    style="width: 95%; height: 70vh;">
                    <div 
                        class="zone-item flex-row"
                        v-for="item in this.orderZone(zoneList)" 
                        :key="item.zone"
                    >
                        <p>{{capitalizeFirstLetter(item.zone)}}</p>
                        <!-- <p v-if="(getCurrentZone===item.zone)">Current</p>
                        <p v-else-if="getZoneServerActive(item)">Full</p> -->
                        <button
                            v-if="(getCurrentZone===item.zone)"
                            class="current-button"
                            >
                            Current
                        </button>
                        <button
                            v-else-if="getZoneServerActive(item)"
                            class="full-button"
                        >
                            Full
                        </button>
                        <button
                            v-else
                            @click.prevent="SetZoneServer(item.zone)"
                            class="connect-button"
                        >
                            Connect
                        </button>
                        <!-- <hr style="width: 100%" class="soild 1px" color="#666666"> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import firebase from 'firebase/app'
import PlayFab from 'playfab-sdk'
import _ from 'lodash'

export default {
    watch: {
        zoneServerReport: {
            handler(zoneServerReport) {
                this.getZoneServerFromPlayFab(zoneServerReport)
                // console.log('OnConnectedZoneServer', zoneServerReport)
            }
        }
    },
    props: {
        projectPath: {
            type: String,
            default: null,
        },
        zoneServerReport: {
            type: Object,
            default: null
        },
        isDemo: {
            type: Boolean,
            default: false
        },
		showZoneReport: {
			type: Boolean,
			default: null,
		}
    },
    computed: {

        getCurrentZone() {
            if (this.zoneServerReport?.targetZone !== null) {
                if (this.zoneServerReport?.targetZone !== undefined)
                    return this.zoneServerReport.targetZone
                    // return this.getCurrentZoneIndex(this.zoneServerReport.targetZone)
            }
            return null
        },
        getCurrentZoneData() {
            const currentZone = this.zoneList.find(item => item.zone === this.getCurrentZone)
            if (currentZone) {
                return currentZone
            }
            return null
        },
        getCurrentPlayerCount() {
            if (this.getCurrentZoneData) {
                return this.getCurrentZoneData.playerCount
            }
            return 'none'
        }
    },
    data() {
        return {
            // currentZoneReport: "null",

            zoneList: [],
            gotZoneData: false,

            firebaseProjName: 'srvc-sunsetmusic',
			firebaseAPIId: 'AIzaSyAwgJk2UfbLzIKVWt-NHUt6C26hmEjR_cA',
			playfabTitleId: '88C3C',

            playfabZoneReport: null,

            debugSnapshot: null,
            debugZoneList: [
                {
                    name: 'Zone1',
                    zoneId: 'zone1'
                },
                {
                    name: 'Zone2',
                    zoneId: 'zone2'
                },
                {
                    name: 'Zone3',
                    zoneId: 'zone3'
                },
                {
                    name: 'Zone4',
                    zoneId: 'zone4'
                },
                {
                    name: 'Zone5',
                    zoneId: 'zone5'
                },
            ]
        }
    },
    methods: {
        getZoneServerList() {
            this.debugSnapshot = firebase
            .firestore()
            .collection('_projects')
            .doc(this.projectPath)
            .collection('serverList')
            .onSnapshot( snapshot => {
                    this.zoneList.length = 0
                    snapshot.forEach((doc) => {
                        if (doc.data().zone === '-1') return
                        this.zoneList.push(doc.data())
                    })
                    this.gotZoneData = true
                    // this.debugSnapshot()
                },
                err => {
                    console.log('Unable to fetch the serverList document:', err.message)
                }
            )
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        orderZone(zoneList) {
            // const roomList = Array.from(zoneList)
            const roomList = JSON.parse(JSON.stringify(zoneList));
            roomList.forEach(item => {
                // item.zone = item.zone.replace('zone', 'room')
                item.id = parseInt(item.zone.slice(4), 10)
                // console.log(item.zone, item.id)
            })

            return _.orderBy(roomList, 'id', 'asc'); 
        },
        getZoneServerActive(zoneServer) {
            if (!zoneServer) return false
            if (zoneServer.maxConnections > zoneServer.playerCount) {
                return false
            } 
            return true
        },
        getCurrentZoneIndex(zone) {
            // const zoneIndex = this.zoneList.findIndex(item => item.zone === zone)
            const zoneIndex = zone.replace('zone', '')
            return zoneIndex
        },
        SetZoneServer(zone) {
            const zoneIndex = this.orderZone(this.zoneList).findIndex(item => item.zone === zone)
            // console.log(zoneIndex)
            this.$emit('sendUnityMessage', 'SetZoneServer', zoneIndex.toString())
			this.$emit('toggleNav')
            this.$gtag.event(`SetZoneServer_${zone}`, { method: 'Google' })
        },
        getZoneServerFromPlayFab(zoneReport) {
            // zoneReport param
            // targetZone
            // playerCount
            // vmServerId
            // vmSessionId

            const request = {
                FunctionName: 'getZoneServer',
				FunctionParameter: { 
					zone: zoneReport.targetZone,
                    projectName: this.projectPath,
                    firebaseProjName: this.firebaseProjName,
                    firebaseAPIID: this.firebaseAPIId
				},
				GeneratePlayStreamEvent: true
            }

            let callbackResult;

            const playFabCallback = (error, result) => {
                if (result) {
                    callbackResult = result.data.FunctionResult
                    this.playfabZoneReport = callbackResult
                }
            }

            PlayFab.PlayFabClient.ExecuteCloudScript(request, playFabCallback)
        },
    },
    mounted() {
        this.getZoneServerList()
    },
}
</script>
<style lang="scss">
.current-zone-handle {
    text-align: left;

    // p {
    //     margin: 1vh;
    //     color: black !important;
    // }
}

.zone-list {
    width: 100%;
    height: 70%;
    padding: 0 0.5vw 0 0;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #FF7A00 transparent;
    margin: auto;
    align-items: center;
    
    &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #FF7A00;
        border-radius: 0px;
        border: 3px solid transparent;
    }
}

.zone-item {
    margin: .5vh 0;
    padding: 1vh 0;
    border-bottom: solid 1px #dddddd;
    justify-content: space-between;

    .connect-button {
        color: black;
        border: solid .2vw #FF7A00;
        border-radius: 2vw;
        padding: 0.1vh 1.5vw;
        font-size: .7vw;
        height: 4vh;
        width: 7vw;
        margin: auto 0 auto 0;
        cursor: pointer;
        transition: all 0.2s;
        font-weight: bold;
        // width: 5vw;
        // background: white;

        &:hover {
            background: #FF7A00;
        }


    }

    .full-button {
        color: black;
        border: solid .2vw #dddddd;
        border-radius: 2vw;
        padding: 0.1vh 1.5vw;
        font-size: .7vw;
        height: 4vh;
        width: 7vw;
        margin: auto 0 auto 0;
        cursor: pointer;
        transition: all 0.2s;
        font-weight: bold;
        // width: 5vw;
        // background: white;

        &:hover {
            background: #dddddd;
        }
    }

    .current-button {
        color: black;
        border: solid .2vw #FF7A00;
        background: #FF7A00;
        border-radius: 2vw;
        padding: 0.1vh 1.5vw;
        font-size: .7vw;
        height: 4vh;
        width: 7vw;
        margin: auto 0 auto 0;
        cursor: pointer;
        transition: all 0.2s;
        font-weight: bold;
        // width: 5vw;
        // background: white;

        &:hover {
            background: #FF7A00;
        }
    }

    p {
        margin: 1vh 0 1vh 0;
        font-size: .8vw;
        font-weight: bold;
        color: black !important;
    }
}

.disable {
    background: gray;
    color: white !important;
    cursor: not-allowed !important;
}

.title {
    font-size: 1.2vw;
    font-weight: bold;
    margin: 3vh 0 1vh 0;
    color: black !important;
}

.zone-title {
    font-size: 2vw;
    // font-weight: bold;
    margin: 1vh 0 1vh 0;
    color: black !important;
}

.zone-subtitle {
    font-size: 1vw;
    // font-weight: bold;
    margin: 1vh;
    color: #FF6900;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.noborder {
    border: none;
}

.zone {
    grid-template-columns: none !important;
}

// p {
//     margin: 1vh;
//     color: black !important;
// }

</style>