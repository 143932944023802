<template>
	<div class="menu-sub-container-bi timetable">
		<div class="menu-sub-container-bi__left">
			<div class="flex sub__nav">
				<div
					v-for="item in days"
					:key="item.id"
					:class="{ sub__nav__selected: selectDayDummy === item.id }"
					@click.prevent="selectDay(item.id)"
				>
					{{ item.display }}
				</div>
			</div>
			<div style="display: flex;">
				<!-- <div class="timeline-timestamp">
					<p class="__head">GMT+8</p>
					<div class="__gap">
						<p v-for="item in timelog" :key="item">
							0{{ String(item).slice(0, 1) }}:{{ String(item).slice(1, 3) }}
							PM
						</p>
					</div>
				</div> -->
				<div class="flex-column" style="width: 100%; position: relative;">
					<div class="stage-container">
						<p class="__head">GMT+8</p>
						<div v-for="item in stage" :key="item" class="stage">
							{{ item }}
							<span v-if="item !== 'Cinema'">Stage</span>
						</div>
					</div>
					<div class="time-container">
						<div style="display: flex; flex-direction: column; gap: 0.5vh;">
							<div v-for="item in timelog" :key="item" class="timeline-timestamp">
								<p class="__gap">
									{{ String(item).slice(0, 2) }}:{{ String(item).slice(2, 4) }}
									<!-- PM -->
								</p>
							</div>
						</div>
						<div v-for="i in stage" :key="i" class="timeline">
							<template v-for="item in fillMissingData">
								<div
									v-if="item.day === selectDayDummy && item.stage === i"
									:key="item.day + item.time + i"
								>
									<div
										v-if="!item.showId"
										class="timeline__grid"
										:class="item.time === '1915' ? 'timeline__grid__short' : ''"
										style="background: transparent;"
									></div>
									<span v-else>
										<template v-for="show in video">
											<div
												v-if="item.showId === show.showId"
												:key="show.showId"
												class="timeline__grid"
												:class="[
													selectedShow.showId == item.showId
														? 'timeline__grid__active'
														: '',
													show.duration === 60 ? 'timeline__grid__long' : '',
													show.duration === 15 ? 'timeline__grid__short' : '',
													(show.showId === 'Cn_Must2_Intro')
													| (show.showId === 'Cn_Must1_Interview')
														? 'timeline__grid__short'
														: '',
													(show.showId === 'Cn_STR2_Chihuahua')
													| (show.showId === 'Cn_Must3_StreetArt')
													| (show.showId === 'Cn_STR5_Ignore')
													| (show.showId === 'Cn_STR5')
														? 'timeline__grid__long'
														: ''
												]"
												style="cursor: pointer;"
												@click.prevent="selectShow(show, item)"
											>
												<!-- <p>{{ show.title.ch }}</p> -->		
												<div class="showName" v-html="show.title.ch"></div>
												<!-- <span
													class="livetag"
													:class="{
														hidden: onLive(item.time)
													}"
												>
													<span class="livetag__circle"></span>
													<span class="livetag__text">LIVE now</span>
												</span> -->
											</div>
										</template>
									</span>
								</div>
							</template>
						</div>
						<div v-if="liveTime" class="timeline-pointer">{{ timestamp }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="menu-sub-container-bi__right showInfo">
			<div>
				<h2
					v-if="selectedShow"
					class="showInfo__title"
					:style="{ 'border-bottom-color': selectedColor }"
				>
					<!-- {{ selectedShow.title.ch }} -->
					<div class="infotitle" v-html="selectedShow.title.ch"></div>
				</h2>
				<div v-if="selectedShow" class="infobox">
					<img width="363" :src="selectedShow.imgUrl" alt="" />
					<!-- <img width="363" src="/img/placeholder.png" alt="" /> -->
					<div style="display: flex; justify-content: start;">
						<span class="stagetag" :style="{ 'background-color': selectedColor }"
							>{{ selectedStage.stage }} Stage</span
						>
						<span class="content" style="margin: .3vh 0;">{{ String(selectedStage.time).slice(0, 2) }}:{{ String(selectedStage.time).slice(2, 4) }}</span>
					</div>
					<p class="content">
						{{ selectedShow.description.ch }}
					</p>
					<p class="content">
						{{ selectedShow.description.en }}
					</p>
					<!-- <div class="content" v-html="selectedShow.description.ch"></div>
					<div class="content" v-html="selectedShow.description.en"></div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	props: {
		selectDayDummy: {
			type: String,
			default: 'day1'
		}

	},
	data() {
		return {
			// debug
			debugShowUI: true,
			timestamp: '',
			days: [
				{
					id: 'day1',
					display: 'DAY1'
				},
				{
					id: 'day2',
					display: 'DAY2'
				},
				{
					id: 'day3',
					display: 'DAY3'
				},
				{
					id: 'day4',
					display: 'DAY4'
				}
			],
			stage: ['Street', 'Ocean', 'DJ', 'Cinema'],
			// selectedDay: 'day1',
			selectedShow: '',
			selectedStage: '',
			selectedColor: 'var(--orange)',
		}
	},
	computed: {
		...mapState('products', ['timetable', 'video', 'day']),
		times() {
			let times = this.timetable.map(item => item.time)
			times = Array.from(new Set(times)).sort((a, b) => a - b)
			times.splice(1, 1)
			return times
		},
		timelog() {
			// let times = this.timetable.map(item => parseInt(item.time, 10) - 1200)
			let times = this.timetable.map(item => parseInt(item.time, 10))
			times = Array.from(new Set(times)).sort((a, b) => a - b)
			times.splice(1, 1)
			return times
		},
		endTime() {
			return this.timelog[0] + 200
		},
		liveTime() {
			// just define the start & end hour
			const showStart = new Date('December 31, 1975, 19:00:00 GMT+8:00');
			const showEnd = new Date('December 31, 1975, 21:00:00 GMT+8:00');
			// const showStart = new Date('December 31, 1975, 23:00:00 GMT+8:00');
			// const showEnd = new Date('December 31, 1975, 1:00:00 GMT+8:00');

			const startUTCHour = showStart.getUTCHours()
			const endUTCHour = showEnd.getUTCHours()

			const now = new Date()
			// const hour = now.getHours()
			const nowUTCHour = now.getUTCHours()
			const result = nowUTCHour >= startUTCHour && nowUTCHour < endUTCHour
			// const result = hour >= 19 && hour < 21
			console.log(nowUTCHour, result)
			return result
		},
		fillMissingData() {
			const data = this.day.reduce((acc, day) => {
				this.times.forEach(time => {
					this.stage.forEach(stage => {
						if (
							!this.timetable.find(
								t => t.day === day && t.time === time && t.stage === stage
							)
						) {
							acc.push({ day, time, stage, display: 'none' })
						}
					})
				})
				return acc
			}, [])
			const sortdata = this.timetable.concat(data).sort((a, b) => {
				return a.time - b.time
			})
			return sortdata.filter(
				e => e.day !== 'day3' || e.time !== '2000' || e.stage !== 'Cinema'
			)
		}
	},
	created() {
		if (!this.timetable) {
			this.getDate()
		}
	},
	mounted() {
		this.getNow()
		setInterval(this.getNow, 1000)
		this.pointerMove()
	},
	methods: {
		getNow() {
			// Depending on timezone, your results will vary
			// so diff timezone player will see their time in timepointer
			const now = new Date()
			// const utc = now.getUTCDate
			const time = now.toLocaleTimeString('en-US', {
				hour: '2-digit',
				minute: '2-digit',
				hour12: true
			})
			// const dateTime = `${time} PM`
			this.timestamp = time
		},
		selectDay(value) {
			// this.selectedDay = value
			this.$emit("onSelectDay", value)
		},
		selectShow(show, stage) {
			this.selectedShow = show
			this.selectedStage = stage
			if (this.selectedStage.stage === 'Street') {
				this.selectedColor = 'var(--orange)'
			}
			if (this.selectedStage.stage === 'Ocean') {
				this.selectedColor = 'var(--blue)'
			}
			if (this.selectedStage.stage === 'DJ') {
				this.selectedColor = 'var(--purple)'
			}
			if (this.selectedStage.stage === 'Cinema') {
				this.selectedColor = 'var(--yellow)'
			}
		},
		pointerMove() {
			if (this.liveTime) {
				const pointer = document.querySelector('.timeline-pointer')

				const showStart = new Date('December 31, 1975, 19:00:00 GMT+8:00');
				// const showStart = new Date('December 31, 1975, 23:00:00 GMT+8:00');

				const now = new Date()
				// const hour = now.getHours() - 19
				// const minute = now.getMinutes()
				const UTCHour = now.getUTCHours() - showStart.getUTCHours()
				const UTCMinute = now.getUTCMinutes()
				// pointer.style.top = `${(hour * 60 + minute) * 0.59}vh`
				pointer.style.top = `calc(${(UTCHour * 60 + UTCMinute)} * (14vh * 4 + 4vh * 4 + 2vh) / 120 - 11px)` // nowTime * fulltimeline height / 2hours - pointer padding
				let minutesPassed = 0
				setInterval(() => {
					minutesPassed += 1
					if (minutesPassed <= 120) {
						// pointer.style.top = `${(hour * 60 + minute + minutesPassed) * 0.59}vh`
						pointer.style.top = `calc(${(UTCHour * 60 + UTCMinute + minutesPassed)} * (14vh * 4 + 4vh * 4 + 2vh) / 120 - 11px)`
					} else {
						clearInterval()
					}
				}, 60000)
			}
		},
		// tofix: timezone issue, if data was GMT+8, must calc the time with that timezone
		onLive(value) {
			const now = new Date()
			const time = now.toLocaleTimeString('en-US', {
				hour: '2-digit',
				minute: '2-digit',
				hour12: false
			})
			const result = parseInt(time.slice(0, 2) + time.slice(3, 5), 10)
			if (parseInt(value, 10) <= result && result < parseInt(value, 10) + 29) {
				return false
			}
			return true
		}
	}
}
</script>
<style lang="scss">
:root {
	--orange: #ff6900;
	--blue: #66baff;
	--purple: #d15ee1;
	--yellow: #fbcb3b;
}
.time-container {
	position: relative;
	display: grid;
	// margin-top: 12px;
	margin-top: 1vh;
	// padding: 0 50px 0 120px;
	padding: 0vh 2vw 0 0;
	gap: 0.5vh;
	grid-template-columns: .3fr 1fr 1fr 1fr 1fr;
	.timeline {
		height: 400px;
		display: flex;
		flex-direction: column;
		gap: 0.5vh;
		overflow-y: hidden;
    	// height: fit-content;
		// height: 75vh;
		// height: calc(14vh * 4 + 2vw * 4 + 2vh);
		height: calc(14vh * 4 + 4vh * 4 + 2vh);
		&:nth-child(2) {
			.timeline__grid {
				background: var(--orange);
			}
		}
		&:nth-child(3) {
			.timeline__grid {
				background: var(--blue);
			}
		}
		&:nth-child(4) {
			.timeline__grid {
				background: var(--purple);
			}
		}
		&:nth-child(5) {
			.timeline__grid {
				background: var(--yellow);
			}
		}
		&__grid {
			// padding: 16px 20px;
			// padding: 1vw 1vw;
			padding: 2vh;
			// border-radius: 30px;
			border-radius: 2vw;
			height: 14vh;
			// @media screen and (max-width: 1680px) {
			// 	height: 12vh;
			// }
			// @media screen and (max-width: 1450px) {
			// 	height: 12vh;
			// }
			// @media screen and (max-width: 1280px) {
			// 	padding: 8px 20px 8px 20px;
			// 	height: 13vh;
			// }
			&:hover {
				color: #000 !important;
				filter: brightness(1.1);
			}
			p {
				// margin: 0 0 8px 0;
				margin: 1vw;
				text-align: left;
				color: black;
				// font-size: 16px;
				font-weight: 600;
				font-size: 1vw;
				// @media screen and (max-width: 1680px) {
				// }
				// @media screen and (max-width: 1366px) {
				// }
				// @media screen and (max-width: 1280px) {
				// 	margin: 0 0 0 0;
				// 	font-size: 10px;
				// }
			}
			.showName {
				position: relative;
				// padding: .5vw;
				padding: 2vh;
				text-align: left;
				color: black;
				// font-size: 16px;
				font-weight: bold;
				font-family: 'Inter';
				font-size: 1vw;
			}
			&__active {
				color: #000 !important;
				filter: brightness(1.1);
			}
			/* 1915 */
			&__short {
				height: calc(5vh - 0.25vh);
				display: flex;
				align-items: center;
				p {
					font-size: 13px;
					margin: 0;
				}
				// @media screen and (max-width: 1680px) {
				// 	padding: 2px 20px 4px 20px;
				// 	height: 6.75vh;
				// }
				// @media screen and (max-width: 1366px) {
				// }
				// @media screen and (max-width: 1280px) {
				// 	padding: 2px 20px 4px 20px;
				// 	height: 6.25vh;
				// }
			}
			&__long {
				height: calc(32vh + 0.5vh);
				// @media screen and (max-width: 1680px) {
				// 	height: 29vh;
				// }
			}
			.livetag {
				display: flex;
				align-items: center;
				background: white;
				width: fit-content;
				height: fit-content;
				padding: 3.5px 5px;
				margin-right: 7px;
				border-radius: 60px;
				gap: 3px;
				// @media screen and (max-width: 1680px) {
				// }
				// @media screen and (max-width: 1366px) {
				// }
				// @media screen and (max-width: 1280px) {
				// 	padding: 1px 2px;
				// 	display: none;
				}
				&__circle {
					background: #e20000;
					border-radius: 100%;
					width: 13px;
					height: 13px;
					// @media screen and (max-width: 1680px) {
					// }
					// @media screen and (max-width: 1366px) {
					// }
					// @media screen and (max-width: 1280px) {
					// 	height: 8px;
					// 	width: 8px;
					}
				}
				&__text {
					font-family: 'Arial';
					font-weight: 700;
					font-size: 12px;
					color: #000000;
					// @media screen and (max-width: 1680px) {
					// }
					// @media screen and (max-width: 1366px) {
					// }
					// @media screen and (max-width: 1280px) {
					// 	font-size: 8px;
					// }
				}
			}
			.hidden {
				display: none;
			}
		}
	
	.timeline-timestamp{
		display: flex;
		flex-direction: column;
		gap: 0.5vh;
	}
	.timeline-pointer {
		font-family: "Inter";
		font-weight: 600;
		font-size: .6vw;
		color: white;
		background-color: black;
		padding: 3.5px 11px;
		border-radius: 2vw;
		position: absolute;
		/* top: -19px; */
		left: 0.7vw;
		&::before {
			content: '';
			width: 68.25vw;
			height: 1px;
			background-color: #dadada;
			position: absolute;
			top: 50%;
			left: 100%;
			// @media screen and (max-width: 1680px) {
			// }
			// @media screen and (max-width: 1366px) {
			// }
			// @media screen and (max-width: 1280px) {
			// 	width: 64vw;
			// }
		}
	}

.stage-container {
	display: grid;
	// grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-columns: 0.3fr 1fr 1fr 1fr 1fr;
	gap: 0.5vh;
	// padding: 12px 50px 0 120px;
	padding: 0vh 2vw 0 0;
	position: relative;
	.stage {
		padding: 14px 0 11px 0;
		text-align: center;
		border-radius: 8px 8px 0 0;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 600;
		// font-size: 18px;
		font-size: 1vw;
		line-height: 123.02%;
		letter-spacing: -0.03em;
		color: black;
		&:nth-child(2) {
			color: var(--orange);
			background: #222222;
		}
		&:nth-child(3) {
			color: var(--blue);
			background: #222222;
		}
		&:nth-child(4) {
			color: var(--purple);
			background: #222222;
		}
		&:nth-child(5) {
			color: var(--yellow);
			background: #222222;
		}
	}

}

.timeline-timestamp {
		display: flex;
		flex-direction: column;
		position: relative;
		width: 5vw;
		// left: 1.5rem;
		// top: 8px;
		// padding: 14px 0 11px 0;
		// p {
		// 	color: black !important;
		// 	font-size: .6vw;
		// 	margin: 0;
		// 	// font-size: 16px;
		// }

	}

.__head {
	font-family: 'Inter';
	font-weight: 600;
	// font-size: 18px;
	font-size: .6vw;
	text-align: center;
	// padding: 14px 0 11px 0;
	color: black !important;
	p {
		font-size: .6vw;
		margin: 0;
		// font-size: 16px;
	}
}
.__gap {
	// display: flex;
	// flex-direction: column;
	// flex-wrap: wrap;
	// justify-content: space-around;
	// gap: 12vh;
	font-family: 'Inter';
	font-size: .6vw;
	text-align: center;
	// gap: 14vh;
	margin: 0 2vw;
	height: calc(14vh + 4vh);
	// margin: 2rem 0 0 0;
	// @media screen and (max-width: 1680px) {
	// 	gap: 10vh;
	// }
	// @media screen and (max-width: 1366px) {
	// }
	// @media screen and (max-width: 1280px) {
	// 	gap: 8.5vh;
	// }
	color: black !important;
	p {
		font-size: .6vw;
		margin: 0;
		// font-size: 16px;
	}
}

.timetable .showInfo {
	h2 {
		// margin: 3.5rem 0 0 0 !important;
		// padding-bottom: 23px;
		// @media screen and (max-width: 1680px) {
		// }
		// @media screen and (max-width: 1366px) {
		// }
		// @media screen and (max-width: 1280px) {
		// 	font-size: 1.2rem;
		// 	padding-bottom: 16px;
		// }
	}
	img {
		// margin: 20px 0;
		margin: 1.5vw 0 1vw 0;
		// @media screen and (max-width: 1680px) {
		// }
		// @media screen and (max-width: 1366px) {
		// }
		// @media screen and (max-width: 1280px) {
		// 	margin: 10px 0;
		// }
	}
	.content {
		font-family: 'Inter';
		font-weight: bold;
		font-size: 0.8vw;
		line-height: 2vh;
		color: #000000;
		text-align: left;
		margin: 2vh 0px;
	}
	.infotitle {
		font-size: 1.4vw;
		&:lang(zh) {
			font-size: 1.2vw;
		}
	}
	.infobox {
		padding-right: 1vw;
		// height: 68vh;
		overflow-y: scroll;
		height: 70vh;
		// @media screen and (max-width: 1680px) {
		// 	height: 66vh;
		// }
		.stagetag {
			background: var(--blue);
			color: rgba(34, 34, 34, 1);
			font-family: 'Inter';
			font-weight: 700;
			// font-size: 13px;
			font-size: .7vw;
			align-self: center;
			padding: 3px 10px;
			border-radius: 2vw;
			// margin-right: 8px;
			margin-right: 0.5vh;
		}
	}
}
</style>
