<!-- eslint-disable no-restricted-syntax -->
<template>
	<div class="menu-sub-container-bi">
		<div class="menu-sub-container-bi__left">
			<!-- <img class="sub__logo" src="/img/game/navbar/i_nav_logo.svg" alt="" /> -->
			<div class="flex sub__nav">
				<div
					:class="{ sub__nav__selected: 'street' === selectedStage }"
					@click.prevent="selectMap('street')"
				>
					Street Stage
				</div>
				<div
					:class="{ sub__nav__selected: 'ocean' === selectedStage }"
					@click.prevent="selectMap('ocean')"
				>
					Ocean Stage
				</div>
				<div
					:class="{ sub__nav__selected: 'dj' === selectedStage }"
					@click.prevent="selectMap('dj')"
				>
					DJ Stage
				</div>
				<div
					:class="{ sub__nav__selected: 'cinema' === selectedStage }"
					@click.prevent="selectMap('cinema')"
				>
					Cinema
				</div>
			</div>
			<div class="map">
				<div class="map__img map-clickarea">
					<div
						class="map-clickarea__cinema"
						@click.prevent="selectMap('cinema')"
					></div>
					<div style="flex-direction: column; display: flex;">
						<div
							class="map-clickarea__ocean"
							@click.prevent="selectMap('ocean')"
						></div>
						<div
							class="map-clickarea__street"
							@click.prevent="selectMap('street')"
						></div>
					</div>
					<div class="map-clickarea__dj" @click.prevent="selectMap('dj')"></div>
				</div>
				<img class="map__img" src="/img/game/map/map.svg" alt="map" />
			</div>
		</div>
		<div v-if="gotStageInfo" class="menu-sub-container-bi__right">
			<div>
				<h2>
					{{ selectedStage === "dj" ? "DJ" : selectedStage[0].toUpperCase() + selectedStage.slice(1) }}
					<span v-if="selectedStage !== 'cinema'">Stage</span>
				</h2>
				<div class="infobox">
					<img width="363" :src="stageinfo[selectedStage].imageUrl" alt="" />
					<div v-if="result" class="flex live">
						<span class="livetag">
							<span class="livetag__circle"></span>
							<span class="livetag__text">LIVE</span>
						</span>
						<!-- <span class="live__singer">{{ showname[0].title.ch }}</span> -->
						<div class="live__singer" v-html="showname[0].title.ch"></div>
					</div>
					<!-- <p class="stage-content">
						{{ stageinfo[selectedStage].info }}
					</p> -->
					<div class="stage-content" v-html="stageinfo[selectedStage].info"></div>
				</div>
			</div>
			<button
				type="button"
				class="btn-primary"
				@click.prevent="passLocation(selectedStage)"
			>
				Take me there
			</button>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
	props: {
		selectStageDummy: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			projectPath: '23-01-SunsetTown',
			// debug
			debugShowUI: true,
			hover: false,
			stage: ['Cinema', 'DJ', 'Ocean', 'Street'],
			data: [
				{
					name: 'Ning Tong',
					startTime: '2022-12-30 12:00:00 +0800',
					endTime: '2022-12-30 18:00:00 +0800'
				}
			],
			live: true,
			selectedStage: 'ocean'
		}
	},
	watch: {
		selectStageDummy: {
			handler(selectStageDummy) {
				this.selectedStage = selectStageDummy
			},
			immediate: true
		}
		// gotStageInfo: {
		// 	handler(gotStageInfo) {
		// 	}
		// }
	},
	computed: {
		...mapState('products', ['connected', 'location']),
		...mapState('products', [
			'timetable',
			'video',
			'day',
			'stageinfo',
			'gotStageInfo',
			'gotVideo',
			'gotTimetable'
		]),
		result() {
			const liveshow = []
			const datetimeNow = new Date()
			const time = datetimeNow.toLocaleTimeString('en-US', {
				hour: '2-digit',
				minute: '2-digit',
				hour12: false
			})
			const date = datetimeNow.getDate()
			let showStage
			switch (this.selectedStage) {
				case 'ocean':
					showStage = 'Ocean'
					break
				case 'street':
					showStage = 'Street'
					break
				case 'dj':
					showStage = 'DJ'
					break
				case 'cinema':
					showStage = 'Cinema'
					break
				default:
					break
			}
			const filtered = this.timetable.filter(item => item.stage === showStage)
			filtered.map(e => {
				const show = new Date(e.startAt.seconds * 1000)
				const showDate = show.getDate()
				const showTime = show.toLocaleTimeString('en-US', {
					hour: '2-digit',
					minute: '2-digit',
					hour12: false
				})
				if (showDate === date && showTime.slice(0, 2) === time.slice(0, 2)) {
					if (
						time.slice(3, 5) >= showTime.slice(3, 5) &&
						time.slice(3, 5) < parseInt(showTime.slice(3, 5), 10) + 30
					) {
						liveshow.push(e)
					}
				}
				return e
			})
			return liveshow[0]
		},
		showname() {
			return this.video.filter(e => e.showId === this.result.showId)
		}
	},
	async created() {
		// if (!this.connected) {
		// 	await this.getData()
		// 	// this.setConnected(true)
		// }
	},
	mounted() {
		this.isLive()
	},
	methods: {
		...mapMutations('products', ['setLocation']),
		// ...mapActions('products', ['getTimetable', 'getVideo', 'getStageInfo']),
		isLive() {
			const now = Date.now()
			console.log('isLive: ',now)
		},
		selectMap(value) {
			// this.selectedStage = value
			this.$emit('onSelectStage', value)
		},
		passLocation(scene) {
			this.$gtag.event(`transport_stage_${scene}`, { method: 'Google' })
			let sceneIdx
			switch (scene) {
				case 'ocean':
					sceneIdx = 0
					break
				case 'street':
					sceneIdx = 1
					break
				case 'dj':
					sceneIdx = 2
					break
				case 'cinema':
					sceneIdx = 3
					break
				default:
					sceneIdx = 0
					break
			}

			this.$emit('sendUnityMessage', 'SetPlayerTransport', sceneIdx)
			this.$emit('sendUnityMessage', 'focusCloudContainer')
			this.$emit('toggleNav')
			this.setLocation(scene)
		}
	}
}
</script>
<style lang="scss">
.btn-primary {
	background: #ff7a00;
	border: none;
	border-radius: 2vw;
	display: block;
	padding: 4vh 2vw;
	color: black;
	width: calc(100% - 1vw);
	font-family: 'Inter';
	font-size: 1.5vw;
	font-weight: 600;
	cursor: pointer;
	align-self: start;
	margin-bottom: 6vh;
    padding: 4vh 2vw;
	// @media screen and (max-width: 1680px) {
	// 	padding: 3vh 2vw;
	// }
	// @media screen and (max-width: 1367px) {
	// 	padding: 3vh 1.5vw;
	// }
	// @media screen and (max-width: 1280px) {
	// 	margin: 8px 0 0 0;
	// 	padding: 4vh 2vw;
	// }
}

::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}
::-webkit-scrollbar-thumb {
	background: #e1e1e1;
	border: 0px none #ffffff;
	border-radius: 50px;
}
</style>
