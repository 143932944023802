<template>
	<div id="UnityNavBar" :class="{'hideGameUI' : hideGameUI }">
		<ul class="menu">
			<li @click.prevent="toggleNav" style="position: relative;">
				<img
					:src="[
						toggle
							? navHover ? '/img/game/navbar/i_nav_off_active.svg' : '/img/game/navbar/i_nav_off.svg'
							: navHover ? '/img/game/navbar/i_nav_on_active.svg' : '/img/game/navbar/i_nav_on.svg'
					]"
					alt="nav menu"
					@mouseover="navHover = true"
					@mouseout="navHover = false"
				/>
				<div v-show="!toggle">
					<p  class="icon_text">Tab</p>
				</div>
			</li>
			<transition name="slidefade" :duration="250">
				<ul v-show="toggle" class="menu-sub">
					<template v-for="item in submenu">
						<li :key="item.alt" @click.prevent="selectMenu(item.alt)">
							<img
								:src="item.alt === selection || item.isActive? item.active:item.none"
								:alt="item.alt"
								:class="{ 'menu-sub__selected': item.alt === selection }"
								@mouseover="item.isActive = true"
								@mouseout="item.isActive = false"
							/>
						</li>
					</template>
				</ul>

			</transition>
		</ul>
		<transition name="fade" :duration="500">
			<div v-if="toggle" class="menu-modal">
				<keep-alive>
					<component :is="selectionComp" :selectStageDummy="selectStageDummy" :selectDayDummy="selectDayDummy" :showZoneReport="showZoneReport" :isDemo="isDemo" @onSelectDay="onSelectDay" @onSelectStage="onSelectStage" :zoneServerReport="zoneServerReport" @sendUnityMessage="sendUnityMessage" @toggleNav="toggleNav" :projectPath="projectPath"></component>
				</keep-alive>
			</div>
		</transition>
	</div>
</template>

<script>
// import firebase from 'firebase/app'
import MapView from '@/components/UnityNavBar/map.vue'
import TimetableView from '@/components/UnityNavBar/timetable.vue'
import InstructionView from '@/components/UnityNavBar/instruction.vue'
import zoneServerView from '@/components/UnityNavBar/zoneServer.vue'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
	components: {
		MapView,
		TimetableView,
		InstructionView,
		zoneServerView
	},
	props: {
		projectPath: {
			type: String,
			default: null,
		},
		zoneServerReport: {
			type: Object,
			default: null
		},
		isDemo: {
			type: Boolean,
			default: false
		},
		showZoneReport: {
			type: Boolean,
			default: null,
		},
		hideGameUI: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			// debug
			debugShowUI: true,
			toggle: false,
			selectStageDummy: "street", // save map clicked recorded dummy
			selectDayDummy: "day1",
			navHover: false,
			submenu: [
				{
					alt: 'map',
					src: '/img/game/navbar/i_nav_location_active.svg',
					none: '/img/game/navbar/i_nav_location.svg',
					active: '/img/game/navbar/i_nav_location_active.svg',
					isActive: false
				},
				{
					alt: 'info',
					src: '/img/game/navbar/i_nav_info.svg',
					none: '/img/game/navbar/i_nav_info.svg',
					active: '/img/game/navbar/i_nav_info_active.svg',
					isActive: false
				},
				{
					alt: 'zoneServer',
					src: '/img/game/navbar/i_nav_zone.svg',
					none: '/img/game/navbar/i_nav_zone.svg',
					active: '/img/game/navbar/i_nav_zone_active.svg',
					isActive: false
				}
				// ,{
				// 	alt: 'profile',
				// 	src: '/img/game/navbar/i_nav_profile.svg'
				// },
				// {
				// 	alt: 'tool',
				// 	src: '/img/game/navbar/i_nav_tool.svg'
				// },
				// {
				// 	alt: 'instruction',
				// 	src: '/img/game/navbar/i_nav_instruction.svg'
				// }
			],
			selection: 'map'
		}
	},
	async created() {
		if (!this.connected) {
			await this.getData()
			this.setConnected(true)
		}
	},
	computed: {
		...mapState('products', [
			'timetable',
			'video',
			'day',
			'stage',
			'connected'
		]),
		// eslint-disable-next-line consistent-return, vue/return-in-computed-property
		selectionComp() {
			if (this.selection === 'map') return MapView
			if (this.selection === 'info') return TimetableView
			if (this.selection === 'instruction') return InstructionView
			if (this.selection === 'zoneServer') return zoneServerView
		}
	},
	methods: {
		...mapMutations('products', ['setConnected']),
		...mapActions('products', ['getTimetable', 'getVideo', 'getStageInfo']),

		toggleNav() {
			this.toggle = !this.toggle
			if (this.toggle) {
				this.$emit('sendUnityMessage', 'SetCursorFree')
			} else {
				this.$emit('sendUnityMessage', 'SetCursorLock')
				this.$emit('sendUnityMessage', 'focusCloudContainer')
			}
		},
		selectMenu(value) {
			this.selection = value
			this.$gtag.event(`unityNavbar_select_${value}`, { method: 'Google' })
		},
		async getData() {
			this.getStageInfo()
			this.getTimetable()
			this.getVideo()
		},
		sendUnityMessage(functionName, data) {
			this.$emit('sendUnityMessage', functionName, data)
		},
		onSelectStage(value) {
			this.selectStageDummy = value
		},
		onSelectDay(value) {
			this.selectDayDummy = value
		}
	}
}
</script>

<style lang="scss">
ul {
	list-style: none;
	maring: 1rem 0;
	list-style-type: none;
}
#UnityNavBar {
	position: fixed;
	top: 0;
	right: 0;
	margin: 1vh 1.5vw;
	z-index: 1000;
}
.menu {
	display: flex;
	flex-direction: row-reverse;
	// gap: 20px;
	gap: 1vw;
	// margin: 1rem 0;
	margin: 1vh 0;
	.icon_text {
		// font-size: 14px;
		font-size: .6vw;
		font-weight: 700;
		color: white;
		position: absolute;
		transform: translate(-50%, 100%);
		top: 50%;
    	left: 50%;
		// margin: 0;
		// text-align: center;
	}
	li {
		cursor: pointer;
		img {
			filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.3));
			height: 4vh;
		}
	}
}
.menu-sub {
	display: flex;
	// gap: 20px;
	gap: 1vw;

}
.menu-sub__selected {
	filter: contrast(2);
}
.menu-modal {
	background: white;
	background-blend-mode: color-burn;
	// border-radius: 10px;
	border-radius: 1vw;
	// height: calc(100vh - 7rem);
}

.flex {
	display: flex;
	align-items: center;
}
.menu-sub-container-bi {
	display: grid;
	grid-template-columns: 75vw 22vw;
	// height: 100%;
	height:90vh;
	// grid-auto-rows: 95%;
	&__left {
		display: flex;
    	flex-direction: column;
		.sub {
			&__logo {
				position: fixed;
				top: 24px;
			}
			&__nav {
				justify-content: center;
				// gap: 36px;
				gap: 1vw;
				margin: 2vh 0;
				// font-size: 18px;
				font-size: .8vw;
				color: black;
				font-weight: 600;
				div {
					border-radius: 43px;
					padding: 8px 15px;
					cursor: pointer;
					&:hover {
						background: gray;
					}
				}
				&__selected {
					background: black !important;
					color: white;
					background-blend-mode: multiply;
				}
			}
		}
		.map {
			// display: flex;
			// justify-content: center;
			// position: relative;
			// height: 97%;
			position: relative;
			width: 100%;
			height: 100%;
			&__img {
				// width: calc(100vw / 1.5);
				// height: 78vh;
				// width: 100%;
				// height: 100%;
				// margin: 0;
				position: absolute;
				width: 100%;
				height: 97%;
			}
			.map-clickarea {
				z-index: 99;
				position: absolute;
				// width: calc(100vw / 2);
				width: 100%;
				height: 100%;
				display: grid;
				grid-template-columns: 1fr 1fr 1fr;
				// grid-template-rows: repeat(3, minmax(0px, auto));
				// cursor: pointer;
				&__cinema {
					// height: 36vh;
					height: 25vh;
					width: 25vh;
					margin-top: 26vh;
					margin-left: 11vw;
					border-radius: 100%;
					// margin-top: 16vh;
					cursor: pointer;
					transition: .1s all;

					&:hover {
						// background: rgba(0, 0, 0, 0.2)
					}
				}
				&__ocean {
					// height: 28vh;
					cursor: pointer;
					transition: .1s all;
					// width: 25vh;
					height: 25vh;
					width: 25vh;
					// margin: 5vh 0 0 8vh;
					margin: 5vh 0 0 8.5vw;
					border-radius: 100%;

					&:hover {
						// background: rgba(0, 0, 0, 0.2)
					}
					}
				&__street {
					// height: 28vh;
					height: 25vh;
					width: 25vh;
					margin-top: 7vh;
					margin-left: 5.5vw;
					border-radius: 100%;
					cursor: pointer;
					transition: .1s all;
					&:hover {
						// background: rgba(0, 0, 0, 0.2)
					}
				}
				&__dj {
					// margin-top: 27vh;
					// height: 36vh;
					margin: 27vh 0vh 0 -5.4vw;
					height: 25vh;
					width: 25vh;
					border-radius: 100%;
					cursor: pointer;
					transition: .1s all;

					&:hover {
						// background: rgba(0, 0, 0, 0.2)
					}
				}
			}
		}
	}
	&__right {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: space-between;
		border-left: 1px solid black;
		// padding: 10px 33px 10px 33px;
		padding: 1vh 3vh;
		align-items: center;
		// height: 95%;
		// @media screen and (max-width: 1280px) {
		// 	padding: 10px;
		// }
		h2 {
			font-family: 'Inter';
			font-weight: 700;
			font-size: 2vw;
			color: black;
			// line-height: 123.02%;
			letter-spacing: -0.03em;
			padding-bottom: 10px;
			border-bottom-style: solid;
			border-bottom-width: 4px;
			border-bottom-color: #ff7a00;
			// margin: 29px 23px 0 0;
			margin: 3vh 1.25vw 0 0;
			// @media screen and (max-width: 1280px) {
			// 	margin: 10px 23px 0 0;
			// }
		}
		img {
			width: 100%;
			margin: 1.5vw 0 1vw 0;
			// margin: 1.5vw 0;
			// @media screen and (max-width: 1280px) {
			// 	margin: 1vw 0;
			// }
		}
		.infobox {
			// padding-right: 19px;
			padding-right: 1vw;
			height: 58vh;
			overflow-y: scroll;
			// @media screen and (max-width: 1280px) {
			// 	height: 40vh;
			// }
		}
		.livetag {
			display: flex;
			align-items: center;
			background: #ff7a00;
			width: fit-content;
			height: fit-content;
			padding: 3px 12px 3px 9px;
			margin-right: 7px;
			border-radius: 60px;
			gap: 9px;
			&__circle {
				background: #e20000;
				border-radius: 100%;
				width: 9px;
				height: 9px;
			}
			&__text {
				font-weight: 600;
				font-size: 15px;
				color: #000000;
			}
		}
		.live__singer {
			font-family: 'Inter';
			font-weight: bold;
			font-size: 0.8vw;
			line-height: 123.02%;
			color: #000000;
			letter-spacing: -0.03em;
		}
		.stage-content {
			font-family: 'Inter';
			font-weight: bold;
			// font-size: 18px;
			font-size: 0.8vw;
			// line-height: 123.02%;
			// letter-spacing: -0.03em;
			line-height: 2vh;
			color: #000000;
			// text-align: left;
			text-align: left;
			margin: 2vh 0px;
		}
	}

}
.zone {
	padding: 0 3vh;
}



</style>
