<template>
	<div class="menu-sub-container">
		<img class="sub__logo" src="/img/game/navbar/i_nav_logo.svg" alt="" />
		<img src="/img/game/navbar/i_nav_tutorial.svg" alt="" />
	</div>
</template>

<script>
export default {
	props: {},
	data() {
		return {
			// debug
			debugShowUI: true
		}
	}
}
</script>
<style lang="scss" scoped>
.menu-sub-container {
	display: flex;
	flex-direction: column;
	gap: 48px;
	height: 87vh;
	align-items: center;
	.sub__logo {
		margin-top: 36px;
	}
}
</style>
