/* eslint-disable import/prefer-default-export */
const ubitus = (url) => {
    return new Promise((res) => {
        const script = document.createElement('script');
        script.onload = () => res();
        script.onerror = () => {
            console.log(`☁Ubitus script onload error or timeout.`)
        }
        script.setAttribute('src', url);
        document.head.appendChild(script);
    })
}

export default ubitus

// export default new Promise((res) => {
//     const script = document.createElement('script');
//     script.onload = () => res();
//     script.onerror = () => {
//         console.log(`☁Ubitus script onload error or timeout.`)
//     }
//     script.setAttribute('src', 'https://ubitus-naxs-demo.gometaverse.gg/resources/onnine3.js');
//     document.head.appendChild(script);
//   });