<template>
	<div id="MobileInput" :class="{ isTablet: isTablet }">
		<div>
			<div v-if="!useCustomJoystick" id="zone_NPJS"></div>
			<div v-if="useCustomJoystick" id="zone_customJoystick">
				<div v-show="Touching === false" class="bg">
					<div class="bgBtn"></div>
				</div>
				<div
					ref="touchArea"
					class="touchArea"
					:class="[Touching ? '' : 'opacity-0']"
					@touchmove.prevent="touchMove"
					@touchstart.prevent="touchMove"
					@touchend.prevent="touchend"
				>
					<div class="arc" :style="angleCss"></div>
					<div ref="touchPointer" class="touchPointer"></div>
				</div>
			</div>
		</div>
		<div id="zone_buttons" :class="{ isTablet: isTablet }">
			<div id="RunButton" class="moveBtn" @touchstart.prevent="mousedownRunButton" @touchend.prevent="mouseupRunButton" >
				<div class="moveBtnicon" :class="[runTouch ? 'runTouch' : '']" ></div>
			</div>
			<div id="FlyButton" class="moveBtn" @touchstart.prevent="mousedownFlyButton" @touchend.prevent="mouseupFlyButton" >
				<!-- <img class="moveBtnicon" src="/img/ID0-Run-Handle.svg" /> -->
				<div class="moveBtnicon" :class="[flyTouch ? 'flyTouch' : '']"></div>
			</div>

		</div>

		<div id="CMFreelook" class="Landscape-only none-select" @touchstart="mousedownFreelook($event)" @touchmove="mousemoveFreelook($event)" @touchend="mouseendFreelook" ></div> 
	</div>
</template>

<script>
import nipplejs from 'nipplejs'
import { isTablet } from 'mobile-device-detect'
// import event from '@/js/event'
export default {
	name: 'MobileInput',
	props: {
	},
	data() {
		return {
			useCustomJoystick: true,
			Touching: false,
			runTouch: false,
			flyTouch: false,
			pointerPos: {
				x: 0,
				y: 0,
			},
			pointerTransform: null,
			nippleManager: null,
			// Mouse FreeLook
			flags: false,
			lastPos: {x: 0, y: 0},
			nx: '', ny: '',
			rotateSpeed: {x: .8, y: .8},

			// Joystick touches
			MoveInput: false,
			CMInput: false,
			MoveTouch: null,
			CMTouch: null,
			MoveAngle: 0
		}
  	},
	computed: {
		// pointerCss(){
		// 	return `transform: translate(${this.pointerPos.x}px, ${this.pointerPos.y}px);`
		// },
		angleCss() {
			return `transform: rotate(${this.MoveAngle}deg);`
		},
		isTablet() {
			return isTablet
		},
		
	},
	mounted() {
		
		window.addEventListener('resize', () => {
			this.reset()
		})
		this.reset()
	},
	watch: {
  	},
	methods: {
		reset(){
			if(this.useCustomJoystick)
				this.resetTouch()
			else
				this.initNippleJS()
		},
		scale (number, inMin, inMax, outMin, outMax) {
			return (number - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
		},
		touchMove(e){
			let touch
			this.Touching = true
			touch = e.targetTouches[e.targetTouches.length-1] // eslint-disable-line
			// const areaSize = {x: this.$refs.touchArea.offsetWidth, y: this.$refs.touchArea.offsetHeight}
			// const relPos = { x: (touch.clientX-this.$refs.touchArea.offsetParent.offsetLeft), y: (touch.clientY-this.$refs.touchArea.offsetParent.offsetTop)}
			// const result = {x: (relPos.x - areaSize.x)/areaSize.x*2, y: (relPos.y - areaSize.y)/areaSize.y*2*-1 }
			// const result = {x: relPos.x/areaSize.x*2, y: relPos.y/areaSize.y*-1 }
			
			const touchPos = { x: touch.clientX, y: touch.clientY }
			const areaPos = { x: this.$refs.touchArea.offsetParent.offsetLeft + this.$refs.touchArea.offsetParent.offsetWidth / 2, y: this.$refs.touchArea.offsetParent.offsetTop + this.$refs.touchArea.offsetParent.offsetHeight / 2 }
			// console.log('touchPos', touchPos)
			const moveRawX = touchPos.x - areaPos.x
			const moveRawY = touchPos.y - areaPos.y
			// console.log('moveRawX', moveRawX, 'moveRawY', moveRawY)

			// const moveValueX = this.normalize(moveRawX, -1, 1)
			// const moveValueY = this.normalize(moveRawY, -1, 1)
			
			const result = { x: this.scale(moveRawX, -100, 100, -1, 1), y: this.scale(moveRawY, -100, 100, 1, -1)}
			result.x = Math.min(Math.max(result.x, -1), 1)
			result.y = Math.min(Math.max(result.y, -1), 1)
			// console.log(result)

			// console.log('areaPos', areaPos)


			// console.log('areaSize', areaSize)
			// console.log('relPos', relPos)
			// console.log('result', result)
			// console.log('deg', Math.atan2(result.y, result.x) - 30)
			// this.MoveAngle = -Math.atan2(result.y, result.x) - 4.25
			const radians = Math.atan2(touchPos.x - areaPos.x, touchPos.y - areaPos.y);
			const degree = (radians * (180 / Math.PI) * -1) + 180;
			this.MoveAngle = degree

			this.$emit('sendUnityMessage', 'FSMove', result.x, result.y)

			// this.pointerPos.x = relPos.x - this.$refs.touchPointer.offsetWidth/2
			// this.pointerPos.y = relPos.y - this.$refs.touchPointer.offsetHeight/2
			// console.log('pointerPos', this.pointerPos)

			// this.pointerTransform = `transform: translate(${relPos.x}px, ${relPos.y}px);`
		},
		touchend() {
			this.resetTouch()
			this.Touching = false
			// this.MoveInput = false
		},
		resetTouch() {
			this.pointerPos.x = this.$refs.touchPointer.clientWidth/2
			this.pointerPos.y = this.$refs.touchPointer.clientHeight/2
			this.$emit('sendUnityMessage', 'FSMove', 0, 0)
		},
		initNippleJS() {
			console.log('initJoysitck')
			if(this.nippleManager){
				console.log('initJoysitck destroy')
				this.nippleManager.destroy()
			}

			const options = {
				color: 'transparent',
				zone: document.getElementById('zone_NPJS'),
				multitouch: false,
				mode: 'static',                   // 'dynamic', 'static' or 'semi'
				position: {left: '60px', bottom: '60px'},
				size: 120,
			}

			this.nippleManager = nipplejs.create(options)
			this.nippleManager.on('move', (evt, data) => {
				// console.log(data.vector)
				this.$emit('sendUnityMessage', 'FSMove', data.vector.x, data.vector.y)
			});
			this.nippleManager.on('end', () => {
				// on touch released
				this.$emit('sendUnityMessage', 'FSMove', 0, 0)
			});

		},
		onAnimClick(num, state){
			const animProp = `FSAnim${num-1}`
			console.log('onAnimClick', animProp, state)
			this.$emit('sendUnityMessage', animProp, `${state}`)
		},
		onAnimUp(num, state) {
			this.onAnimClick(num, state)
		},
		mousedownFlyButton() {
			this.flyTouch = true
			this.$emit('sendUnityMessage', 'FSJump', 'true')
		},
		mousedownRunButton() {
			this.runTouch = true
			this.$emit('sendUnityMessage', 'FSRun', 'true')
		},
		mouseupFlyButton() {
			this.flyTouch = false
			this.$emit('sendUnityMessage', 'FSJump', 'false')
		},
		mouseupRunButton() {
			this.runTouch = false
			this.$emit('sendUnityMessage', 'FSRun', 'false')
		},
		mousedownFreelook(e) {
			this.flags = true

			let touch
			touch = e.targetTouches[e.targetTouches.length-1] // eslint-disable-line

			this.lastPos.x = touch.clientX
			this.lastPos.y = touch.clientY
		},
		mousemoveFreelook(e) {
			if (this.flags)
			{
				let touch
				touch = e.targetTouches[e.targetTouches.length-1] // eslint-disable-line
				this.nx = ((touch.clientX - this.lastPos.x) / window.innerWidth) * 50
				this.ny = -(((touch.clientY - this.lastPos.y) / window.innerHeight) * 50)

				if(isTablet){
					// easier rotate for bigger screen
					this.nx *= 2
					this.ny *= 2
				}

				this.nx *= this.rotateSpeed.x
				this.ny *= this.rotateSpeed.y

				this.$emit('sendUnityMessage', 'FSCMFreelook', this.nx, this.ny)
				this.lastPos.x = touch.clientX
				this.lastPos.y = touch.clientY
			}
		},
		mouseendFreelook() {
			// this.CMInput = false
			this.flags = false
			this.$emit('sendUnityMessage', 'FSCMFreelook', 0, 0)
		}
	},
}
</script>

<style lang="scss" scoped>
@import '@/theme/vars.scss';

@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&family=Poppins:wght@300&display=swap%27');

*{
	// border: 1px solid white;
	user-select: none;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;

	// Prevent unexpected zoom or pan on mobile devices
	touch-action: none;
}

.opacity-0 {
	opacity: 0;
}

#MobileInput {
	position: fixed;
	width: 100vw;
	height: 100vh;
	left: 0;
	bottom: 0;
}

#zone_buttons{
	position: absolute;
	right: 7vw;
	bottom: 0vw;
	z-index: 3;
		
	.moveBtn {
		position: absolute;
		// height: 88px;
		// width: 8vw;
		// height: 8vw;
		// max-width: 88px;
		// max-height: 88px;
	}

	// #animBtns{
	// 	display: flex;
	// 	align-items: center;
	// 	gap: 4vh;
	// 	// right: 9vw;
	// 	top: 10vh;
	// 	left: 8vw;
	// 	.animBtn{
	// 		font-family: "Poppins";
	// 		font-size: 1vw;
	// 		display: flex;
	// 		align-items: center;
	// 		justify-content: center;
	// 		margin: 0 5px;
	// 		border-radius: 2vw;
	// 		right: 30px;
	// 		width: 4.5vw;
	// 		height: 2.5vw;
	// 		max-width: 60px;
	// 		max-height: 40px;
	// 		color: white;
	// 		background: rgba(255,255,255,0.1);
	// 		border: none;

	// 		&:active{
	// 		background: rgba(255,255,255,0.6);
	// 		}
	// 	}
	// 	@include tablet {
			
	// 	}

	// 	@include mobile {
	// 		right: 8.7vw;
	// 	}
	// }
	.moveBtnicon {
		height: 3.6vw;
		width: 3.6vw;
		border: 1px solid #ccc;
		border-radius: 100%;
	}
	.flyTouch {
		width: 10vw;
		height: 10vw;
		position: absolute;
		left: -3.25vw;
    	bottom: -3.5vh;
		touch-action: none;
	}
	.runTouch {
		width: 10vw;
		height: 10vw;
		position: absolute;
		left: -3.25vw;
    	bottom: -3.5vh;
		touch-action: none;
	}
	// #RunButton {
	// 	bottom: 18vh;
	// 	right: 0;
	// 	z-index: 2;
	// 	width: max-content;
	// }
	#FlyButton {
		left: -13.2vw;
		bottom: 16vh;
		width: 7vw;
    	height: 7vw;
		z-index: 2;
		width: max-content;
		display: flex;
		align-items: center;
		flex-direction: column;
		align-content: center;
		justify-content: center;
		touch-action: none;
	}

	#RunButton {
		bottom: 18vh;
		width: 7vw;
    	height: 7vw;
		// right: -3.5vw;
		z-index: 2;
		left: -4.1vw;
		// bottom: 16vh;
		z-index: 2;
		width: max-content;
		display: flex;
		align-items: center;
		flex-direction: column;
		align-content: center;
		justify-content: center;
		touch-action: none;
	}
	// #FlyButton {
	// 	left: -16vw;
	// 	bottom: 10vh;
	// 	z-index: 2;
	// }
}

#zone_buttons.isTablet {
	#RunButton {
		bottom: 7vw;
		right: 3vw;
		z-index: 2;
	}
	#FlyButton {
		left: -20vw;
		bottom: 3vw;
		z-index: 2;
	}
}

#CMFreelook {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	touch-action: none;
}
#zone_NPJS{
	z-index: 2;
	position: absolute;
	bottom: 9vw;
	left: 9vw;
	height: 3.6vw;
	width: 3.6vw;
	border: 1px solid #ccc;
	border-radius: 100%;
}
#zone_customJoystick {
	// opacity: 0.5;
    z-index: 2;
    /* color: red; */
    /* background-color: red; */
    position: absolute;
    /* top: 0; */
    /* right: 0; */
    bottom: 14vh;
    width: 12vw;
    left: 8vw;
    height: 12vw;
	.bg {
		// position: fixed;
		// width: 70vw;
		// height: 50vh;
		background: linear-gradient(
			0deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(255, 255, 255, 0) 50%
		);
		left: 50%;
    	bottom: 50%;
		pointer-events: none;
		position: absolute;
		.bgBtn {
			position: relative;
			left: -1.8vw;
    		top: 1.8vw;
			height: 3.6vw;
			width: 3.6vw;
			border: 1px solid #ccc;
			border-radius: 100%;
		}
	}
	.touchArea{
		border: 1px solid #ffffff87;
		border-radius: 100%;
		width: 16vw;
		height: 16vw;
		position: absolute;
		z-index: -1;
		left: -2vw;
		bottom: -2vw;
		.arc {
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			left: -1vw;
			top: -1vw;
			// top: -4.5%;
			// left: -4.5%;
			transform: rotate(0deg);
			border: 1vw solid;
			border-color: rgba(255, 255, 255, 0.3) transparent transparent;
			border-radius: 100%;
			z-index: -8;
			content: '';
		}
	}
	// .touchPointer{
	// 	pointer-events: none;
	// 	background: rgba(255,255,255,0.1) !important;
	// 	&:active{
	// 		background: rgba(255,255,255,1);
	// 	}
	// 	width: 50%;
	// 	height: 50%;
	// }
}

// // Tablet
// #MobileInput.isTablet{
	
// 	#zone_customJoystick {
// 		bottom: 10vw;
// 		.touchArea{
// 			min-width: 180px;
// 			min-height: 180px;
// 		}
// 	}
// 	#zone_buttons{
// 		bottom: 9vw;
// 		right: 5vw;
// 	}
// }

// .ID0animBtn {
// 	display: flex;
// 	flex-direction: row;
// 	position: fixed;
// }

// #imganimbtn {
// 	@include mobile {
// 		width: 12vh;
// 	}
// 	@include tablet {
// 		width: 8vh;
// 	}
// }

</style>
<style lang="scss">
.nipple {
	.back, .front{
		border: 2px solid #fff;
	}
	.front{
		background: rgba(255,255,255,0.1) !important;
		&:active{
			background: rgba(255,255,255,1);
		}
	}
}
</style>