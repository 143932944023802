<template>
  <div v-if="isMobile">
      <nav v-if="visibleInfoBtn">
        <img v-show="!onInfo && !onChat" @click="toggleInfo" class="MB-Info-icon" :src="mbInfoImg" />
        <img v-show="!onInfo && !onChat" class="MBChaticon" @click="toggleChat" :src="mbChatImg" />
        <img v-show="onInfo" @click="toggleInfo" class="MB-close-icon" :src="mbCloseImg" />
        <img v-show="onChat" @click="toggleChat" class="MB-close-icon" :src="mbCloseImg" />
      </nav>

      <!-- <footer v-if="isAndroid">
        <div class="MB-btn-fullscreen" :class="{'onToggle': !introState}" href="#" @click="MBsetFullscreen">
          <img class="icon" src="/img/ID0FullScreen.svg" />
        </div>
      </footer> -->
  </div>

  <div v-else-if="!isMobile" :class="{'hideGameUI' : hideGameUI }">
      <footer>
        <img v-if="visibleInfoBtn" class="PC-Info-icon" @click="toggleInfo" :class="{'onToggle': onInfo}" :src="mbPCInfoImg" />

        <!-- <div class="PC-btn-fullscreen" :class="{'onToggle': !introState, 'home': !gameStarted}" href="#" @click="PCsetFullscreen()">
          <img class="user-drag" v-bind:src=" !introState ? '/img/ID0exitFullScreen.svg' : '/img/ID0FullScreen.svg'" />
        </div> -->

      </footer>
  </div>
</template>

<script>
import { isMobile, isAndroid} from 'mobile-device-detect'

export default {
  props: {
    visibleInfoBtn: Boolean,
    hideGameUI: Boolean,
  },
  data() {
    return {
        introState: true,
        onInfo: false,
        onChat: false,

        // ui image url
        mbInfoImg: '/img/game/input/mobile/icon_help_MB.svg',
        mbChatImg: '/img/game/input/mobile/icon_chat_MB.svg',
        mbCloseImg: '/img/game/input/mobile/Btn_Close.svg',
        mbPCInfoImg: '/img/game/input/icon_help.svg'
    }
  },
  beforeMount() {
  },
  mounted(){
    window.addEventListener('resize', () => {
      // console.log(this.introState)
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        // eslint-disable-next-line
        const screenWidth = screen.width;
        // eslint-disable-next-line
        const screenHeight = screen.height;

        if (windowWidth === screenWidth && windowHeight === screenHeight) {
            this.introState = false
        }
        else
          this.introState = true
    });

    document.addEventListener('fullscreenchange', () => {
      if (document.fullscreenElement) this.introState = false
      else this.introState = true
    })

  },

  computed: {
    isMobile() {
      return isMobile
    },    
    isAndroid() {
      return isAndroid
    },
  },

  methods:{
    MBsetFullscreen() {
        document.body.requestFullscreen()
    },
    PCsetFullscreen() {
      if(this.introState){
        if(this.$browserDetect.isFirefox)
          document.body.requestFullscreen()
        else
          document.body.webkitRequestFullScreen()
      }
      else if(!this.introState){
        if(this.$browserDetect.isFirefox)
          document.exitFullscreen()
        else
          document.webkitCancelFullScreen()
      }
    },
    noFullscreen() {
      if(this.$browserDetect.isFirefox)
          document.exitFullscreen()
        else
          document.webkitCancelFullScreen()
    },
    toggleInfo(){
      this.onInfo = !this.onInfo
      this.$emit('toggleInfo', this.onInfo)
    },
		toggleChat() {
      this.onChat = !this.onChat
      this.$emit('toggleChat', this.onChat)
    },
  },

    // if( this.$browserDetect.isChrome || this.$browserDetect.isFirefox || this.$browserDetect.isSafari ){				
}
</script>

<style lang="scss">
@import '@/theme/vars.scss';

.MB-Info-icon{
  pointer-events: auto;
  position: fixed;
  right: 8vw;
  top: 10vh;
  width: 4.5vw;
  height: 4.5vw;
  z-index: 1100;
  opacity: 0.75;
  font-family: inter, sans-serif;
  color: white;
  background-color: black;
  border-radius: 50%;
  font-size: 1.5vw;
  text-align: center;
  line-height: 3vw;
  &.onToggle{
    opacity: 0.75;
  }
}
.MBChaticon{
  pointer-events: auto;
  position: fixed;
  right: 8vw;
  top: 22vh;
  width: 4.5vw;
  z-index: 1100;
  opacity: 0.75;
}
.MB-close-icon{
  pointer-events: auto;
  position: fixed;
  right: 8vw;
  top: 9.5vh;
  width: 4.5vw;
  z-index: 1100;
}

.PC-Info-icon{
  pointer-events: auto;
  position: fixed;
  right: 2vw;
  bottom: 2vw;
  width: 35px;
  z-index: 999;
  // &.onToggle{
  //   opacity: 0.75;
  // }
}

.MB-btn-fullscreen {
  display: block;
  position: fixed;
  right: 3vw;
  bottom: 4vh;
  z-index: 999;
  img {
    width: 4vw;
    height: 4vw;
  }
  .seperator {
    height: 100px;
  }
  &.onToggle{
    display: none;
  }
}

.PC-btn-fullscreen {
  pointer-events: auto;
  cursor: pointer;
  position: fixed;
  right: 3vw;
  bottom: 2.7vw;
  z-index: 999;
  max-height: 1.85vw;
  &.home{
    bottom: 1.8vw;
  }
  img {
    width: 1.2vw;
    height: 1.2vw;
    fill: white;
  }
  .seperator {
    height: 100px;
  }
  &.onToggle{
    opacity: 0.7;
  }
}
</style>