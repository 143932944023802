<template>
	<div>
		<div
			id="unity-container"
			ref="containerRef"
			@mousemove="FSUserInteractedPC()"
			@touchstart="FSUserInteracted()"
			@touchmove="FSUserInteracted()"
			:class="{ showContainer: unitySystemLoaded }"
			:style="{
				width: FurioosWidth,
				height: FurioosHeight,
			}"
		></div>
		<div
			v-show="showDefaultProgress"
			:class="{ isFinished: unitySystemLoaded }"
			class="default-progress-bar"
		>
			<div
				class="progress-fill"
				:style="{ width: unityLoadingProgress * 100 + 'vw' }"
			></div>
		</div>

		<MobileInput
			v-if="useMobileJoystick && isMobile"
			@sendUnityMessage="sendUnityMessage"
			class="Landscape-only"
		>
		</MobileInput>
		<!-- <PCInput ref="PCInput"
			v-if="showPCInput"
			@MockUnityMessage="onGetUnityMessage($event)"
			@sendUnityMessage="sendUnityMessage"
		>
		</PCInput> -->
	</div>
</template>

<script>
// import InApp from 'detect-inapp';
import { isMobile } from 'mobile-device-detect'
// import Event from '@/js/event.js' // eslint-disable-line
import { mapGetters, mapState } from 'vuex'
import { isNil } from 'lodash'
import { Player } from 'furioos-sdk'
import MobileInput from '@/components/MobileInput.vue'
// import PCInput from '@/components/PCInput.vue'
// import { boolean } from 'is_js'
// import { string } from 'is_js'

export default {
	name: 'UnityContainer_Furioos',
	components: {
		MobileInput,
		// PCInput,
	},
	props: {
		furioosLinkId: {
			type: String,
			default: null,
		},
		furioosLinkId_backup: {
			type: String,
			default: null,
		},
		externalProgress: Function,
		useMobileJoystick: {
			type: Boolean,
			default: false,
		},
		usePCInput: {
			type: Boolean,
			default: false,
		},
		showDefaultProgress: {
			type: Boolean,
			default: false,
		},
		mobileAutoResize: {
			type: Boolean,
			default: true,
		},
		furioosAutoStart: {
			type: Boolean,
			default: true,
		},
		furioosHideToolbar: {
			type: Boolean,
			default: true,
		},
		module: Object,
	},

	data() {
		return {
			unityLoaderUrl: '../unityHelpers/UnityLoader_2020.js', // hard coded path
			unityInstance: null,
			unityDownloaded: false,
			unitySystemLoaded: false,
			unityLoadingProgress: 0,
			progressStarted: false,
			error: null,
			onceTu: true,
			furioosPlayer: null,
			furioosPlayerContainer: null,
			isDebugMode: false,
			FurioosHeight: '100%',
			FurioosWidth: '100%',
			FurioosTop: '0',
			FurioosLeft: '0',
			furioosIframe: null,
			userIdleTime: 20, // how long can a user stay inactive before kicked out
			userInactiveCount: 0,
			FS: {
				triedMainId: false,
				triedBackup: false,
				isConnecting: false,
				fsIdConnecting: null,
				onLoadCallback: null,
			},
		}
	},

	computed: {
		...mapGetters('authentication', ['isUserLoggedIn']),
		...mapState('authentication', ['user']),
		...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle']),
		isMobile() {
			return isMobile
		},
		showPCInput(){
			// debug only
			// if (process.env.NODE_ENV === 'development') {
			// 	if(!isMobile && this.isDebugMode)
			// 		return true 
			// }
			if(!isMobile && this.usePCInput && this.unitySystemLoaded) return true
			return false
		}
	},

	watch: {
		user: {
			handler(user) {
				if (!isNil(user)) {
					// wait to modified
					// setTimeout(
					// 	() => this.sendUnityMessage('SetUserIdAnonymously', this.user.uid),
					// 	9000
					// )
				}
			},
			immediate: true,
		},
	},
	beforeMount() {},

	mounted() {
		// Debug use: Force to show unity container without Furioos connection in dev mode
		if (process.env.NODE_ENV === 'development') {
			this.isDebugMode = true
			this.debugModeEmulation()
		} else {
			this.isDebugMode = false
		}

		window.addEventListener('resize', () => {
			this.checkFurioosFrameSize()
		})
		console.log('fsContainer mounted', this.furioosLinkId, this.furioosLinkId_backup) 

		this.initFurioosPlayer(this.furioosLinkId)
		
		this.checkFurioosFrameSize()
	},
	// End Mounted

	methods: {
		async debugModeEmulation(){
			console.log('FS DebugMode')
			const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

			await delay(3000)
			
			// this.$emit('ContainerState', 'FSLoading')
			this.$emit('ContainerState', 'OnProgressStart')
			
			await delay(3000)

			this.$emit('ContainerState', 'OnStreamStart')
			this.SetUnitySystemLoaded()
		},
		timeout(ms) {
			return new Promise(resolve => setTimeout(resolve, ms));
		},
		checkFurioosFrameSize() {
			let windowW = window.innerWidth // eslint-disable-line
			let windowH = window.innerHeight // eslint-disable-line
			let width = windowW
			let height = (windowW / 16) * 9
			let left
			let top

			if (isMobile) {
				// return // disable mobile auto resize
				if(this.mobileAutoResize){
					if (windowW < windowH) {
						const windowWTemp = windowW
						windowW = windowH
						windowH = windowWTemp
					}
				}else{
					return
				}
			}

			if (windowW / windowH >= 16 / 9) {
				// fit width, extend height
				width = windowW
				height = (windowW / 16) * 9
				top = (height - windowH) / 2
				this.sendUnityMessage('WindowOffset', 0, top)
			} else {
				// fit height, extend width
				height = windowH
				width = (windowH / 9) * 16
				left = (width - windowW) / 2
				this.sendUnityMessage('WindowOffset', left, 0 )
			}

			if(this.FurioosHeight !== `${height}px` || this.FurioosWidth !== `${width}px`){
				this.FurioosHeight = `${height}px`
				this.FurioosWidth = `${width}px`
				console.log('AutoResize', this.FurioosWidth, this.FurioosHeight)
			}
		},
		initFurioosPlayer(fsIdConnecting) {
			// Furioos loading order: onLoad > SignIn > StreamAvailable? > AppInstall Progress (copying > Decompressing) >  onAppInstallSuccess/Failed > onAppStart > onStreamStart
			console.log(
				'🔴 FS Init',
				fsIdConnecting,
				this.furioosAutoStart
			)

			this.FS.fsIdConnecting = fsIdConnecting
			
			if(fsIdConnecting === this.furioosLinkId_backup){
				this.FS.triedBackup = true
			}
			if(fsIdConnecting === this.furioosLinkId){
				this.FS.triedMainId = true
			}

			const furioosOptions = {
				whiteLabel: true,
				hideTitle: true,
				hideToolbar: this.furioosHideToolbar,
				hidePlayButton: !this.furioosAutoStart,
				inactiveTimeout: 86400000,
				debugAppMode: this.isDebugMode, // This enable the local debug mode. Require websocket server from sdk example.
				// overridedURL: 'http://192.168.31.139:8080/'
			}

			// remove existing doms
			this.$refs.containerRef.innerHTML = ''

			this.furioosPlayer = new Player(
				fsIdConnecting,
				'unity-container',
				furioosOptions
			)

			this.furioosPlayer.onLoad(() => {
				if(this.FS.onLoadCallback !== this.FSOnload) {
					console.log('🔴 FS onLoad returned')
					// return
				}
				this.FSOnload()
			})
			this.FS.onLoadCallback = this.FSOnload
		},
		FSOnload(){
			console.log('🔴 FS onLoad ', this.FS.fsIdConnecting)
			this.FSGetServerAvailability(this.FS.fsIdConnecting)
			this.furioosIframe = document.querySelector('#furioos-sdk-iframe')
			this.furioosPlayerContainer = document.querySelector('#unity-container')

			this.furioosIframe.addEventListener('keydown', e => {
				console.log('FS Iframe keydown', e.key)
			})
			// its not work to focus
			this.furioosIframe.addEventListener('click', () => {
				console.log('FS Iframe Onclick')
			})
			this.furioosIframe.addEventListener('touchstart', () => {
				console.log('FS Iframe touchstart')
			})
		},
		FSGetServerAvailability(sdkId) {
			this.furioosPlayer.getServerAvailability((data) => {
				console.log('🔴 FS getServerAvailability:', sdkId, data)
				if(data.maximumMachines && data.maximumMachines > 0){
					// has reservation
					console.log('ContainerState', 'has reservation')

					if (data.availableMachines > 0) {
						console.log('ContainerState', 'FSHasRezMachine')
						this.$emit('ContainerState', 'FSHasRezMachine')
						this.FSStartConnection(sdkId)
					}else if(data.usedMachines < data.maximumMachines ){
						console.log('ContainerState', 'FSWaitInLine')
						this.$emit('ContainerState', 'FSWaitInLine')
						this.FSTryReconnect(sdkId)
					}else{
						console.log('ContainerState', 'FSFull')
						this.$emit('ContainerState', 'FSFull')
						this.FSTryReconnect(sdkId)
					}
				}else{
					// no reservation
					console.log('ContainerState', 'no reservation')

					if (data.availableMachines > 0) {
						// 
						console.log('ContainerState', 'FSLoading')
						this.$emit('ContainerState', 'FSLoading')
						this.FSStartConnection(sdkId)

						// show loading progress
						this.$emit('ContainerState', 'OnProgressStart')
					}else{
						this.FSTryReconnect(sdkId)
						console.log('ContainerState', 'FSFull')
						this.$emit('ContainerState', 'FSFull')
					}
				}
			})
		},
		FSTryReconnect(currentSdkId){
			console.log('🔴 FS TryReconnect', currentSdkId)
			if(!this.furioosLinkId_backup) {
				// No backup id
				this.FSStartConnection(currentSdkId)
				return
			}

			if(!this.FS.triedBackup){
				// Haven't try backup yet
				this.initFurioosPlayer(this.furioosLinkId_backup)
			}else if(this.FS.triedMainId){
				if(currentSdkId === this.furioosLinkId_backup){
					// switch back to main sdk id
					this.initFurioosPlayer(this.furioosLinkId)
				}else{
					this.FSStartConnection(currentSdkId)
				}
			}else{
				// No other options, start connection and wait
				this.FSStartConnection(currentSdkId)
			}
		},
		FSStartConnection(currentSdkId){
			if(this.FSStarted) return
			console.log('🔴 FS FSStartConnection', currentSdkId)
			this.furioosPlayer.start()
			this.FS.isConnecting = true
			this.FSStarted = true
			
			// Bind application install progress
			this.furioosPlayer.onAppInstallProgress((value) => {
				if(!this.progressStarted){
					this.progressStarted = true
					this.$emit('OnProgressStart')
				}
				this.onProgress(value.progress)
			})

			this.furioosPlayer.onAppStart(() => {
				// never called
				console.log('FS onAppStart')
				// this.SetUnitySystemLoaded()
				this.FSGetVMData()
			})

			// Bind stream start
			this.furioosPlayer.onStreamStart(() => {
				console.log('🔴 FS FSOnStreamStart')
				this.$emit('ContainerState', 'FSOnStreamStart')
				// this.SetUnitySystemLoaded() // todo: should be delete if OnSystemLoaded is stable
				this.sendUnityMessage('OnWebAppStart')
				this.FSGetVMData()
			})

			this.furioosPlayer.onSessionStopped(() => {
				if(!this.FS.isConnecting){
					this.$emit('ContainerState', 'FSSessionStopped')
					console.log('🔴 FS onSessionStopped emitted')
				}else{
					console.log('🔴 FS onSessionStopped')
				}
			})			

			this.furioosPlayer.onUserActive(() => {
				console.log('FS onUserActive')
			})

			this.furioosPlayer.onUserInactive(() => {
				console.log('FS onUserInactive')
			})

			// this.furioosPlayer.onStats((stats) => {
			// 	console.log('FS onStats', stats)
			// })		

			// Bind SDK messages
			this.furioosPlayer.onSDKMessage((data) => {
				console.log('FS SDK Message Received:', data)
				this.onGetUnityMessage(data)
			})

			// FSActiveCheck
			setInterval(this.FSUserActiveCheck, 10000)

			// checkFurioosFrameSize
			setInterval(this.checkFurioosFrameSize, 15000)
		},
		FSUserActiveCheck(){
			if(!this.unitySystemLoaded) return

			if(this.isMobile) return // not working for mobile yet

			this.furioosPlayer.setUserActive()
			// const limit = this.userIdleTime * 60 * 1000 / 10000 // limitMin * seconds * miliseconds / interval
			// if(this.userInactiveCount >= limit){
			// 	console.log('FSUserActiveCheck  Stop :', this.userInactiveCount, limit)
			// 	this.furioosPlayer.stop()
			// 	this.$emit('ContainerState', 'FSUserInatctiveStopped')
			// }
			// console.log('FSUserActiveCheck  userInactiveCount:', this.userInactiveCount, limit)
			// this.userInactiveCount += 1
		},
		FSUserInteracted(){
			if(this.userInactiveCount > 0){
				this.userInactiveCount = 0
			console.log('FSUserActiveCheck reset')
			}
			console.log('FSUserInteracted mobile')
		},
		FSUserInteractedPC(){
			if(this.userInactiveCount > 0){
				this.userInactiveCount = 0
			console.log('FSUserActiveCheck reset')
			}
		},
		FSGetVMData () {
			this.furioosPlayer.getServerMetadata((metadata) => {
				console.log('FS VM Metadata: ', metadata)
			})
		},

		// #region Shared method name between furioos and webgl
		sendUnityMessage(method, param = '', param2 = '') {
			// console.log('_sum', method, param, param2)

			if (this.furioosPlayer === null) {
				console.warn(
					"you've sent a message to the Furioos player, but it wasn\t instantiated yet."
				)
				return
			}

			if (method === 'FSMove' || method === 'FSCMFreelook' || method === 'WindowOffset') {
				this.furioosPlayer.sendSDKMessage({
					functionName: method,
					x: param,
					y: param2,
				})
				console.log(method, param, param2)
				return
			}

			if (method === 'SetInputFocusInUnity') {
				if (param === 'true') {
					// if(this.usePCInput){
					// 	this.$nextTick(() => {
					// 		console.log('focus',this.$refs.PCInput.$el)
					// 		this.$refs.PCInput.$el.focus();
							
					// 		const div = document.getElementById('inputZone')
					// 		div.focus()
					// 	});
					// }else if(this.furioosIframe !== null){
					// 		this.furioosIframe.focus()
					// 		console.log('focus', this.furioosIframe)
					// }
					if(this.furioosIframe !== null){
						this.furioosIframe.focus()
						console.log('focus', this.furioosIframe)
						// const mouseenter = new MouseEvent('mouseenter', {
						// 	'view': window,
						// 	'bubbles': true,
						// 	'cancelable': true
						// });
						// this.furioosIframe.dispatchEvent(mouseenter);
					}
					
					// if(this.furioosIframe !== null){
					// 	this.furioosIframe.focus()
					// 	console.log('focus', this.furioosIframe)
					// }
				} else {
					window.focus()
					console.log('focus', window)
				}
			}

			// Send regular message
			this.furioosPlayer.sendSDKMessage({
				functionName: method,
				value: param,
			})
			console.log(method, param)
		},
		// #endregion
		// #region Same methods between furioos and webgl
		onProgress(progress) {
			// console.log('onProgress', progress)
			this.unityLoadingProgress = progress
			this.$emit('externalProgress', progress)
		},
		onGetUnityMessage(data) {
			const params = data.split('/')
			if (params.length >= 2) {
				console.log('_ogum', params[0], params[1])
				this.$emit('onGetUnityMessage', params[0], params[1])
			} else {
				console.log('_ogum ', data)
				this.$emit('onGetUnityMessage', data)
			}

			switch (data) {
				case 'OnSystemLoaded':
					this.SetUnitySystemLoaded()
					break
				case 'OnGameEnd':
					this.$emit('onUnityGameEnd')
					break
				default:
					break
			}
		},
		SetUnitySystemLoaded(){
			this.unitySystemLoaded = true
			this.$emit('onUnitySystemLoaded')
			this.SendUserIdToUnity()
			this.FSGetVMData()
			this.furioosIframe.setAttribute("muted", "false");
		},
		SendUserIdToUnity() {
			// if (!isNil(this.user)) {
				// must wait for NXEvent to be initialized
				// todo: fix NXEvent in Unity
				// setTimeout(() => {
				// 	this.sendUnityMessage('SetUserIdAnonymously', this.user.uid)
				// }, 2000)
			// }
		},
		// #endregion
	},
}
</script>


<style lang="scss">
#unity-container iframe {
	border: none;
}
</style>
<style lang="scss" scoped>
#unity-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	opacity: 0;

	position: fixed;
	min-width: 100vw;
	min-height: 100vh;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	iframe {
		border: none;
	}

	&.showContainer {
		opacity: 1;
	}
	&.forceShow {
		opacity: 1;
	}
}

.default-progress-bar {
	position: fixed;
	width: 100%;
	height: 5px;
	bottom: 0px;
	left: 0;
	z-index: 999;
	opacity: 0;
	background: #000;
	.progress-fill {
		height: 100%;
		background: #ccc;
		transition: width 0.1s;
		z-index: 999;
	}
	&.showProgress {
		opacity: 1;
		transition: 1s;
		transition-delay: 1s;
		// .progress-fill {
		// 	width: 100% !important;
		// }
	}
}

button.dev {
	position: fixed;
	left: 10px;
	bottom: 10px;
}
</style>
