<template>
    <div class="playfab-container">
        <h1 style="margin: 0 !important">
            {{this.projectPath}} Playfab Server
        </h1>
        <p style="margin: 0 !important">Now: {{nowTime}}</p>
        <h2 style="margin: 1vh 0 1vh 0">
            All Players: <span class="count-num">{{playerCountSum}}</span>
        </h2>

        <div class="zone-dev-container">
            <div class="zone-dev-item" v-for="item in orderZone(zoneList)" :key="item.zone">
                <p>Server: {{item.zone}}</p>
                <p>IP: {{item.ip}}</p>
                <p>maxConnections: {{item.maxConnections}}</p>
                <p>playerCount: {{item.playerCount}}</p>
            </div>
        </div>
    </div>
</template>
<script>

import firebase from 'firebase/app'
import _ from 'lodash'

export default {
    props: {
        projectPath: {
            type: String,
            default: '',
        }
    },
    computed: {
        playerCountSum() {
            let sum = 0
            this.zoneList.forEach((item) => {
                sum += parseInt(item.playerCount, 10)
            })
            return sum
        },
    },
    data() {
        return {
            zoneList: [],
            nowTime: null,
        }
    },
    methods: {
        getZoneList() {
            firebase
            .firestore()
            .collection('_projects')
            .doc(this.projectPath)
            .collection('serverList')
            .onSnapshot( snapshot => {
                    this.zoneList.length = 0
                    snapshot.forEach((doc) => {
                        if (doc.data().zone === '-1') return
                        this.zoneList.push(doc.data())
                    })
                },
                err => {
                    console.log('Unable to fetch the serverList document:', err.message)
                }
            )
        },

        orderZone(zoneList) {
            // const roomList = Array.from(zoneList)
            const roomList = JSON.parse(JSON.stringify(zoneList));
            roomList.forEach(item => {
                // item.zone = item.zone.replace('zone', 'room')
                item.id = parseInt(item.zone.slice(4), 10)
                // console.log(item.zone, item.id)
            })

            return _.orderBy(roomList, 'id', 'asc'); 
        },
    },
    mounted() {
        this.getZoneList()

        window.setInterval(() => {
			const now = new Date()
			this.nowTime = now
		}, 1000)
    }
}
</script>
<style lang="scss">
.playfab-container {
    align-self: baseline;
    text-align: start;
    margin: 1vw;
}

.zone-dev-container {
    overflow-y: scroll; 
    width: 100%; 
    height: 80vh; 
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.zone-dev-item {
    border-radius: 2vw;
    background: chartreuse;
    width: 90%;
    font-family: 'Inter';
    font-weight: bold;
    font-size: 0.7vw;
    padding: 1vh;

    p {
        color: black !important;
        margin: 0 !important;
    }
}

.count-num {
    font-size: 3vw;
    color: cyan;
}
</style>