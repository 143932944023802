<template>
    <div>
        <transition name="fade">
            <div
                v-if="!devPermit"
                class="layout-fullview"
                style="z-index: 9999; background-color: black;"
            >
                <form
                    style="width: 15%"
                >
                    <label style="color: white !important;">dev login</label>
                    <input 
                        v-model="devPassword"
                        type="password"
                    >
                    <button
                        @keydown.enter="devLogin"
                        @click.prevent="devLogin"
                    >
                        enter
                    </button>
                </form>
            </div>
        </transition>
        <!-- <p>{{ BuildVersion }}</p> -->
        <!-- Wireframe for debug -->
        <div class="layout-fullview" v-if="devPermit && debugUI" id="wireframe" style="display: flex; flex-direction: row; justify-content: space-between; background: black;">
            <!-- <button class="debug-btn" @click="getCookie('_user_token')">getCookie _user_token</button> -->
            <playfabConsole
                :projectPath="projectPath"
            ></playfabConsole>
            <div style="text-align: center; align-self: end; width: fit-content; padding: 1vw; margin: 1vw; background: navy; border:none; border-radius: 2vw;">
                <!-- USER SESSION OPERATION -->
                <div style="background: black; border-radius: 2vw;">
                    <label style="font-size:1vw; font-weight: bold; font-family: 'Inter';">USER SESSION OPERATION</label>
                    <br />
                    <label>UserId</label> <input type="text" v-model="operationUserId">
                    <br />
                    <label>gameSession </label>
                    <select v-model="operationSession">
                        <option value="day1">day1</option>
                        <option value="day2">day2</option>
                        <option value="day3">day3</option>
                        <option value="day4">day4</option>
                        <option value="test">test</option>
                    </select>
                    <br />
                    <button class="debug-btn" @click.prevent="UserSessionOperation('ban')">ban</button>
                    <button class="debug-btn" @click.prevent="UserSessionOperation('reactive')">reactivate</button>
                    <p></p>
                </div>

                <!-- GAME FUNCTION -->
                <div style="background: black; border-radius: 2vw;">
                    <label style="font-size:1vw; font-weight: bold; font-family: 'Inter';">GAME FUNCTION</label>
                    <p></p>
                    <button class="debug-btn" @click.prevent="playfabLogin">playfabLogin</button>
                    <p></p>
                    targetZone
                    <input
                        type="value"
                        v-model="testZoneInput"
                        value
                    />
                    <p></p>
                    <button class="debug-btn" @click.prevent="testGetZoneServer">testGetZoneServerFunction</button>
                    <p></p>
                    <button class="debug-btn" @click.prevent="sendUnityMessage('wmcopydataTest', 'Hello Unity!')">sendWMCopyDataTest</button>
                    <p></p>
                    <button class="debug-btn" @click.prevent="debugGameAuth">debug GameAuth function</button>
                    <p></p>
                    <!-- NotificationText:{{notificationText}} -->
                    <!-- <p></p> -->
                </div>

                <!-- KEYGEN FOR SUNSETTOWN -->
                <div style="background: black; border-radius: 2vw;">
                    <label style="font-size:1vw; font-weight: bold; font-family: 'Inter';">
                        KEYGEN FOR SUNSETTOWN
                    </label>
                    <p></p>
                    <div style="display: flex; flex-direction:row; justify-content: space-between; margin: 0 5vw;">
                        <div><label>keyType</label> <input type="text" v-model="keyGenType" value /></div>
                        <div><label>beta</label> <input type="checkbox" v-model="keyGenIsBeta" /></div>
                    </div>
                    <p></p>
                    <div style="display: flex; flex-direction:row; justify-content: space-between; margin: 0 2vw;">
                        <div>
                            <label>keySession </label>
                            <select v-model="keyGenSession">
                                <option value="day1">day1</option>
                                <option value="day2">day2</option>
                                <option value="day3">day3</option>
                                <option value="day4">day4</option>
                                <option value="test">test</option>
                            </select>
                        </div>
                        <div>
                            <label>
                                amount
                            </label>
                            <input
                                type="number"
                                v-model="keyGenAmount"
                                value
                            />
                        </div>
                    </div>
                    <p></p>
                    <button class="debug-btn" @click="keyGenForSunsettown(keyGenType, keyGenSession, keyGenAmount, keyGenIsBeta)">keyGenForSunsettown</button>
                    <br>
                </div>

                <!-- BASIC KEYGEN -->
                <div style="background: black; border-radius: 2vw;">

                    <p></p>
                    <label style="font-size:1vw; font-weight: bold; font-family: 'Inter';">
                        BASIC KEYGEN
                    </label>
                    <p></p>
                    <label>keyType </label> 
                    <select v-model="keyGenSession">
                        <option value="day1">day1</option>
                        <option value="day2">day2</option>
                        <option value="day3">day3</option>
                        <option value="day4">day4</option>
                        <option value="test">test</option>
                    </select>
                    <p></p>
                    <label>
                        amount
                    </label>
                    <input
                        type="number"
                        v-model="keyGenAmount"
                        value
                    />
                    <p></p>
                    <button class="debug-btn" @click="keyGen(keyGenSession, keyGenAmount)">
                        keyGen
                    </button>
                    <p></p>
                </div>

                <!-- USER FUNCTION -->
                <div style="background: black; border-radius: 2vw;">
                    <label style="font-size:1vw; font-weight: bold; font-family: 'Inter';"> USER FUNCTION</label>
                    <p></p>
                    <button class="debug-btn" @click="lookStoreUser">
                        Look Store User
                    </button>
                    <p></p>

                    <select v-model="keyGenSession">
                        <option value="day1">day1</option>
                        <option value="day2">day2</option>
                        <option value="day3">day3</option>
                        <option value="day4">day4</option>
                        <option value="test">test</option>
                    </select>
                    <p></p>
                    <button class="debug-btn" @click="getAuthedUserCount(keyGenSession)">
                        Get authed user count
                    </button>
                    <p></p>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import { mapMutations } from 'vuex'
import firebase from 'firebase/app'
// import PlayFab from 'playfab-sdk'
import playfabConsole from '@/components/playfabConsole.vue'

export default {
    components: {
		playfabConsole
    },
    props: {
        projectPath: {
            type: String,
            default: '',
        },
        debugUI: {
			type: Boolean,
			default: false
		},
    },
    data() {
        return {
            // debugUI: false,
			devPermit: false,
			devPassword: null,
			adminPassword: 'sunset.town2023',

			// key generator
			keyGenType: '',
			keyGenSession: '',
			keyGenAmount: 0,
			keyGenStartpoint: 0,
			keyGenIsBeta: false,

			// ban, reactive User
			operationUserId: '',
			operationSession: null,

            testZoneInput: null,
        }
    },
    methods: {
        ...mapMutations('app', ['setPreloading']),

        debugGameAuth() {
			const post = {
				appId: 'naxsfuture_sunsettown_test',
				userId: 'ubitus_test',
				gameToken: 'test'
			}
			fetch('https://us-central1-srvc-sunsetmusic.cloudfunctions.net/gameAuth', {
				method: 'POST',
				header: {'Content-Type': 'application/json'},
				body: JSON.stringify(post)
			}).then(result => {
				console.log(`gameAuthed`)
				result.json().then(data => {
					console.log(data)
				})
			}).catch(e => {
				console.log(e.message)
			})
		},
		// testGetZoneServer() {
		// 	const request = {
		// 		FunctionName: 'getZoneServer',
		// 		FunctionParameter: { 
		// 			zone: this.testZoneInput,
        //             projectName: this.projectPath,
        //             firebaseProjName: this.firebaseProjName,
        //             firebaseAPIID: this.firebaseAPIId
		// 		},
		// 		GeneratePlayStreamEvent: true
		// 	}

		// 	let callbackResult;
        //     let callbackError;

        //     const playFabCallback = (error, result) => {
        //         if (result) {
        //             callbackResult = result.data.FunctionResult
		// 			console.log(callbackResult)
        //         } else if (error) {
		// 			callbackError = error
		// 			console.log(callbackError)
        //         }
        //     }

		// 	PlayFab.PlayFabClient.ExecuteCloudScript(request, playFabCallback);
		// },
		keyGen(event, num) {
			const keyGen = firebase.app().functions('us-central1').httpsCallable('keyGen');
			keyGen({path: `${this.projectPath}/${event}`, count: num})
				.then(result => {
					console.log(result)
				})
				.catch(e => {
					console.log(e.message)
				})
		},
		keyGenForSunsettown(type, session, amount, beta, startpoint) {
			const keyGenSunsetTown = firebase.app().functions('us-central1').httpsCallable('keyGenSunsetTown');
			keyGenSunsetTown({type, path: `${this.projectPath}/${session}`, count: amount, beta, startpoint})
				.then(result => {
					console.log(result)
				})
				.catch(e => {
					console.log(e.message)
				})
		},
		UserSessionOperation(f) {
			if (f === 'ban') {
				const banFunction = firebase.app().functions('us-central1').httpsCallable('banUserSession');
				banFunction({userId: `${this.operationUserId}`, session: `${this.operationSession}`, projPath: this.projectPath})
				.then(result => {
						console.log(result)
					})
					.catch(e => {
						console.log(e.message)
					})
			} else if (f === 'reactive') {
				const reactiveFunction = firebase.app().functions('us-central1').httpsCallable('reactiveUserSession');
				reactiveFunction({userId: `${this.operationUserId}`, session: `${this.operationSession}`, projPath: this.projectPath})
				.then(result => {
						console.log(result)
					})
					.catch(e => {
						console.log(e.message)
					})
			}
		},
		getAuthedUserCount(event) {
			const userCountFunction = firebase.app().functions('us-central1').httpsCallable('getKeyUsedCount');
			userCountFunction({path: this.projectPath, event})
			.then(result => {
				console.log(result)
			})
			.catch(e => {
				console.log(e.message)
			})
		},
		lookStoreUser() {
			console.log('store', this.user, 'token', this.token)
		},
        devLogin() {
			if (this.devPassword === this.adminPassword) {
				this.setPreloading(true)
				this.devPermit = true
				// this.debugUI = false
                this.$emit('OnDevPermit', this.devPermit)
			}
		},
    },
}
</script>
<style lang="scss">
    
</style>