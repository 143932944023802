<template>
    <!-- Tutorial -->
    <div :class="{'hideGameUI' : hideGameUI }">
        <div
            v-if="showInfo"
            class="TU-Mask layout-fullview Landscape-only-flex"
            style="z-index: 999"
        >
            <div
                style="position: relative; height: auto; margin-top: auto;"
            >
            </div>
            <img 
                class="tutorial-tips__pc-img"
                v-show="showInfoTips"
                :src="pcTutorialTipsImg"
            />
            <img
                class="tutorial-img"
                :class="{ 'tutorialimg-img_st__pc': !isMobile }"
                :src="pcTutorialImg"
                v-show="showTutorial"
            />
        </div>
        <!-- <div
            v-if="isMobile && showTutorial || isMobile && showInfo"
            class="TU-Mask opacity-50 layout-fullview Landscape-only-flex"
        >
            <img
                class="tutorial-img"
                :src="
                    showTutorial ? mbTutorialImg : mbInfoImg
                "
            />
            <button
                v-if="!showInfo"
                class="nextbtn btn-game"
                @click="closeTutorial"
                >
                NEXT
            </button>
        </div> -->
    </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';

export default {
    props: {
        showInfoTips: Boolean,
        showInfo: Boolean,
        showTutorial: Boolean,
        hideGameUI: Boolean,
    },
    computed: {
        isMobile() {
            return isMobile
        }
    },
    data() {
        return {
            // ui image url
            pcTutorialImg: "/img/game/input/Tutorial_PC_st.svg",
            pcTutorialTipsImg: "img/game/input/Tutorial_PC_tips.svg",
            mbTutorialImg: "/img/game/input/mobile/tutorial_MB.svg",
            mbInfoImg: "/img/game/input/mobile/info_MB.svg"
        }
    },
    methods: {
        closeTutorial() {
			console.log('closeTutorial')
			this.$emit('toggleTutorial', false)
		},
    },
}
</script>
<style lang="scss">
    
</style>