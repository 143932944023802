<template>
	<vue-avatar-editor
		class="photoeditor"
		@finished="saveCrop"
		:hasScale="hasScale"
		:hasRotation="hasRotation"
		@select-file="onSelectFile($event)"
		@sizealert="fileSizeAlert"
		@sendPreview="getPreview($event)"
		@showPreview="showPreview"
		ref="vueavatareditor"
		:width="512"
		:height="512"
		:image2="userThumbnailUrl"
		:saveDone="saveDone"
	>
	</vue-avatar-editor>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { isNil } from 'lodash'
import firebase from 'firebase/app'
import VueAvatarEditor from '@/components/VueAvatarEditor'

export default {
	components: {
		VueAvatarEditor
	},
	props: ['isSystemLoaded'],
	data() {
		return {
			registerForm: {
				name: '',
				email: '',
				password: ''
			},
			thumbnails: {
				thumbnail_512: '',
				thumbnail_256: '',
				thumbnail_128: ''
			},
			thumbnailsURL: {
				thumbnail_512: '',
				thumbnail_256: '',
				thumbnail_128: ''
			},
			loginError: null,
			pwdAlert: null,
			photoAlert: null,
			idAlert: null,
			havephoto: false,
			userID: '',
			alreadyinuse: null,
			btnsuccess: false,
			isWaiting: false,
			previewimage: '',
			hasRotation: false,
			hasScale: true,
			saveDone: false,

			userThumbnailUrl: null,
		}
	},
	computed: {
		...mapState('authentication', ['user']),
		...mapState('app', ['networkOnLine', 'appTitle']),

		isUploaded() {
			if (this.thumbnailsURL.thumbnail_128 && this.thumbnailsURL.thumbnail_256 && this.thumbnailsURL.thumbnail_512) {
				console.log('upload completed', {
					'thumbnail_128': this.thumbnailsURL.thumbnail_128,
					'thumbnail_256': this.thumbnailsURL.thumbnail_256,
					'thumbnail_512': this.thumbnailsURL.thumbnail_512 
				})
				return true
			}
			console.log('uploading... ', {
				'thumbnail_128': this.thumbnailsURL.thumbnail_128,
				'thumbnail_256': this.thumbnailsURL.thumbnail_256,
				'thumbnail_512': this.thumbnailsURL.thumbnail_512 
			})
			return false
		}

		// fillup() {
		// 	if (
		// 		this.registerForm.name !== '' &&
		// 		this.registerForm.email !== '' &&
		// 		this.registerForm.password !== '' &&
		// 		this.havephoto
		// 	)
		// 		return true

		// 	return false
		// }
	},
	watch: {
		user: {
			handler(user) {
				// console.log('watch user handler')
				if (!isNil(user)) {
					// console.log('watch user handler !isNil')
					// this.$store.dispatch('modals/closeAll')
					this.userID = this.user.id
					//   const redirectUrl = isNil(this.$route.query.redirectUrl)
					//     ? '/welcome'
					//     : this.$route.query.redirectUrl
					//   console.log('redirecting to', redirectUrl)
					//   this.$router.push(redirectUrl)
					this.checkUserThumbnail(user)
				}
			},
			immediate: true
		},
		isSystemLoaded: {
			handler(isSystemLoaded) {
				if (isSystemLoaded === true && this.registerForm.name !== '') {
					// console.log('systemLoad and generate displayname')
					setTimeout(
						() => this.$emit('displayName', this.registerForm.name),
						300000
					)
					setTimeout(
						() => this.$emit('displayName', this.registerForm.name),
						8000
					)
				}
			},
			immediate: true
		},
	},
	mounted() {
		// this.generateID()
		// this.$refs.IDinput.focus()
	},
	methods: {
		...mapMutations('app', ['setPreloading']),
		...mapMutations('authentication', ['setUser']),
		// called when user authed
		checkUserThumbnail(user) {
			const imageUrl = user.thumbnail_512
			this.userThumbnailUrl = imageUrl
			// console.log('UserAvatar userThumbnailUrl loaded: ', this.userThumbnailUrl)
		},

		// called when file is dropped
		onSelectFile(files) {
			console.log('here is your file', files[0])
			this.photoAlert = null
			this.havephoto = true
			this.saveDone = false
		},

		changeThumbnail() {
			for (let i=0; i<=3; i += 1) {
				this.actualChangeFunc()
			}
		},

		async actualChangeFunc() {
			const tag = 'thumbnail_128'
			this.thumbnailsURL[tag] = 'abc'
		},

		getPreview(img) {
			this.previewimage = img
				.toDataURL()
				.replace(/^data:image\/(png|jpg);base64,/, '')
			this.$emit('avatarphoto', this.previewimage)
		},

		// examineID() {
		// 	if (this.registerForm.name !== '') this.idAlert = null
		// 	this.$emit('displayName', this.registerForm.name)
		// },
		ispending(state) {
			this.$emit('loading', state)
		},

		fileSizeAlert() {
			this.photoAlert = 'Uploaded image cannot smaller than 512*512'
		},

		saveCrop(img) {
			// if img is empty, skip all profile pic update
			if (img === null) return

			for (let i = 9; i >= 7; i -= 1) {
				const newCanvas = document.createElement('canvas')
				const newContext = newCanvas.getContext('2d')
				// newCanvas.width = parseInt(2 ** i, 1)
				// newCanvas.height = parseInt(2 ** i, 1)
				newCanvas.width = 2 ** i
				newCanvas.height = 2 ** i
				// console.log(152, newCanvas.width, newCanvas.height)
				newContext.drawImage(img, 0, 0, newCanvas.width, newCanvas.height)

				const size = 2 ** i
				const temp = newCanvas.toDataURL()
				if (i === 9) {
					this.$emit(
						'avatarphoto',
						temp.replace(/^data:image\/(png|jpg);base64,/, '')
					)
				}
				// console.log(163, size.toString())
				const tname = `thumbnail_${size.toString()}`
				// console.log(165, tname)
				this.thumbnails[tname] = temp.replace(/^data:image\/[a-z]+;base64,/, '')
			}

			// console.log('thumbnails', this.thumbnails)
			this.saveProfile()
			// this.createProfile()
		},

		// submitRegister() {
		// 	this.alreadyinuse = null
		// 	this.pwdAlert = null
		// 	if (this.registerForm.name === '') {
		// 		this.idAlert = 'Please fill up your ID!'
		// 	} else if (this.registerForm.password.length < 6) {
		// 		this.pwdAlert = 'Password should be at least 6 characters'
		// 	} else if (this.havephoto && !this.pwdAlert && !this.photoAlert) {
		// 		this.isWaiting = true
		// 		this.$refs.vueavatareditor.finished()
		// 		/* console.log(
		// 			'submitRegister',
		// 			this.registerForm.email,
		// 			this.registerForm.password) */
		// 	} else if (!this.havephoto) {
		// 		this.photoAlert = 'Please upload your profile image'
		// 	}
		// },

		async saveProfile() {
			// save avatar pic
			for (let i = 9; i >= 7; i -= 1) {
				// const tnumber = parseInt(2 ** i, 1)
				const tnumber = 2 ** i
				const tname = `thumbnail_${tnumber.toString()}`
				// console.log('199', tname)
				this.uploadImage(this.thumbnails[tname], tname).then(async() => {
					if (this.isUploaded) {
						await this.updateDB()
						await this.updateAuth()
						// this.showSaveState = true
						// this.$refs.vueavatareditor.onUploadFinished()
					}
				})
			}
			this.$emit('updatepic', this.thumbnailsURL.thumbnail_128) // nav pic

			// userName from GameLogin.vue
			this.setPreloading(false)
		},

		// async createProfile() {
		// 	await this.createUser()
		// 	if (!this.alreadyinuse) {
		// 		// this.userID
		// 		this.$emit('signupUserID', this.userID)
		// 		// this.btnsuccess = true
		// 		this.$store.dispatch('modals/closeAll')
		// 		for (let i = 9; i >= 7; i -= 1) {
		// 			const tnumber = parseInt(2 ** i, 1)
		// 			const tname = `thumbnail_${tnumber.toString()}`
		// 			this.uploadImage(this.thumbnails[tname], tname)
		// 		}
		// 		this.$emit('updatepic', this.thumbnailsURL.thumbnail_128)
		// 		// this.$emit('avatarphoto', this.thumbnailsURL.thumbnail_512)
		// 		await this.updateDB()
		// 		await this.updateAuth()
		// 		this.isWaiting = false
		// 	}
		// },

		// async createUser() {
		// 	try {
		// 		const res = await firebase
		// 			.auth()
		// 			.createUserWithEmailAndPassword(
		// 				this.registerForm.email,
		// 				this.registerForm.password
		// 			)
		// 		if (!res) {
		// 			throw new Error('Could not create user')
		// 		}
		// 		// console.log('User created ', res.user)
		// 		await res.user.updateProfile({ displayName: this.registerForm.name })
		// 		this.setUser(res.user)
		// 		this.userID = res.user.uid
		// 		this.ispending(true)

		// 		await firebase
		// 			.firestore()
		// 			.collection('users')
		// 			.doc(this.userID)
		// 			.set({
		// 				displayName: this.registerForm.name,
		// 				email: this.registerForm.email
		// 			})
		// 			.catch(console.error)
		// 	} catch (err) {
		// 		if (
		// 			err.message ===
		// 			'The email address is already in use by another account.'
		// 		) {
		// 			this.alreadyinuse = err.message
		// 			this.isWaiting = false
		// 		}
		// 		console.log('CreateUser failed:', err.message)
		// 	}
		// },

		async uploadImage(imgurl, tag) {
			const filePath = `users/${this.userID}/profilePic/${this.userID}_${tag}.jpg`
			const storageRef = firebase.storage().ref(filePath)

			try {
				const res = await storageRef.putString(imgurl, 'base64', {
					contentType: 'image/jpg'
				})
				const url = await res.ref.getDownloadURL()
				this.thumbnailsURL[tag] = url
				// this.$set(this.thumbnailsURL, tag, url)
				console.log('upload', firebase.auth().currentUser.uid, tag, url)
				console.log('URL', this.thumbnailsURL)
			} catch (err) {
				console.log('Upload Image error:', err.message)
			}
		},

		async updateDB() {
			// try {
			// 	await firebase
			// 		.database()
			// 		.ref(`users/${this.userID}`)
			// 		.update({
						// email: this.registerForm.email,
						// uid: this.userID,
			// 			photoURL: this.thumbnailsURL.thumbnail_512,
			// 			thumbnail_512: this.thumbnailsURL.thumbnail_512,
			// 			thumbnail_256: this.thumbnailsURL.thumbnail_256,
			// 			thumbnail_128: this.thumbnailsURL.thumbnail_128,
			// 			displayName: this.userName
			// 		})
			// 	console.log('RTDB updated')
			// } catch (err) {
			// 	console.log('Update RTDB error:', err.message)
			// }

			try {
				await firebase
					.firestore()
					.collection('users')
					.doc(this.userID)
					.set({
						photoURL: this.thumbnailsURL.thumbnail_512,
						thumbnail_512: this.thumbnailsURL.thumbnail_512,
						thumbnail_256: this.thumbnailsURL.thumbnail_256,
						thumbnail_128: this.thumbnailsURL.thumbnail_128,
						// displayName: this.userName
					}, {merge: true}).then(() => {
						setTimeout(() => {
							this.OnStartGame()
						}, 500)
					})
				console.log('firestore updated')
			} catch (err) {
				console.log('Update firestore error:', err.message)
			}
		},

		async updateAuth() {
			try {
				await firebase.auth().currentUser.updateProfile({
					photoURL: this.thumbnailsURL.thumbnail_512,
					// displayName: this.userName
				})
				this.ispending(false)
				// this.resetForm()
				console.log('UpdateProfile success!!')
			} catch (err) {
				console.log('UpdateAuth error', err.message)
			}
		},

		// resetForm() {
		// 	this.$refs.vueavatareditor.reset()
		// 	this.isuploaded = false
		// 	this.registerForm.name = ''
		// 	this.registerForm.email = ''
		// 	this.registerForm.password = ''
		// 	this.havephoto = false
		// },

		// randomNumber(min, max) {
		// 	return parseInt(Math.random() * (max - min) + min, 1)
		// },

		// paddingzero(str, length) {
		// 	if (str.length >= length) return str
		// 	return this.paddingzero(`0${str}`, length)
		// },

		// generateID() {
		// 	const number = this.randomNumber(0, 999)
		// 	const IDdefault = number.toString()
		// 	this.registerForm.name = `0x-${this.paddingzero(IDdefault, 4)}`
		// 	// this.$emit('displayName', this.registerForm.name)
		// }

		OnConfirm() {
			// this.setPreloading(true)
			this.$refs.vueavatareditor.finished()
		},
		OnStartGame() {
			this.$emit('OnStartGame')
		},
		OnEditorPreview() {
			this.$refs.vueavatareditor.sendPreview()
		},
		showPreview() {
			this.$emit('showPreview')
		}
	}
}
</script>

<style lang="scss" scoped>
// .register-wrapper {
// 	//display: flex;
// 	//align-items: center;
// 	background-image: url('/img/reg_bg.svg');
// 	//padding: 6rem 0rem;
// 	background-repeat: no-repeat;
// 	background-size: contain;
// 	width: 19.2vw;
// 	height: 32.4vw;
// }
// .register_space {
// 	height: 23.2%;
// }

.vueavatar-container {
	position: fixed;
	z-index: 99;
}
</style>
