<template>
    <div>
        <UbitusContainer
            v-if="cloudPlatform==='ubitus'"
            id="UbitusPlayer"
			ref="UbitusRef"
			:class="{ 'layout-fullview': !isMobile, 'layout-fullpercent': isMobile }"
			:showDefaultProgress="false"
            :gameToken="gameToken"
			:ubitusSettings="ubitusSettings"
			:reservationMode="reservationMode"
			@OnProgressStart="OnProgressStart"
			@onGetUnityMessage="onGetUnityMessage"
			@externalProgress="externalProgress($event)"
			@onUnitySystemLoaded="onUnitySystemLoaded"
            @onUnityGameEnd="onUnityGameEnd"
			@onUserClickPlay="onUserClickPlay"
			@ContainerState="OnContainerState($event)"
			@OnUbiKeyInput="OnUbiKeyInput($event)"
			@sendUbitusConfigDetails="sendUbitusConfigDetails($event)"
        ></UbitusContainer>

        <FurioosContainer
            v-if="cloudPlatform==='furioos'"
			id="FurioosPlayer"
			ref="FurioosRef"
			:class="{ 'layout-fullview': !isMobile, 'layout-fullpercent': isMobile }"
			:furioosLinkId="furioosLinkId"
			:furioosLinkId_backup="furioosLinkId_backup"
			:useMobileJoystick="useMobileJoystick"
			:usePCInput="usePCInput"
			:showDefaultProgress="false"
			:furioos-auto-start="furioosAutoStart"
			@OnProgressStart="OnProgressStart"
			@onGetUnityMessage="onGetUnityMessage"
			@externalProgress="externalProgress($event)"
			@onUnitySystemLoaded="onUnitySystemLoaded"
			@ContainerState="OnContainerState($event)"
		></FurioosContainer>
    </div>
</template>
<script>
/* eslint no-use-before-define: 0 */
import FurioosContainer from '@/components/UnityContainer_Furioos'
import UbitusContainer from '@/components/UnityContainer_Ubitus.vue'
// eslint-disable-next-line
import {
	isMobile,
	deviceDetect,
	browserName,
	isSafari,
	isIOS,
	isAndroid,
	isIOS13,
	isChrome,
	isFirefox,
	isMobileSafari,
} from 'mobile-device-detect'

export default {
    components: {
		FurioosContainer,
        UbitusContainer,
	},
    props: {
        userId: {
            type: String,
            default: null,
        },
        gameToken: {
            type: String,
            default: null,
        },
        cloudPlatform: {
            type: String,
            default: null,
        },
		furioosLinkId: {
			type: String,
			default: null,
		},
		furioosLinkId_backup: {
			type: String,
			default: null,
		},
		useMobileJoystick: {
			type: Boolean,
			default: true,
		},
		usePCInput: {
			type: Boolean,
			default: false,
		},
		furioosAutoStart: {
			type: Boolean,
			default: true,
		},
		ubitusSettings: {
			type: Object,
			default: null,
		},
		reservationMode: {
			type: Boolean,
			default: false
		},
		DebugGameAuthMode: {
			type:Boolean,
			default: false
		}
    },
    data() {
        return {
			isUnitySystemLoaded: false,
        }
    },
    computed: {
		isMobile() {
			return isMobile
		},
		isSafari() {
			return isSafari
		},
		isMobileSafari() {
			return isMobileSafari
		},
		isIOS() {
			return isIOS
		},
		isIOS13() {
			return isIOS13
		},
		isAndroid() {
			return isAndroid
		},
		isDebug(){
			return true
		},
		isBrowserSupported() {
			// debugging
			// if(this.isDebug)
			// 	return false
			
			const deviceInfo = deviceDetect()
			console.log(
				'Browser info',
				deviceInfo,
				browserName,
				// 'isInApp',
				// this.isInApp
			)


			if (isMobile) {
				console.log('browsercheck? ismobile')
				// if (this.isInApp) {
				// 	console.log('browsercheck? isInApp')
				// 	return false
				// }

				if (isIOS) {
					console.log('browsercheck? isIOS')

					if (deviceInfo.osVersion.match('13')) {

						console.log('browsercheck? isIOS13')
						if ('serviceWorker' in navigator) {
							console.log('browsercheck? support serviceWorker')
							return true
						}

						if (isSafari) {
							console.log('browsercheck? support isSafari')
							return true
						}

						if(isMobileSafari) return true
						return false 

					}

					if (isChrome || isFirefox) {
						console.log('browsercheck? support isChrome || isFirefox')
						return false
					}
					if (isMobileSafari) {
						console.log('browsercheck? support isMobileSafari')
						return true
					}
					if (isSafari) {
						console.log('browsercheck? support isSafari')
						return true
					}
					return false
				}

				if (isAndroid) {
					console.log('browsercheck? isAndroid')
					if (isFirefox || isChrome) {
						console.log('browsercheck? isAndroid + isFirefox / isChrome')
						return true
					}
				}
				
				return false
			}
			
			// Only exclued IE on PC/Mac
			if (this.$browserDetect.isIE)				
				return false
			
			return true
		},
    },
    beforeMount() {
		if (!this.isBrowserSupported) {
			console.log("browsernotsupport")
			this.$emit('StarterState', 'BrowserNotSupported')
		}
	},

	mounted() {
	},

    methods: {
		OnProgressStart() {
			console.log('CloudGameStarter ', 'OnProgressStart')
			this.$emit('StarterState', 'OnProgressStart')
		},
		externalProgress(progress) {
			this.$emit('StarterState', 'externalProgress', progress)
		},
		sendUnityMessage(functionName, data) {
			// console.log('Furioos Example sendUnityMessage ', functionName)
			switch (this.cloudPlatform) {
				case "ubitus":
					this.$refs.UbitusRef.sendUnityMessage(functionName, data)
					break;
				case "furioos":
					this.$refs.FurioosRef.sendUnityMessage(functionName, data)
					break;
				default:
					break;
			}
		},
		onGetUnityMessage(param0, param1 = null) {
			// console.log('CloudGameStarter _ogum', param0, param1)
			if (param0 === 'OnTabKeyDown') {
				console.log('CloudGameStarter onGetUnityMessage', 'OnTabKey')
				this.$emit('ToggleMainMenu')
				this.sendUnityMessage('SetInputFocusInUnity', 'false')
				// this.openModalMainMenu() // TODO: make tab menu into Modal
			}
			if (param1) {
				this.$emit('onGetUnityMessage', param0, param1)
				return
			}
			this.$emit('onGetUnityMessage', param0)
		},
		onShowContainer() {
			this.$emit('onShowContainer')
		},
		onUnitySystemLoaded() {
			console.log('CloudGameStarter onUnitySystemLoaded')
			this.$emit('onUnitySystemLoaded')
			this.isUnitySystemLoaded = true
			// this.sendUnityMessage('SetDeviceIsMobile', isMobile)
			// this.showPlayer = true
		},
        onUnityGameEnd() {
            this.$emit('onUnityGameEnd')
        },
		onUserClickPlay() {
			this.$emit('onUserClickPlay')
		},
		OnContainerState(FunctionName, param){
			// pass container state to parent component
			this.$emit('StarterState', FunctionName, param)
		},
		OnUbiKeyInput(useroperation) {
			this.$emit('OnUbiKeyInput', useroperation)
		},
		sendUbitusConfigDetails(reoprt) {
			this.$emit('sendUbitusConfigDetails', reoprt)
		},
		focusCloudContainer() {
			switch (this.cloudPlatform) {
				case "ubitus":
					this.$refs.UbitusRef.FocusIframe()
					break;
				case "furioos":
					// todo
					break;
				default:
					break;
			}
		}
		// SetUserActive() {
		// 	this.$refs.UnityRef.FSSetUserActive()
		// }
	},
}
</script>
<style lang="scss">
#UbitusPlayer {
	position: fixed;
	min-width: 100vw;
	min-height: 100vh;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
#FurioosPlayer {
	position: fixed;
	min-width: 100vw;
	min-height: 100vh;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
.page-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 0vw;
	bottom: 0vw;
}
</style>