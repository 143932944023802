<template>
    <transition name="modal-fade">
        <div class="gameKeyContainer">
            <form action="#" @submit.prevent="useKey">
                <div class="form-space"></div>
                <div>
                    <span style="font-weight: bold;"><span>Enter your </span><span style="color: #D75EE7;">{{ gameSession }}</span><span> serial code</span></span>
                </div>
                <input 
                    type="text"
                    value
                    required
                    autofocus
                    v-model="gameKey"
                    placeholder="Serial code"
                    pattern="[^\s]*"
                />
                <div class="alert" role="alert">
                    <p v-if="codeAlert">{{ codeAlert }}</p>
                </div>
                <button 
                    class="submit-btn"
                >
                    Confirm
                </button>
                <div @click.prevent="OnBack" class="back-btn" style="cursor: pointer;">
                    Back
                </div>
                <div class="form-space"></div>

            </form>
        </div>
    </transition>
</template>
<script>
import firebase from 'firebase/app';
import { mapMutations, mapState } from 'vuex';

export default {
    components: {

    },
    props: {
    },
    data() {
        return {
            gameKey: '',
            projectPath: '',
            gameSession: '',
            isGameAuthed: false,
            codeAlert: '',
            gameKeySnapshot: null
        }
    },
    mounted() {

    },
    computed: {
        ...mapState('app', ['preloading']),
        ...mapState('authentication', ['user']),
    },
    methods: {
		...mapMutations('app', ['setPreloading', 'setMessageToastrContent', 'setMessageFadeTime']),

		getKey(e) {
			// console.log(this.gameKey)
			const callGetKey = firebase.app().functions('us-central1').httpsCallable('getKey');
			callGetKey({ key: this.gameKey.trim(), path: this.projectPath, event: e})
				.then(result => {
						console.log(result)
					})
					.catch(err => {
						console.log(err.message)
					})
		},
		useKey() {
            console.log('🕹️ gameSession useKey', this.gameSession, 'projectPath', this.projectPath, 'userId', this.user.id, 'gameKey', this.gameKey.trim())
            this.setPreloading(true)
			// console.log(this.gameKey.trim())
			const callUseKey = firebase.app().functions('us-central1').httpsCallable('useKey');
			callUseKey({ userId: this.user.id, key: this.gameKey.trim(), path: this.projectPath, event: this.gameSession})
				.then(result => {
						console.log(result)
                        if (result.data.code === 404) {
                            this.codeAlert = 'Your serial code is not exist or the event has not yet begun. Please try again.'
                            this.setPreloading(false)
                        } else if (result.data.code === 200) {
    						this.$gtag.event(`use_serial_code_success`, { method: 'Google' })
                        }
                        setTimeout(()=> {
                            this.GetSignInGameData(this.user.id, this.gameSession, this.projectPath)
                            // this.setPreloading(false)
                        }, 2000) 
					})
					.catch(err => {
                        this.codeAlert = 'Your serial code is not exist or the event has not yet begun. Please try again.'
                        this.setPreloading(false)
						console.log(err.message)
					})
		},
        GetSignInGameData(userId, gameSession, projectPath) {
            // console.log('🕹️ gameSession', gameSession, 'projectPath', projectPath)
            // console.log('🕹️ user', user.id)
            if (this.isGameAuthed) return
            // console.log('gameKey GetSignInGameData', gameSession)
            this.gameSession = gameSession
            this.projectPath = projectPath
            // console.log('🕹️ GetSignInGameData gameSession', this.gameSession, 'projectPath', this.projectPath, 'userId', userId)
			if (!userId || !this.gameSession) return
            try {
				this.gameKeySnapshot = firebase
					.firestore()
					.collection('users')
					.doc(userId)
					.collection(projectPath)
					.doc('auth')
					.collection('key')
                    .doc(gameSession)
					.onSnapshot(doc => {
						// const gameKey = []
						// snapShot.forEach((doc) => {
						// 	gameKey.push({...doc.data()})
						// })
						// console.log("gameKey data", {"result": gameKey} )
                        if (doc.exists && doc.data().enabled) {
                            this.isGameAuthed = true
                            this.$emit('OnGameAuth', this.isGameAuthed)
                            console.log('🕹️ GameAuth successfully', doc.data().hash)
                        } else if (doc.exists && !doc.data().enabled) {
                            this.isGameAuthed = false
                            this.$emit('OnGameAuth', this.isGameAuthed)
                            this.setMessageFadeTime(7000)
                            this.setMessageToastrContent('Your game is limited, please contact to administrator for details.')
                            setTimeout(() => {
                                window.location.reload()
                            }, 7000);
                        } else {
                            this.isGameAuthed = false
                            this.$emit('OnGameAuth', this.isGameAuthed)
                            console.log('🕹️ GameAuth doc is not exist')
                            this.gameKeySnapshot() // cancel real-time listener
                        }
					})
			} catch (err) {
				console.log("No such user data, or you're anonymous user.", err)
			}
		},
        OnUserLogout() {
            this.gameKeySnapshot() // cancel real-time listener
            this.gameKeySnapshot = null
            this.isGameAuthed = false
        },
        OnBack() {
            this.$emit('enter', false)
        }
    },
}
</script>
<style lang="scss">

.gameKeyContainer {
    width: 33rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 2vw;
    z-index: 999;
    box-shadow: 4px 20px 70px rgba(0, 0, 0, 0.25);
}

.key-input {
    display: flex;
    flex-direction: row;
    height: 2.5vh;
    margin: 20px 0 20px 0;
    align-items: center;
    column-gap: 1vw;
}

.back-btn {
    position: absolute;
    cursor: pointer;
    margin: 1rem 1.5rem 1rem 1.5rem;
    left: 0;
    color: #828282;
    font-size: .8rem;
}
</style>