<template>
    <div class="user-wrapper">
        <form v-if="!sent" action="#" @submit.prevent="submit">
            <div class="form-space"></div>

			<p class="user-title text-left" >Forget Password</p>

			<div class="user-subtitle text-left">
				<a >Enter the email you used to create your account so we can send you instructions on how to reset your password.</a>
			</div>

            <input 
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                v-model="forgetEmail"
                class="form-control"
                value
                required
            />

			<div class="alert" role="alert">
				<p>{{ alertMessage }}</p>
			</div>

			<!-- <button class="user-btn" @click.prevent="encrypteEmail(forgetEmail)">Test Send</button> -->
			<button type="submit" :class="{'btn_notcomplete': !fillup}" class="submit-btn">Send</button>
			<button class="user-btn" @click.prevent="backToLogin">Back to Login</button>

            <div class="form-space"></div>
            
        </form>

        <form v-if="sent" @submit.prevent="resetsubmit">
            <div class="form-space"></div>

			<p class="user-title text-left" >Check your Email</p>

			<div class="user-subtitle text-left">
				<a>We have sent an email with password reset information to {{encryptedEmail.full}}.</a>
			</div>

            <div class=" text-left">
                <a>Didn't receive the email? Check spam or promotion folder or </a>
            </div>
            
			<button type="submit" class="submit-btn">Resend Email</button>
			<button class="user-btn" @click.prevent="backToLogin">Back to Login</button>

            <div class="form-space"></div>
        </form>
    </div>
</template>
<script>
import { mapMutations } from 'vuex'
import firebase from 'firebase/app'

export default {
    data() {
        return {
            forgetEmail: '',
            encryptedEmail: {
                name: '',
                domain: '',
                end: '',
                full: ''
            },
            sent: false,
            alertMessage: ''
        }
    },
    computed: {
        fillup() {
            if (this.forgetEmail !== '') return true
            return false
        }
    },
    methods: {
		...mapMutations('app', ['setPreloading']),
        submit() {
            this.setPreloading(true)
            firebase.auth().sendPasswordResetEmail(this.forgetEmail)
            .then(() => {
                // Password reset email sent!
                // ..
                this.encrypteEmail(this.forgetEmail)
                this.sent = true
                this.setPreloading(false)
            })
            .catch((error) => {
                console.log(error)
                this.alertMessage = error.message
                this.setPreloading(false)
            });
        },
        resetsubmit() {
            this.sent = false
            this.resetForm()
        },
        resetForm() {
            this.forgetEmail = ''
            this.encryptedEmail.name = ''
            this.encryptedEmail.domain = ''
            this.encryptedEmail.end = ''
            this.encryptedEmail.full = ''
        },
        backToLogin() {
            this.resetsubmit()
            this.$store.dispatch('modals/open', {
				name: 'ModalUser',
				userMode: 'login',
			})
        },
        encrypteEmail(email) {
            const e = email.toString()
            const nameAry = e.split('@', 1)
            const domainAry1 = e.split('@', 2)
            const domainAry2 = domainAry1[1].split('.', 1)
            const endAry = e.split('.', 2)

            const name = nameAry[0]
            const domain = domainAry2[0]
            const end = endAry[1]

            // name encrypt
            let subname = name.substring(1, name.length - 1)
            subname = subname.replaceAll(/[a-z]/g, '*')
            this.encryptedEmail.name = `${name.substring(0, 1)}${subname}${name.substring(name.length-1)}`

            // domain encrypt
            let subdomain = domain.substring(1, domain.length - 1)
            subdomain = subdomain.replaceAll(/[a-z]/g, '*')
            this.encryptedEmail.domain = `${domain.substring(0, 1)}${subdomain}${domain.substring(domain.length-1)}`

            this.encryptedEmail.full = `${this.encryptedEmail.name}.${this.encryptedEmail.domain}.${end}`

            // console.log(this.encryptedEmail.full)
        }
    }
}
</script>
<style lang="scss">
    
</style>