<template>
    <div class="uavatar-container">
        <!-- <p>Player Skin</p> -->
        <div class="skin-container">

            <div class="skin-div" v-for="(object) in limb" :key='object.id'>
                <!-- <button class="ctrl-btn" @click.prevent="setSkin(object.dictKey, 'prev')">
                    &lt;
                </button> -->
                <img 
                    class="ctrl-img"
                    src="img/game/avatar/editor_prev_arrow.svg" 
                    @click.prevent="setSkin(object.dictKey, 'prev')"
                />
                <p>{{ object.name }}</p>
                <!-- <button class="ctrl-btn" @click.prevent="setSkin(object.dictKey, 'next')">
                    &gt;
                </button> -->
                <img 
                    class="ctrl-img"
                    src="img/game/avatar/editor_next_arrow.svg"
                    @click.prevent="setSkin(object.dictKey, 'next')"
                />
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {

    },
    data() {
        return {
            limb: [
                {
                    id: '0',
                    name: 'CLOTHES',
                    dictKey: 'body'
                },
                {
                    id: '1',
                    name: 'PANTS',
                    dictKey: 'pants'
                },
                {
                    id: '2',
                    name: 'SHOES',
                    dictKey: 'shoe'
                },
                {
                    id: '3',
                    name: 'OTHERS',
                    dictKey: 'acc'
                }
            ],
            unityFunction: 'PlayerCustomize',
            // debug
            debugShowUI: true,
        }
    },
    methods: {
        setSkin(dictKey, ctrl) {
            console.log(`UnityAvatar sendUnityMessage: PlayerCustomize ${dictKey}/${ctrl}`)
            this.$emit('sendUnityMessage', 'PlayerCustomize', `${dictKey}/${ctrl}`)
        },
    },
    computed: {

    },
    mounted() {

    }
}
</script>

<style lang="scss">
.uavatar-container {
    position: relative;
    // left: 0;
    // right: 0;
    text-align: center;
    color: black;
    font-family: 'Inter';
    font-size: 1vw;
    // margin: 8vh 0;
    // height: 100%;
    margin: auto;
    height: 100%;

    p {
        font-size: .7vw;
    }
}

.skin-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    align-items: center;
    left: 0;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
}

.skin-div {
    display: flex;
    align-items: center;
    // flex-direction: column;

    p {
        color: black;
        width: 8vw;
        font-size: 1vw;
        font-weight: bold;
        margin: 2vh 1vw;
    }
}

.ctrl-btn {
    // border-radius: 50px;
    border: none;
    width: 3vh;
    height: 3vh;
    font-size: 1vh;
    margin: .5vw;
    color: black;
    cursor: pointer;
}

.ctrl-img {
    width: 3vh;
    height: 3vh;
    margin: .5vw;
    cursor: pointer;
}

</style>