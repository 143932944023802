<template>
	<div class="user-wrapper">
		<div class="form-space"></div>
		<!-- Loader Login.spec.js -->
		<!-- <div v-show="user === undefined" data-test="loader" class="authenticate">
			Authenticating...
		</div> -->

		<!-- Offline instruction -->
		<div
			v-show="!networkOnLine"
			class="network-warn"
			data-test="offline-instruction"
		>
			Please check your connection, login feature is not available offline.
		</div>

		<p v-if="loginError">{{ loginError }}</p>
		<!-- Auth UI -->
		<div
			v-show="user !== undefined || (!user && networkOnLine)"
			style="height:100%;"
		>
			<!-- <div style="text-align: center"> -->
				<!-- <img class="input-icon3" src="/img/icon01.svg" /> -->
				<!-- <input
					id="name"
					ref="IDinput"
					v-model="registerForm.name"
					value
					name="name"
					class="form-control form-control-lg ideditor idinput"
					type="name"
					required
					autofocus
					@change="examineID"
				/>
				<label class="ideditor idlabel">ID</label> -->
			<!-- </div> -->

			<form action="#">
				<!-- <h3>Sign Up</h3> -->
				<p class="user-title text-left">Create account</p>

				<div class="user-subtitle text-left">
					<span >Already have an account? </span>
					<span class="tips" @click.prevent="redirectRoute">Login</span>
				</div>

				<div class="form-group">
					<!-- <label>Nickname</label> -->
					<input
						id="name"
						v-model="registerForm.name"
						type="name"
						class="form-control"
						placeholder="ID"
						name="name"
						value
						required
						autofocus
					/>
				</div>
				<div class="form-group">
					<!-- <img class="input-icon2" src="/img/icon02.svg" /> -->
					<!-- <label>Email</label> -->
					<input
						id="registerEmail"
						v-model="registerForm.email"
						type="email"
						class="form-control"
						placeholder="Email"
						name="email"
						value
						required
						autofocus
					/>
				</div>

				<div class="password-group">
					<!-- <img class="input-icon1" src="/img/icon03.svg" /> -->
					<!-- <label>Password</label> -->
					<input
						id="registerPassword"
						v-model="registerForm.password"
						placeholder="Password"
						type="password"
						class="form-control"
						name="password"
						required
					/>

					<img @click.prevent="togglepw(showPw)" class="togglepw-img" :src="showPw ? '/img/user/eye_on.svg' : '/img/user/eye_off.svg'" />
				</div>

				<!-- <div class="register_space"></div> -->

				<div class="alert" role="alert">
					<p v-if="idAlert">{{ idAlert }}</p>
					<p v-if="pwdAlert">{{ pwdAlert }}</p>
					<!-- <p v-if="photoAlert">{{ photoAlert }}</p> -->
					<p v-if="alreadyinuse">{{ alreadyinuse }}</p>
				</div>

				<button
					v-if="!isWaiting"
					type="submit"
					id="emailPasswordSubmit"
					:class="{ btn_notcomplete: !fillup }"
					class="submit-btn btn-dark btn-lg btn-block"
					@click.prevent="signUpWithEmailPassword"
				>
					Create Account
				</button>

				
				<!--
					TO-change: submit loading...	
				-->
				<button
					v-if="isWaiting"
					class="user-btn btn-dark btn-lg btn-block"
					style="pointer-events:none;"
					disabled
				>
					Loading...
				</button>

				
				
				<div class="or-sign-with">
					<label class="text-center"><span>or </span></label>
				</div>

				<div class="GoogleSignInButton">
					<button
						mat-flat-button
						class="user-btn"
						@click.prevent="signUpWithGoogle"
						>
						<img
						width="20px"
						alt='Google G Logo'
						src="@/assets/img/Google__G__Logo.svg.png"
						/>
						Continue with Google
					</button>
				</div>
			
				<div class="form-space"></div>
				
				<!--<p class="forgot-password text-right">
					Already registered?
					<a @click="switchMode('login')">LOGIN</a>
				</p>-->
			</form>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { isNil } from 'lodash'
import firebase from 'firebase/app'
// import VueAvatarEditor from '@/components/VueAvatarEditor'

export default {
	components: {
		// VueAvatarEditor
	},
	// props: ['isSystemLoaded'],
	data() {
		return {
			registerForm: {
				name: '',
				email: '',
				password: ''
			},
			// thumbnails: {
			// 	thumbnail_512: '',
			// 	thumbnail_256: '',
			// 	thumbnail_128: ''
			// },
			// thumbnails_url: {
			// 	thumbnail_512: '',
			// 	thumbnail_256: '',
			// 	thumbnail_128: ''
			// },
			loginError: null,
			pwdAlert: null,
			// photoAlert: null,
			idAlert: null,
			// havephoto: false,
			userID: '',
			alreadyinuse: null,
			btnsuccess: false,
			isWaiting: false,
			showPw: false,
			// previewimage: '',
			debug: false
		}
	},
	computed: {
		/* 取得 store 的值 */
		...mapState('authentication', ['user']),
		...mapState('app', ['networkOnLine', 'appTitle']),

		fillup() {
			if (
				this.registerForm.name !== '' &&
				this.registerForm.email !== '' &&
				this.registerForm.password !== ''
				// && this.havephoto
			)
				return true

			return false
		},

		submitRegister() {
			if (this.registerForm.name.length <= 0) {
				return false
			}
			if (this.registerForm.password.length < 6) {
				return false
			}
			return true
		},
	},
	watch: {
		user: {
			handler(user) {
				// console.log('watch user handler')
				if (!isNil(user)) {
					// console.log('watch user handler !isNil')
					this.$store.dispatch('modals/closeAll')
					//   const redirectUrl = isNil(this.$route.query.redirectUrl)
					//     ? '/welcome'
					//     : this.$route.query.redirectUrl
					//   console.log('redirecting to', redirectUrl)
					//   this.$router.push(redirectUrl)
				}
				// console.log('watch user', user)
			},
			immediate: true
		},
		// enter nickname with unity synchronize
		// isSystemLoaded: {
		// 	handler(isSystemLoaded) {
		// 		if (isSystemLoaded === true && this.registerForm.name !== '') {
		// 			// console.log('systemLoad and generate displayname')
		// 			setTimeout(
		// 				() => this.$emit('displayName', this.registerForm.name),
		// 				300000
		// 			)
		// 			setTimeout(
		// 				() => this.$emit('displayName', this.registerForm.name),
		// 				8000
		// 			)
		// 		}
		// 	},
		// 	immediate: true
		// }
	},
	mounted() {
		// this.generateID()
		// this.$refs.IDinput.focus()
	},
	methods: {
		/* 更動 store 的值 */
		...mapMutations('app', ['setPreloading']),
		...mapMutations('authentication', ['setUser']),
		...mapActions('authentication', ['register']),
		// examineID() {
		// 	if (this.registerForm.name !== '') this.idAlert = null
		// 	this.$emit('displayName', this.registerForm.name)
		// },
		// ispending(state) {
		// 	console.log('is pendding')
		// 	this.$emit('loadingRegister', state)
		// },

		// submitRegister() {
		// 	// if (this.registerForm.name === '') {
		// 	// 	this.idAlert = 'Please fill up your ID!'
		// 	// 	return false
		// 	// }
		// 	if (this.registerForm.password.length < 6) {
		// 		// this.pwdAlert = 'Password should be at least 6 characters'
		// 		console.log(this.pwdAlert)
		// 		return false
		// 	}
		// 	return true
		// },

		// async createUser() {
		// 	try {
		// 		const res = await firebase
		// 			.auth()
		// 			.createUserWithEmailAndPassword(
		// 				this.registerForm.email,
		// 				this.registerForm.password
		// 			)
		// 		if (!res) {
		// 			throw new Error('Could not create user')
		// 		}
		// 		// console.log('User created ', res.user)
		// 		await res.user.updateProfile({ displayName: this.registerForm.name })
		// 		// this.setUser(res.user)
		// 		this.userID = res.user.uid
		// 		this.ispending(true)

		// 		await firebase
		// 			.firestore()
		// 			.collection('users')
		// 			.doc(this.userID)
		// 			.set({
		// 				displayName: this.registerForm.name,
		// 				email: this.registerForm.email
		// 			})
		// 			.catch(console.error)
		// 	} catch (err) {
		// 		if (
		// 			err.message ===
		// 			'The email address is already in use by another account.'
		// 		) {
		// 			this.alreadyinuse = err.message
		// 			this.isWaiting = false
		// 		}
		// 		console.log('CreateUser failed:', err.message)
		// 	}
		// },
		// async updateDB() {
		// 	try {
		// 		await firebase
		// 			.database()
		// 			.ref(`users/${this.userID}`)
		// 			.update({
		// 				email: this.registerForm.email,
		// 				uid: this.userID,
		// 				photoURL: '',
		// 				// thumbnail_512: this.thumbnails_url.thumbnail_512,
		// 				// thumbnail_256: this.thumbnails_url.thumbnail_256,
		// 				// thumbnail_128: this.thumbnails_url.thumbnail_128,
		// 				displayName: this.registerForm.name
		// 			})
		// 		console.log('RTDB updated')
		// 	} catch (err) {
		// 		console.log('Update RTDB error:', err.message)
		// 	}

		// 	try {
		// 		await firebase
		// 			.firestore()
		// 			.collection('users')
		// 			.doc(this.userID)
		// 			.update({
		// 				photoURL: '',
		// 				// thumbnail_512: this.thumbnails_url.thumbnail_512,
		// 				// thumbnail_256: this.thumbnails_url.thumbnail_256,
		// 				// thumbnail_128: this.thumbnails_url.thumbnail_128,
		// 				displayName: this.registerForm.name
		// 			})
		// 		console.log('firestore updated')
		// 	} catch (err) {
		// 		console.log('Update firestore error:', err.message)
		// 	}
		// },

		// async updateAuth() {
		// 	try {
		// 		await firebase.auth().currentUser.updateProfile({
		// 			photoURL: '',
		// 			displayName: this.registerForm.name
		// 		})
		// 		this.ispending(false)
		// 		this.resetForm()
		// 		console.log('UpdateProfile success!!')
		// 	} catch (err) {
		// 		console.log('UpdateAuth error', err.message)
		// 	}
		// },
		/**
		 *  google auth
		 */
		// async signUpWithGoogle() {
		// 	try {
		// 		const provider = new firebase.auth.GoogleAuthProvider()
		// 		const { user } = await firebase.auth().signInWithPopup(provider)
		// 		await this.register(user)
		// 		this.ispending(true)
		// 	} catch (err) {
		// 		console.log('Auth', err.message)
		// 	}
		// },

		async signUpWithGoogle() {
			this.setPreloading(true)
			this.resetAlert()
			try {
				const provider = new firebase.auth.GoogleAuthProvider()
				await firebase
					.auth()
					.signInWithPopup(provider)
					.then(async (usercred) => {
						// show congraulations
						let { user } = usercred

						await user.reload()
						user = firebase.auth().currentUser

						this.userID = user.uid

						// if user login with google register function, should check if this user is already exist in db, to ignore the init profile data

						setTimeout(() => {
							firebase
							.firestore()
							.collection('users')
							.doc(this.userID)
							.set({
								createTimestamp: new Date(),
								displayName: user.displayName.trim(),
								email: user.email.trim(),
								photoURL: '',
								unityPrefabIdx: 0,
								skinIdx: [0, 0, 0, 0],
								thumbnail_128: '',
								thumbnail_256: '',
								thumbnail_512: ''
							}, {merge: true})
							.then(() => {
								window.location.reload()
							})
							.catch(console.error)
						}, 2000)

						if (this.$route.path === '/play') {
							// process on signIn redirect
							this.$emit('OnUserSignIn')
							// this.$emit('OnUserSignIn', user)
						} else {
							// process for stay on rigster page, show congradulations
							this.$emit('OnRegister')
						}
						console.log(user)
						this.$gtag.event(`Signup_with_google`, { method: 'Google' })

						// await this.register(user)
					}).catch(e => {
						console.log("Account linking error", e.code);
						this.alreadyinuse = e.message
						this.setPreloading(false)
					})
			} catch (err) {
				console.log('UserRegister', err.message)
				this.setPreloading(false)
			}
		},

		async signUpWithEmailPassword() {
			this.setPreloading(true)
			this.isWaiting = true
			this.resetAlert()
			try{
				// submit check
				if (!this.fillup) {
					this.stopLoading()
					return
				}
				if (!this.submitRegister) {
					if (this.registerForm.name.length <= 0) {
						this.idAlert = 'Please fill up your name'
					}
					if (this.registerForm.password.length < 6) {
						this.pwdAlert = 'Password should be at least 6 characters'
					}
					this.stopLoading()
					return
				}
				/* eslint new-cap: ["error", { "properties": false }] */
				// const credential = new firebase.auth.EmailAuthProvider.credential(this.registerForm.email, this.registerForm.password)
				await firebase
					.auth()
					.createUserWithEmailAndPassword(this.registerForm.email, this.registerForm.password)
					.then(async (usercred) => {
						let {user} = usercred;
						// console.log("Account linking success", user);

						console.log(this.registerForm.name)
						console.log(this.registerForm.email)

						await user.reload()
						user = firebase.auth().currentUser

						user.updateProfile({
							displayName: this.registerForm.name
						})

						this.userID = user.uid
						
						setTimeout(() => {
							firebase
								.firestore()
								.collection('users')
								.doc(this.userID)
								.set({
									createTimestamp: new Date(),
									displayName: this.registerForm.name.trim(),
									email: this.registerForm.email.trim(),
									photoURL: '',
									unityPrefabIdx: 0,
									skinIdx: [0, 0, 0, 0],
									thumbnail_128: '',
									thumbnail_256: '',
									thumbnail_512: ''
								}, {merge: true})
								.then(() => {
									setTimeout(() => {
										window.location.reload()
									}, 500)
								})
								.catch(console.error)
						}, 2000)

						if (this.$route.path === '/play') {
							// process on signIn redirect
							this.$emit('OnUserSignIn')
							// this.$emit('OnUserSignIn', user)
						} else {
							// process for stay on rigster page, show congradulations
							this.$emit('OnRegister')
						}
						
						// this.resetForm()
						console.log(user)

						this.$gtag.event(`Signup_with_emailpw`, { method: 'Google' })

						// TODO: for emailpassword, update name manually 
						
						// await this.register(user)
					}).catch((e) => {
						console.log("Account linking error", e);
						if (e.code === 'auth/credential-already-in-use') {
							// show error message 3s, redirect to login page
							this.alreadyinuse = e.message
							this.stopLoading()
						} else if (e.code === 'auth/email-already-in-use') {
							// show error message 3s, redirect to login page
							this.alreadyinuse = e.message
							this.stopLoading()
						}
					});
			} catch (err) {
				console.log('UserRegister', err.message)
				this.stopLoading()
			}
		},

		/**
		 *  If authed anonymous firstly, you can link provider popup signin to current anonymous user account.
		 */
		// async linkWithGoogle() {
		// 	this.setPreloading(true)
		// 	this.resetAlert()
		// 	try {
		// 		const provider = new firebase.auth.GoogleAuthProvider()
		// 		await firebase
		// 			.auth()
		// 			.currentUser.linkWithPopup(provider)
		// 			.then(async (usercred) => {
		// 				// show congraulations
		// 				const { user } = usercred

		// 				// process on signIn redirect
		// 				// this.$emit('OnUserSignIn', user)

		// 				// process for stay on rigster page, show congradulations
		// 				this.$emit('OnRegister')
		// 				console.log(user)
		// 				// await this.register(user)
		// 			}).catch(e => {
		// 				console.log("Account linking error", e.code);
		// 				if (e.code === 'auth/email-already-in-use') {
		// 					// show error message 3s, redirect to login page
		// 					this.alreadyinuse = e.message
		// 					this.setPreloading(false)
		// 				} else if (e.code === 'auth/credential-already-in-use') {
		// 					// show error message 3s, redirect to login page
		// 					this.alreadyinuse = e.message
		// 					this.setPreloading(false)
		// 				}
		// 			})
		// 	} catch (err) {
		// 		console.log('UserRegister', err.message)
		// 		this.setPreloading(false)
		// 	}
		// },
		// async linkWithEmailPassword() {
		// 	this.setPreloading(true)
		// 	this.isWaiting = true
		// 	this.resetAlert()
		// 	try{
		// 		// submit check
		// 		if (!this.fillup) {
		// 			this.stopLoading()
		// 			return
		// 		}
		// 		if (!this.submitRegister) {
		// 			this.pwdAlert = 'Password should be at least 6 characters'
		// 			this.stopLoading()
		// 			return
		// 		}
		// 		/* eslint new-cap: ["error", { "properties": false }] */
		// 		const credential = new firebase.auth.EmailAuthProvider.credential(this.registerForm.email, this.registerForm.password)
		// 		await firebase
		// 			.auth().currentUser
		// 			.linkWithCredential(credential)
		// 			.then(async (usercred) => {
		// 				const {user} = usercred;
		// 				// console.log("Account linking success", user);

		// 				// process on signIn redirect
		// 				// this.$emit('OnUserSignIn', user)

		// 				// process for stay on rigster page, show congradulations
		// 				this.$emit('OnRegister')
						
		// 				this.resetForm()
		// 				console.log(user)
		// 				// await this.register(user)
		// 			}).catch((e) => {
		// 				console.log("Account linking error", e);
		// 				if (e.code === 'auth/credential-already-in-use') {
		// 					// show error message 3s, redirect to login page
		// 					this.alreadyinuse = e.message
		// 					this.stopLoading()
		// 				} else if (e.code === 'auth/email-already-in-use') {
		// 					// show error message 3s, redirect to login page
		// 					this.alreadyinuse = e.message
		// 					this.stopLoading()
		// 				}
		// 			});
		// 	} catch (err) {
		// 		console.log('UserRegister', err.message)
		// 		this.stopLoading()
		// 	}
		// },

		stopLoading() {
			this.setPreloading(false)
			this.isWaiting = false
		},

		resetAlert() {
			this.idAlert = ''
			this.pwdAlert = ''
			this.alreadyinuse = ''
		},

		resetForm() {
			// this.$refs.vueavatareditor.reset()
			// this.isuploaded = false
			this.registerForm.name = ''
			this.registerForm.email = ''
			this.registerForm.password = ''
			// this.havephoto = false
		},

		randomNumber(min, max) {
			return parseInt(Math.random() * (max - min) + min, 1)
		},

		paddingzero(str, length) {
			if (str.length >= length) return str
			return this.paddingzero(`0${str}`, length)
		},

		generateID() {
			const number = this.randomNumber(0, 999)
			const IDdefault = number.toString()
			this.registerForm.name = `0x-${this.paddingzero(IDdefault, 4)}`
			// this.$emit('displayName', this.registerForm.name)
		},
		switchMode(mode) {
			this.$emit('switchMode', mode)
		},
		redirectRoute() {
			// console.log(this.$route)
			// if (this.$route.path === '/play') {
			// 	this.$store.dispatch('modals/open', {
			// 	name: 'ModalUser',
			// 	userMode: 'login',
			// 	})
			// } else {
			// 	this.$router.push('play')
			// }

			this.$store.dispatch('modals/open', {
				name: 'ModalUser',
				userMode: 'login',
			})
		},
		togglepw() {
			const pwInput = document.getElementById('registerPassword')
			this.showPw = !this.showPw
			if (this.showPw) {
				pwInput.setAttribute('type', 'text')
			} else {
				pwInput.setAttribute('type', 'password')
			}
		}
	}
}
</script>

<style lang="scss">

</style>